@import "src/scss/style";
// .modal-basket{
//     position: absolute;
//     display: none;
//     &.active{
//         display: block;
//     }
// }


.modal-compare, .modal-basket {
    position: absolute;
    right: 0;
    top: 40px;
    background: $white;
    box-shadow: 0 100px 80px rgba(24, 48, 41, 0.07), 0 41.7776px 33.4221px rgba(24, 48, 41, 0.0503198), 0 22.3363px 17.869px rgba(24, 48, 41, 0.0417275), 0 12.5216px 10.0172px rgba(24, 48, 41, 0.035), 0 6.6501px 5.32008px rgba(24, 48, 41, 0.0282725), 0 2.76726px 2.21381px rgba(24, 48, 41, 0.0196802);
    padding: 16px 24px 40px;
    width: 578px;
    opacity: 0;
    transform: scaleY(0);
    transform-origin: top;
    transition: .3s;
    overflow: hidden;
    z-index: 1;

    &.active {
        opacity: 1;
        transform: scaleY(1);
    }

    // .modal__close

    & .modal__close {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    // .modal-compare__title

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        color: #000;
        margin-bottom: 6px;
    }

    // .modal-compare__body

    &__body {
    }

    &__body--empty {
        & .modal-compare__body-title {
            font-size: 18px;
            text-align: center;
            margin-top: 14px;
        }

        .modal-compare__body-subtitle {
            @include regular;
            color: $black;
            margin-bottom: 30px;
            text-align: center;

            & a {
                color: $main;
                text-decoration-line: underline;
                font-weight: 600;
            }
        }

        .modal-compare__to-catalog-btn {
            width: fit-content;
            margin: 0 auto;
        }
    }

    // .modal-compare__body--filled

    &__body--filled {
    }

    // .modal-compare__body-title

    &__body-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: $grey;
        margin-bottom: 20px;
    }

    // .modal-compare__items-wrap

    &__items-wrap {
        max-height: 50vh;
        overflow-y: auto;
        padding-right: 9px;

        &::-webkit-scrollbar-button {
            width: 6px;
            height: 0
        }

        &::-webkit-scrollbar-track {
            background-color: $label;
            box-shadow: 0 0 3px $label inset;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
            -webkit-border-radius: 5px;
            border-radius: 5px;
            background-color: $main;
            box-shadow: 0 1px 1px #fff inset;
        }

        &::-webkit-resizer {
            width: 4px;
            height: 0
        }

        &::-webkit-scrollbar {
            width: 4px;
            transform: translateX(20px);
        }
    }

    // .modal-compare__item

    &__item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #DCDCDC;
    }

    // .modal-compare__item-link

    &__item-link {
        display: flex;
        align-items: center;
        width: 85%;
    }

    // .modal-compare__img-wrap

    &__img-wrap {
        max-width: 117px;
        width: 117px;
        height: 80px;
        margin-right: 7px;
        text-align: center;

        & img {
            object-fit: cover;
            height: 100%;
        }
    }

    // .modal-compare__item-name

    &__item-name {
        @include subtitle-small;
        color: $black;
        width: 182px;
    }

    // .modal-compare__price-wrap

    &__price-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-left: auto;
    }

    // .modal-compare__price-old

    &__price-old {
        font-size: 14px;
        line-height: 150%;
        text-decoration-line: line-through;
        color: $grey;
    }

    // .modal-compare__price

    &__price {
        font-weight: bold;
        font-size: 18px;
        line-height: 150%;
        color: $black;
    }

    // .modal-compare__delete-btn

    &__delete-btn {
        background: none;
        cursor: pointer;
    }

    // .modal-compare__compare-btn

    &__compare-btn {
        display: block;
        margin: 30px auto 0 auto;
        width: fit-content;
    }
}

.modal-basket {
    width: 630px;

    &__section {
        width: 240px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    &__item-name {

    }

    // .modal-basket__title

    &__title {
        margin-bottom: 20px;
    }

    &__item-body {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    // .modal-basket__counter

    &__counter {
        display: flex;
        height: 29px;
        align-items: center;
        border: 1px solid $second-grey;
        margin-top: 10px;
    }

    // .modal-basket__items-wrap

    .modal-basket__items-wrap {
    }

    // .modal-basket__body--empty

    &__body--empty {
        & .modal-basket__body-title {
            font-size: 18px;
            text-align: center;
        }

        & .modal-basket__body-subtitle {
            max-width: 340px;
            text-align: center;
            margin: 0 auto 30px auto;

            & a {
                color: $main;
                text-decoration-line: underline;
                font-weight: 600;
            }
        }

        & .modal-basket__to-catalog-btn {
            width: fit-content;
            margin: 0 auto;
        }
    }

    // .modal-basket__item

    &__item {
        padding: 20px 0;
        flex-direction: column;
    }

    // .modal-basket__counter-dec

    &__counter-dec {
    }

    // .modal-basket__counter-val

    &__counter-val {
        width: 20px;
        text-align: center;
    }

    // .modal-basket__counter-inc

    &__counter-inc {
    }

    &__counter-dec, &__counter-inc {
        cursor: pointer;

        & svg {
            transition: .2s;
        }

        &:hover {
            & svg {
                fill: black;
            }
        }

        &:active {
            & svg {
                transform: scale(0.8);
            }
        }
    }

    // .modal-basket__buttons

    &__buttons {
        display: flex;
        width: max-content;
        cursor: pointer;
    }

    // .modal-basket__button

    &__button {
        background: none;
        @include secondary-text;
        padding: 0;
        display: flex;
        align-items: center;

        & svg {
            margin-right: 8px;
        }
    }

    // .modal-basket__fav-button

    &__fav-button {
        margin-right: 16px;
        cursor: pointer;
    }

    // .modal-basket__delete-button

    &__delete-button {
        cursor: pointer;
    }

    // .modal-basket__footer

    &__footer {

    }

    // .modal-basket__item-services

    &__item-services {
        padding-top: 16px;
    }

    // .modal-basket__service

    &__service {
        display: flex;
        justify-content: space-between;

        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }

    // .modal-basket__service-name

    &__service-name {
        font-size: 12px;
        line-height: 150%;
        width: 70%;
    }

    // .modal-basket__service-section

    &__service-section {
        display: flex;
        align-items: center;
    }

    // .modal-basket__service-price

    &__service-price {
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        margin-right: 10px;
        white-space: nowrap;
    }

    // .modal-basket__button

    &__button {
    }

    // .modal-basket__delete-button

    &__delete-button {
    }


    &__item-services {
        margin-left: 124px;
        border-top: 1px solid $second-grey;
    }

    // .modal-basket__footer-top

    &__footer-top {
        margin-top: 32px;
        margin-bottom: 18px;
        display: flex;
        justify-content: space-between;
    }

    // .modal-basket__cashback

    &__cashback {
        display: flex;
        align-items: center;

        & img {
            margin-right: 8px;
        }
    }

    // .modal-basket__cashback-text

    &__cashback-text {
        @include main-text;
        color: #000;
        margin-right: 4px;
    }

    // .modal-basket__cashback-val

    &__cashback-val {
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        text-decoration-line: underline;
        color: #FB8200;
    }

    // .modal-basket__price-total

    &__price-total {
    }

    // .modal-basket__price-total-text

    &__price-total-text {
        @include subtitle-small;
        color: $black;
        margin-right: 16px;
    }

    // .modal-basket__price-total-val

    &__price-total-val {
        @include subtitle;
        color: $black;
    }

    // .modal-basket__footer-bottom

    &__footer-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    // .modal-basket__footer-links

    &__footer-links {
        display: flex;
        flex-direction: column;
    }

    // .modal-basket__footer-link

    &__footer-link {
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        text-decoration-line: underline;
    }

    // .modal-basket__footer-link--continue-shopping

    &__footer-link--continue-shopping {
        margin-bottom: 8px;
        cursor: pointer;
        color: $grey;
    }

    // .modal-basket__footer-link--to-basket

    &__footer-link--to-basket {
        color: $black;
    }

    // .modal-basket__confirm-btn

    &__confirm-btn {
    }

}


@media only screen and (max-width: 670px) {
    .header-actions {
        &__modal-wrap {
            position: static;

            & .modal-compare.active {
                padding: 14px 14px 24px 14px;
            }

            .modal-compare {
                width: 100%;

                & .modal__close {
                    right: 10px;
                    top: 10px;
                }

                &__body-subtitle {
                    font-size: 12px;
                    text-align: center;
                }

                &__to-catalog-btn {
                    display: none;
                }

                &__title {
                    font-size: 14px;
                    margin-bottom: 0;
                }

                &__body-title {
                    font-size: 12px;
                    margin-bottom: 10px;
                }

                &__img-wrap {
                    width: 72px;
                    height: 48px;
                }

                &__item-name {
                    font-size: 12px;
                    height: 35px;
                }

                &__price-old {
                    font-size: 12px;
                }

                &__price {
                    font-size: 14px;
                }

                &__delete-btn {
                    width: 30px;
                    height: 20px;
                    transform: translateY(10px);
                    background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.00031 4.29307L1.46481 0.757568L0.757812 1.46457L4.29331 5.00007L0.757812 8.53557L1.46481 9.24257L5.00031 5.70707L8.53581 9.24257L9.24281 8.53557L5.70731 5.00007L9.24281 1.46457L8.53581 0.757568L5.00031 4.29307Z' fill='%23868686'/%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-position-x: right;
                    background-position-y: top;

                    & img {
                        vertical-align: top;
                        display: none;
                    }
                }

                &__compare-btn {
                    margin: 20px auto 0;
                }

                &__compare-btn {
                    font-size: 12px;
                    padding: 8px 32px;
                }
            }

            .modal-basket {
                padding: 14px 14px 24px 14px;

                & .modal__close {
                    top: 10px;
                    right: 10px;
                }

                &__body-title {
                    margin-bottom: 12px;
                    font-size: 14px;
                }

                &__body-subtitle {
                    font-size: 12px;
                }

                &__to-catalog-btn {
                    display: none;
                }

                &__item {
                    padding: 6px 0;
                    position: relative;
                }

                &__item-services {
                    padding-top: 6px;
                }

                &__service {
                    margin-bottom: 2px;
                    position: relative;
                    align-items: center;
                }

                &__title {
                    margin-bottom: 10px;
                    font-size: 14px;
                }

                &__img-wrap {
                    width: 72px;
                    height: 48px;
                }

                &__item-name {
                    font-size: 12px;
                    height: 35px;
                }

                &__price {
                    font-size: 14px;
                }

                &__price-old, &__fav-button, &__delete-button span, &__service-section &__button, &__counter,
                //&__service-section
                {
                    display: none;
                }

                &__price-wrap {
                    justify-content: center;
                    margin-right: 20px;
                }

                &__cashback {
                    & img {
                        width: 18px;
                        height: 18px;
                        margin-right: 7px;
                    }
                }

                &__cashback-text {
                    font-size: 12px;
                }

                &__cashback-val {
                    font-size: 12px;
                }

                &__footer-top {
                    margin: 14px 0 20px;
                }

                &__footer-link {
                    font-size: 12px;
                }

                &__footer-link--continue-shopping {
                    margin-bottom: 6px;
                }

                &__confirm-btn {
                    font-size: 12px;
                    padding: 8px 32px;
                }

                &__price-total {
                    display: flex;
                    align-items: center;
                }

                &__price-total-text {
                    font-size: 12px;
                    margin-right: 8px;
                }

                &__price-total-val {
                    font-size: 13px;
                }

                &__service-section {
                    & .modal-basket__delete-button {
                        position: relative;
                        transform: translateY(0);
                    }
                }

                &__delete-button {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 10px;
                    height: 10px;
                    transform: translateY(10px);
                    background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.00031 4.29307L1.46481 0.757568L0.757812 1.46457L4.29331 5.00007L0.757812 8.53557L1.46481 9.24257L5.00031 5.70707L8.53581 9.24257L9.24281 8.53557L5.70731 5.00007L9.24281 1.46457L8.53581 0.757568L5.00031 4.29307Z' fill='%23868686'/%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-position-x: right;
                    background-position-y: top;

                    & svg {
                        vertical-align: top;
                        display: none;
                    }
                }

                &__section {
                    position: static;
                }
            }
        }
    }
}

@media only screen and (max-width: 632px) {
    .modal-basket {
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .modal-compare {
        &__item-link {
            flex-wrap: wrap;
        }

        &__item-name {
            width: calc(100% - 79px);
        }

        &__price-wrap {
            margin-left: 79px;
            align-items: flex-start;
        }
    }
}

@media only screen and (max-width: 420px) {
    .modal-basket {
        &__footer-bottom {
            flex-direction: column-reverse;
        }

        &__footer-links {
            text-align: center;
            margin-top: 16px;
        }

        &__item-body {
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        &__price-wrap {
            margin-left: 79px;
            align-items: flex-start;
        }

        &__item-services {
            margin-left: 0;
        }

        &__delete-button {

        }

        &__section {
            width: calc(100% - 79px);
        }

        &__item-name {
            width: 100%;
        }
    }
}

.modal-favorite {
    width: 520px;
    position: absolute;
    right: 0;
    top: 40px;
    padding-bottom: 56px;
    background: #FFFFFF;
    z-index: 1;
    opacity: 0;
    transform-origin: top;
    transition: .3s;
    transform: scaleY(0);
    box-shadow: 0 100px 80px rgba(24, 48, 41, 0.07), 0 41.7776px 33.4221px rgba(24, 48, 41, 0.0503198), 0 22.3363px 17.869px rgba(24, 48, 41, 0.0417275), 0 12.5216px 10.0172px rgba(24, 48, 41, 0.035), 0 6.6501px 5.32008px rgba(24, 48, 41, 0.0282725), 0 2.76726px 2.21381px rgba(24, 48, 41, 0.0196802);

    & .modal__close {
        position: absolute;
        top: 14px;
        right: 14px;
    }

    &.active {
        opacity: 1;
        transform: scaleY(1);
    }

    // .modal-favorite__title

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        margin-top: 16px;
        margin-left: 24px;
    }

    // .modal-favorite__body

    &__body {
        margin-top: 20px;
    }

    // .modal-favorite__body-title

    &__body-title {
        text-align: center;
        margin-bottom: 14px;
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        color: $grey;
    }

    // .modal-favorite__body-subtitle

    &__body-subtitle {
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        max-width: 395px;
        margin: 0 auto;
    }
}


@media only screen and (max-width: 672px) {
    .modal-favorite {
        max-width: 430px;
        width: 100%;
        padding-bottom: 36px;

        & .modal__close {
            top: 10px;
            right: 10px;
        }

        // .modal-favorite__title

        &__title {
            font-size: 14px;
            margin-top: 14px;
            margin-left: 14px;
        }

        // .modal-favorite__body

        &__body {
            margin-top: 12px;
        }

        // .modal-favorite__body-title

        &__body-title {
            margin-bottom: 10px;
            font-size: 14px;
        }

        // .modal-favorite__body-subtitle

        &__body-subtitle {
            font-weight: normal;
            font-size: 12px;
            line-height: 150%;
            text-align: center;
        }
    }
}
