@import "../../../../scss/reset/reser-style";


.quiz-modal-wrap {
	width: 1070px;
	height: 720px;
	background: $white;
	position: relative;
	display: none;
	transition: .3s;
	&.active{
		display: block;
	}
}


.modal-quiz-start .modal-close, .quiz-modal-wrap .modal-close {
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
	transition: .2s;
	& svg path{
		transition: .2s;
	}
	&:hover{
		& svg path{
			fill: $main;
		}
	}
}

.quiz-modal-bg {
	position: absolute;
	right: 0;
	bottom: 0;
}
.quiz-back-btn{
	position: absolute;
	bottom: 56px;
	left: 100px;
	cursor: pointer;
	background: none;
	display: flex;
	align-items: center;
	font-weight: 600;
	font-size: 16px;
	line-height: 150%;
	color: rgba(0, 0, 0, 0.4);
	&:hover{
		color: $main;
		& svg path{
			stroke: $main;
		}
	}
	& svg{
		margin-right: 8px;
	}
}
.quiz-modal {
	padding: 60px 0;
	margin: 0 auto;
	max-width: 690px;
	// .quiz-modal__title

	&__title {
		font-weight: 600;
		font-size: 36px;
		line-height: 150%;
		text-align: center;
		margin-bottom: 40px;
	}

	// .quiz-modal__steps

	&__steps {
		position: relative;
		display: flex;
		justify-content: space-around;
		align-items: center;
		margin-bottom: 40px;
		height: 80px;
	}

	// .quiz-modal__steps-line

	&__steps-line {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 1px;
		margin: auto;
		background: linear-gradient(to right, rgba(37, 114, 38, 0.22), #257226);
	}

	// .quiz-modal__step-item

	&__step-item {
		width: 64px;
		height: 64px;
		background: $second-grey;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: .3s;
		box-shadow: 0px 0px 0px rgba(67, 176, 42, 0.5);
		cursor: not-allowed;
		pointer-events: none;
		&.active{
			cursor: pointer;
			pointer-events: auto;
			width: 80px;
			height: 80px;
			background: $main;
			box-shadow: 0px 0px 20px rgba(67, 176, 42, 0.5);
			& .quiz-modal__step-ico{
				opacity: 1;
			}
			& .quiz-modal__step-number{
				opacity: 0;
			}
		}
		&.filled{
			cursor: pointer;
			pointer-events: auto;
			width: 64px;
			height: 64px;
			background: $second;
			box-shadow: 0px 0px 0px rgba(37, 114, 38, 0.5);
			& .quiz-modal__step-number{
				color: $white;
			}
			&:hover{
				box-shadow: 0px 0px 20px rgba(37, 114, 38, 0.5);
			}
		}
	}

	&__range-value-min, &__range-value-max{
		font-size: 16px;
		line-height: 150%;
	}

	// .quiz-modal__step-ico

	&__step-ico {
		position: absolute;
		width: 40px;
		height: 40px;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		opacity: 0;
		transition: .3s;
	}

	// .quiz-modal__step-number

	&__step-number {
		font-weight: 600;
		font-size: 24px;
		line-height: 150%;
		color: $grey;
		transition: .2s;
		opacity: 1;
	}

	// .quiz-modal__form

	&__form {
		background: $white;
		border: 1px solid rgba(91, 100, 91, 0.06);
		box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.05);
		z-index: 1;
		position: relative;
		max-width: 420px;
		margin: 0 auto;
		& > .quiz-modal__input-wrap{
			width: fit-content;
			margin: 0 auto;
			margin-bottom: 12px;
			& .error{
				font-size: 10px;
				line-height: 120%;
				transition: .2s;
				opacity: 0;
				padding-left: 5px;
			}
			& .error.active{
				opacity: 1;
				& svg{
					margin-right: 4px;
				}
			}
		}
	}

	// .quiz-modal__form--square

	&__form--square {
	}

	// .quiz-modal__form-title

	&__form-title {
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		text-align: center;
		margin-top: 25px;
		margin-bottom: 27px;
		min-height: 48px;
		display: flex;
		justify-content: center;
		align-items: flex-end;
	}

	// .quiz-modal__quiz-modal__form-input

	&__form-input {
		width: 216px;
		height: 50px;
		display: block;
		text-align: center;
		font-size: 20px;
		line-height: 150%;
		border: 1px solid $second-grey;
		margin: 0 auto 32px auto;
		transition: .2s;

		&:hover{
			border: 1px solid #257226;
		}
		&:focus{
			border: 2px solid #257226;
		}
		&:not(:placeholder-shown) {
			border: 1px solid #257226;
		}
	}

	// .quiz-modal__range-input-wrap

	&__range-input-wrap{
		width: 300px;
		margin: 0 auto 30px auto;
	}

	// .quiz-modal__range-values-wrap

	&__range-values-wrap{
		display: flex;
		justify-content: space-between;
		margin-top: 8px;
		margin-left: 3px;
	}

	// .quiz-modal__range-input

	&__range-input {
		height: 1px !important;
		margin-bottom: 12px;
		border: none !important;
		& .noUi-horizontal{
			height: 1px !important;
			border: none !important;
			// background: #DBE2EA !important;
		}
		& .noUi-connects{
			height: 2px;
			background: #DBE2EA;
			border: none !important;
		}
		& .noUi-connect {
			background: #43B02A !important;
			height: 2px !important;
		}
		& .noUi-touch-area{
			width: 24px !important;
			height: 24px !important;
			border: none;
			box-shadow: 0px 0px 20px rgba(67, 176, 42, 0.6);
		}
	}

	// .quiz-modal__inputs-wrap

	&__inputs-wrap{
		display: flex;
		width: 304px;
		justify-content: space-between;
		flex-wrap: wrap;
		margin: 0 auto 20px auto;
	}

	// .quiz-modal__input-wrap

	&__input-wrap{
		& .error{
			font-size: 10px;
			line-height: 120%;
			transition: .2s;
			opacity: 0;
			padding-left: 5px;
		}
		& .error.active{
			opacity: 1;
		}
		& .quiz-modal__form-input{
			width: 140px;
			height: 40px;
			text-align: center;
			margin: 0;
			transition: .2s;
		}
	}

	// .quiz-modal__input-title

	&__input-title{
		font-size: 14px;
		line-height: 150%;
		margin-bottom: 4px;
	}

	// .quiz-modal__range-input--square

	&__range-input--square {
	}

	// .quiz-modal__range-value-min

	&__range-value-min {
	}

	// .quiz-modal__range-value-max

	&__range-value-max {
	}

	// .quiz-modal__submit-btn

	&__submit-btn {
		padding: 12px 45px;
		background: $second;
		color: $white;
		font-weight: 600;
		font-size: 14px;
		line-height: 150%;
		cursor: pointer;
		transition: .2s;
		display: block;
		margin: 0 auto 32px auto;
		&:hover{
			background: #124D13;
		}
		&.disabled{
			background: rgba(37, 114, 38, 0.5);
		}
	}
}


.modal-quiz-start {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: $white;
	z-index: 1;
	display: none;
	justify-content: center;
	align-items: center;
	&.active{
		display: none;
	}
	// .modal-quiz-start__bg

	&__bg {
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: -1;
	}

	// .modal-quiz-start__body

	&__body{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		transform: translatey(-10%);
	}

	// .modal-quiz-start__title

	&__title {
		font-weight: 600;
		font-size: 20px;
		line-height: 150%;
		margin-bottom: 67px;
		text-align: center;
		padding: 0;
	}

	// .modal-quiz-start__start-btn

	&__start-btn {
		font-weight: 600;
		font-size: 14px;
		line-height: 150%;
		color: $white;
		background: $second;
		padding: 12px 45px;

	}
}
.modal-close {
}


@media only screen and (max-width: 1072px) {
	.quiz-modal-wrap{
		width: 100%;
	}
}

@media only screen and (max-width: 976px) {
	.quiz-modal{
		max-height: 100vh;
		&__title{
			display: none;
		}
		&__form-input{
			font-size: 16px;
			width: 170px;
			height: 44px;
		}
		&__submit-btn{
			font-size: 14px;
		}
	}
	.quiz-modal-wrap{
		max-height: 100vh;
	}
	.modal-quiz-start.active{
		display: flex;
	}
	.quiz-modal-wrap .modal-close svg path{
		fill: rgba(14, 14, 14, 0.2);
	}
	.quiz-modal-wrap .modal-close{
		top: 14px;
		right: 14px;
	}
	.quiz-back-btn{
		display: none;
	}

	.quiz-modal-wrap{
		flex-direction: column;
		justify-content: center;
	}
	.quiz-modal{
		width: 100%;
		&__title{
			padding: 0 20px;
			display: none;
		}
		&__steps{
		}
	}
}

@media only screen and (max-width: 670px) {

}


@media only screen and (max-width: 562px) {
	.quiz-modal-wrap > .quiz-modal-bg{
		display: none;
	}
	.quiz-modal{
		&__form{
			border: none;
			box-shadow: none;
			& > .quiz-modal__input-wrap{
				margin-bottom: 18px;
			}
		}
		&__form-title{
			padding: 0 44px;
			min-height: 63px;
			margin-top: 45px;
			margin-bottom: 32px;
			display: flex;
			align-items: center;
		}
		&__range-input-wrap{
			max-width: 80%;
		}
		&__range-value-min, &__range-value-max{
			font-size: 14px;
		}
		&__range-input-wrap{
			margin-bottom: 60px;
		}
	}
}

@media only screen and (max-width: 420px) {
	.quiz-modal{
		padding-top: 75px;
		&__step-item, &__step-item.filled{
			width: 50px;
			height: 50px;
		}
		&__step-item.active{
			width: 65px;
			height: 65px;
		}
		&__steps{
			height: 65px;
		}
	}
}