@import "src/scss/style";

.new-scroll-header {
  top: 0;
  position: fixed;
  width: 100%;
  background: #183029;
  padding: 10px;
  z-index: 9999;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .catalog-lv1 {
      margin-top: 20px;
    }
  }
  .header-search__dropdown {
    top: 40px;
  }
  .header-catalog__button-text {
    color: $main;
  }
  .header-actions__item {
    svg {
      fill: $main;
      path {
        fill: $main;
        stroke: $main;
      }
    }
  }
  .header-search__bar {
    &::placeholder {
      @include subtitle-main-text;
      color: $main;
    }
  }
  .header-catalog__button {
    svg {
      fill: $main;
      path {
        fill: $main;
      }
    }
  }
  .header__search-btn {
    .header-search__ico {
      fill: $main;
      path {
        fill: $main;
        stroke: $main;
      }
    }
    &:hover {
      .header-search__ico {
        fill: $white;
        path {
          fill: $white;
          stroke: $white;
        }
      }
    }
  }
  .header-actions__logged {
    svg {
      fill: white;
      path {
        fill: white;
        stroke: white;
      }
    }
  }
}

.vibration-basket {
  animation: 6s ease-in-out 0s normal none infinite running vibration;
}
@keyframes vibration {
  0%,
  10%,
  20% {
    transform: rotate(0deg);
  }
  2%,
  6% {
    transform: rotate(-10deg);
  }
  4%,
  8% {
    transform: rotate(10deg);
  }
}

.header {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 10999;
  // .header__top

  &__top {
    background: $dark;
    height: 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    & .container {
      display: flex;
      justify-content: space-between;
      height: 100%;
    }
  }

  // .header__social-box

  &__social-box {
    display: flex;
    height: 100%;
    align-items: center;
  }

  // .header__social-link

  &__social-link {
    margin-right: 20px;
    & svg path {
      transition: 0.2s;
    }
    &:hover {
      & svg path {
        fill: $main;
      }
    }
  }

  // .header__top-info

  &__top-info {
    display: flex;
    height: 100%;
    align-items: center;
  }

  // .header__info-link

  &__info-link {
    margin-right: 16px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-bottom: 1px solid $white;
      transform: scaleX(0);
      transform-origin: center;
      transition: 0.3s;
    }
    &:hover {
      &::after {
        transform: scaleX(1);
      }
    }
  }

  // .header__info-ico

  &__info-ico {
    margin-right: 2px;
  }

  // .header__info-text

  &__info-text {
    font-size: 15px;
    color: $white;
  }

  // .header__phone

  &__phone {
    position: relative;
    //&:hover{
    //  & .header__phone-drop{
    //    z-index: 5;
    //    .header__phone-item{
    //      transform: translateY(0%);
    //    }
    //  }
    //}
    &-drop {
      transform: translateY(-500%);
      transition: 0.3s;
    }
    &:hover {
      .header__phone-drop {
        transform: translateY(0);
      }
    }
  }

  // .header__phone-top

  &__phone-top {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    & a {
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      color: $white;
    }
    &:hover {
    }
  }

  // .header__phone-ico

  &__phone-ico {
    margin-right: 4px;
  }

  // .header__arrow-down

  &__arrow-down {
    margin-left: 5px;
    cursor: pointer;
  }

  //.header__phone-drop

  &__phone-drop {
    z-index: 99;
    overflow: hidden;
    position: relative;
    background: #ffffff;
    width: 183px;
    display: flex;
    padding: 14px 22px;
    flex-direction: column;
    justify-content: center;
    .service-phone {
      margin-top: 8px;
      margin-bottom: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .phone-service-item {
        width: 111px;
        &:hover {
          a {
            color: #257226;
          }
        }
        p {
          color: #868686;
          font-weight: 500;
          font-size: 12px;
          line-height: 150%;
        }
        a {
          font-weight: 600;
          font-size: 12px;
          line-height: 150%;
          color: $black;
        }
      }
    }
    .work-days {
      margin-bottom: 12px;
      padding: 0 5px;
      &_item {
        display: flex;
        justify-content: space-between;
        .left {
          color: #257226;
          text-align: right;
          font-weight: 600;
          font-size: 12px;
          line-height: 150%;
        }
        .right {
          color: #000000;
          font-weight: normal;
          font-size: 12px;
          line-height: 150%;
        }
      }
    }
    .support-email {
      padding-top: 12px;
      border-top: 1px solid #dcdcdc;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        a {
          color: #257226;
        }
      }
      a {
        color: #000000;
        font-weight: normal;
        font-size: 12px;
        line-height: 150%;
        margin-left: 10px;
      }
    }
  }

  //.header__phone-item

  &__phone-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    z-index: 6;
    transition: 0.2s;
    & a {
      width: 111px;
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      color: $black;
      margin-left: 10px;
    }
    &:hover {
      a {
        color: #257226;
      }
    }
  }

  // .header__main

  &__main {
    background: $dark;
    height: 80px;
    & .container {
      display: flex;
      height: 100%;
      justify-content: space-between;
    }
  }

  // .header__logo-wrap

  &__logo-wrap {
    min-width: 143px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 5;
  }

  // .header__logo

  &__logo {
    object-fit: cover;
  }

  // .header__search-btn

  &__search-btn {
    width: 80px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      background: #43b02a;
    }
  }

  // .header__bottom

  &__bottom {
    background: $main;
    height: 48px;
    & .container {
      display: flex;
      justify-content: space-between;
      height: 100%;
    }
  }
}
.container {
}
.ar-down {
}
.header-search {
  position: relative;
  // .header-search__form

  &__form {
    height: 100%;
    display: flex;
    align-items: center;
  }

  // .header-search__bar

  &__bar {
    max-width: 550px;
    width: 550px;
    height: 40px;
    background: rgba(255, 255, 255, 0.3);
    @include subtitle-main-text;
    color: $white;
    padding-left: 16px;
    &::placeholder {
      @include subtitle-main-text;
      color: $white;
    }
    &::-webkit-search-cancel-button {
      display: none;
    }
    &:focus ~ .header-search__dropdown {
      box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.05);
      transform: scale(1);
      opacity: 1;
    }
  }

  // .header-search__ico

  &__ico {
    min-width: 34px;
    min-height: 34px;
  }

  // .header-search__dropdown

  &__dropdown {
    flex-direction: column;
    background: white;
    position: absolute;
    left: 0;
    right: 0;
    top: 65px;
    opacity: 0;
    transform: scale(0);
    transition: opacity 0.2s;
    &:hover {
      transform: scale(1);
      opacity: 1;
    }
  }

  // .header-search__drop-items

  &__drop-items {
    padding: 20px 0;
  }

  // .header-search__drop-item

  &__drop-item {
    padding: 4px 48px;
    @include main-text;
    color: $black;
    width: 100%;
    // transition: .2s;
    cursor: pointer;
    &:hover {
      background: $label;
    }
  }

  // .header-search__drop-category

  &__drop-category {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 16px;
    position: relative;
    & img {
      margin-right: 8px;
    }
    &::before {
      content: "";
      width: 93%;
      left: 0;
      right: 0;
      top: -1px;
      margin: 0 auto;
      position: absolute;

      height: 1px;
      background: $second-grey;
    }
  }

  // .header-search__drop-category-text

  &__drop-category-text {
    @include main-text;
    color: $black;
  }

  // .header-search__drop-category-val

  &__drop-category-val {
    margin-left: 5px;
    font-weight: 600;
    color: $black;
  }
}
.header-actions {
  display: flex;
  height: 100%;
  align-items: center;
  // .header-actions__modal-wrap

  &__modal-wrap {
    position: relative;
    max-width: 0;
    max-height: 0;
    margin: 0;
  }

  // .header-actions__item

  &__item {
    cursor: pointer;
    position: relative;
    &:not(:last-child) {
      margin-right: 24px;
    }
    & > svg {
      transition: 0.2s;
    }
    // &:hover > svg path{
    //     fill: $main;
    //     stroke: $main;
    // }
    &:hover > svg {
      transform: translateY(1px);
      z-index: 0;
    }
  }

  // .header-actions__item--favourite

  &__item--favourite {
  }

  // .header-actions__counter

  &__counter {
    position: absolute;
    top: -10px;
    right: -4px;
    border-radius: 50%;
    height: 22px;
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    background: $orange;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    z-index: 1;
  }

  // .header-actions__item--basket

  &__item--basket {
    margin-right: 0 !important;
  }
}
.header-catalog {
  height: 100%;
  position: relative;
  .grey_bg {
    display: none;
  }
  .catalog-name-item {
    display: none;
  }
  .slider-container {
    margin-top: 0 !important;
  }
  &.active {
    & .catalog-lv1 {
      transform: translateY(0);
      box-shadow: 0 15px 20px rgba(0, 0, 0, 0.05);
      transition-delay: 0s;
      & .catalog-lv1__item {
        transform: scaleY(1);
        margin-top: -2px;
        &:first-child {
          transition: 0.15s;
          transition-delay: 0.1s;
        }
        &:nth-child(2) {
          transition: 0.2s;
          transition-delay: 0.1s;
        }
        &:nth-child(3) {
          transition: 0.25s;
          transition-delay: 0.1s;
        }
        &:nth-child(4) {
          transition: 0.3s;
          transition-delay: 0.1s;
        }
        &:nth-child(5) {
          transition: 0.35s;
          transition-delay: 0.1s;
        }
        &:nth-child(6) {
          transition: 0.4s;
          transition-delay: 0.1s;
        }
        &:nth-child(7) {
          transition: 0.45s;
          transition-delay: 0.1s;
        }
        &:not(:last-child) {
          border-bottom: 1px solid $second-grey;
        }
      }
    }
  }
  // .header-catalog__button

  &__button {
    display: flex;
    height: 100%;
    align-items: center;
    cursor: pointer;
  }

  // .header-catalog__button-text

  &__button-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: $white;
    text-transform: uppercase;
    margin-left: 8px;
  }
}
.catalog-lv1 {
  position: absolute;
  transform: translateY(-100%);
  transform-origin: top;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0);
  z-index: -5;
  transition: 0.2s;
  transition-delay: 0.3s;
  // .catalog-lv1__item

  &__item {
    position: relative;
    width: 330px;
    // overflow: hidden;
    transform: translateY(-230%);
    transform-origin: top;
    // transition: .2s;
    &:first-child {
      transition: 0.15s;
      transition-delay: 0.3s;
    }
    &:nth-child(2) {
      transition: 0.2s;
      transition-delay: 0.3s;
      .catalog-lv2 {
        top: -59px;
      }
    }
    &:nth-child(3) {
      transition: 0.25s;
      transition-delay: 0.3s;
      .catalog-lv2 {
        top: -118px;
      }
    }
    &:nth-child(4) {
      transition: 0.3s;
      transition-delay: 0.3s;
      .catalog-lv2 {
        top: -177px;
      }
    }
    &:nth-child(5) {
      transition: 0.35s;
      transition-delay: 0.3s;
      .catalog-lv2 {
        top: -236px;
      }
    }
    &:nth-child(6) {
      transition: 0.4s;
      transition-delay: 0.3s;
      .catalog-lv2 {
        top: -295px;
      }
    }
    &:nth-child(7) {
      transition: 0.45s;
      transition-delay: 0.3s;
      .catalog-lv2 {
        top: -354px;
      }
    }
    &:not(:last-child) {
      border-bottom: 1px solid $second-grey;
    }
    &:hover {
      & .catalog-lv2 {
        opacity: 1;
        z-index: 5;
        // display: flex;
        transform: translateY(0%);
      }
    }
  }

  // .catalog-lv1__button

  &__button {
    display: flex;
    height: 60px;
    width: 330px;
    align-items: center;
    background: $white;
    cursor: pointer;
    &:hover {
      background: $second;
      & .catalog-lv1__item-name {
        font-weight: 600;
        // font-size: 20px;
        color: $white;

        & > a {
          color: inherit;
        }
      }
      & .catalog-lv1__ico,
      & .catalog-lv1__arrow svg {
        & path {
          fill: $white;
        }
      }
    }
  }

  // .catalog-lv1__ico-box

  &__ico-box {
    & img {
      width: 32px;
    }
    margin: 0 12px 0 32px;
  }

  // .catalog-lv1__ico

  &__ico {
  }

  // .catalog-lv1__item-name

  &__item-name {
    font-style: 14px;
    color: $black;
    padding: 10px 10px;
  }

  // .catalog-lv1__arrow

  &__arrow {
    position: absolute;
    right: 13px;
  }
}

.catalog-lv2 {
  .brands-slider-wrap {
    margin-bottom: 0;
  }
  position: absolute;
  left: 330px;
  top: 0;
  width: 965px;
  height: 414px;
  min-width: auto;
  background: $white;
  padding: 0 10px;
  opacity: 0;
  z-index: -1;
  transition: 0.2s;
  transform: scaleY(0);
  transform-origin: top;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.05);
  transition-delay: 0s;
  // .catalog-lv2__list
  .catalog-lv2-content {
    display: flex;
    padding-bottom: 10px;
  }
  .opt-category {
    position: absolute;
    bottom: 48px;
    margin-left: 18px;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #257226;
    width: 260px;
    a {
      color: inherit;
    }
  }
  .slider-container {
    margin-top: -10px;
    width: 90%;
    margin-left: 5%;
  }
  .brands-slider__item img {
    height: 100%;
    width: 80%;
  }
  &__list {
    padding: 20px 15px 20px 15px;

    height: 270px;
    min-width: 260px;
    &.filters-list {
      li {
        a {
          padding: 7px;
          &:hover {
            color: #257226;
            text-decoration: none;
            border-bottom: 1px solid #257226 !important;
          }
        }
      }
      .categories-filters-links {
        display: flex;
        .catalog-lv2__list-title {
          cursor: pointer;
          margin-right: 30px;
          padding: 7px;
          &:hover {
            color: #257226;
            text-decoration: none;
            border-bottom: 1px solid #257226 !important;
          }
        }
        svg {
          margin-right: 8px;
        }
      }
    }
    &:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }
    &-item {
      svg {
        margin-right: 7px;
      }
      &:hover {
        svg * {
          //path {
          fill: #257226 !important;
          //}
        }
      }
    }
  }

  // .catalog-lv2__list-title

  &__list-title {
    margin-bottom: 10px;
    @include subtitle-small;
    color: $second;

    & > a {
      color: inherit;
    }
  }

  // .catalog-lv2__list-item

  &__list-item {
    margin-bottom: 10px;
    text-decoration: none;
    display: flex;
    & img {
      width: 32px;
      margin-right: 5px;
    }
    & a {
      color: $black;
      font-size: 16px;
      border-bottom: 1px solid transparent;
      transition: 0.05s;
      padding: 0 !important;
      margin-right: 5px;
      &:hover {
        color: #257226;
        text-decoration: none;
        border-bottom: 1px solid #257226 !important;
      }
    }
  }
}
.header-about {
  height: 100%;
  display: flex;
  align-items: center;
  // .header-about__item

  &__item {
    @include subtitle-main-text;
    color: $white;
    transition: 0.1s;
    &:not(:last-child) {
      margin-right: 20px;
    }
    &:hover {
      color: $dark;
    }

    &--discount {
      color: $dark;
    }
  }
}
.header-selection {
  min-width: 22%;
  display: flex;
  height: 100%;
  align-items: center;

  // .header-selection__ico

  &__ico {
    & svg path {
      transition: 0.2s;
    }
  }

  // .header-selection__text

  &__text {
    @include subtitle-main-text;
    color: $white;
    margin-left: 4px;
    transition: 0.2s;
  }

  &:hover {
    & .header-selection__text {
      color: $dark;
    }
    & .header-selection__ico {
      & svg path {
        fill: $dark;
      }
    }
    & .header-selection__text {
      color: $dark;
    }
  }
}

.menu-btn-wrap {
  width: 40px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 5;
  display: none;
  height: 40px;
  & .menu-btn {
    width: 16px;
    height: 16px;
    position: relative;
    & span {
      height: 2px;
      background-color: $white;
      display: block;
      position: absolute;
      width: 100%;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
    }
    & span:nth-child(1) {
      top: 0px;
    }

    & span:nth-child(2) {
      top: 6px;
    }

    & span:nth-child(3) {
      top: 12px;
    }
  }
}

.menu-btn-wrap.active {
  & .menu-btn {
    & span {
      background: $black;
    }
    & span:nth-child(1) {
      top: 8px;
      transform: rotate(135deg);
    }
    & span:nth-child(2) {
      opacity: 0;
      left: -400px;
      transition: 1s;
    }
    & span:nth-child(3) {
      top: 8px;
      transform: rotate(-135deg);
    }
  }
}

.mobile-menu {
  display: none;
  position: fixed;
  z-index: -5;
  top: 0;
  left: 0;
  height: 100vh;
  min-height: 450px;
  width: 240px;
  background: $white;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateX(-200%);
  transition: 0.3s;
  .mobile-menu__phone-title {
    color: white;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .mobile-menu__bg {
    width: 100vw;
    height: 100vh;
    z-index: -1;
    position: absolute;
  }
  &.active {
    opacity: 1;
    z-index: 4;
    transform: translateX(0);
  }

  //.mobile-menu__overflow-wrap

  &__overflow-wrap {
    width: 240px;
    position: relative;
    height: calc(100% - 52px);
  }

  // .mobile-menu__login-btn

  &__login-btn {
    margin: 52px 20px 16px;
    display: flex;
    align-items: center;
  }

  // .mobile-menu__login-ico

  &__login-ico {
    margin-right: 8px;
  }

  // .mobile-menu__login-text

  &__login-text {
    @include secondary-text;
  }

  // .mobile-menu__body

  &__body {
    overflow-y: auto;
    width: 240px;
    height: 100vh;
    padding-top: 8px;
    border-top: 1px solid $second-grey;
  }

  // .mobile-menu__item

  &__item {
    height: 37px;
    width: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    @include main-text;
    color: $black;
    & a {
      @include main-text;
      color: $black;
    }
    &:hover {
      background: $second;
      color: $white;
      & > a {
        color: $white;
        font-weight: 600;
      }
      & > svg path {
        fill: $white;
      }
    }
  }

  // .mobile-menu__item--highlight

  &__item--highlight {
    & > a {
      @include main-text;
      font-weight: 600;
      color: $main;
    }
  }

  // .mobile-menu__lv2-body

  &__lv2-body {
    position: absolute;
    left: 50%;
    top: -53px;
    transform: translateX(100%) scale(0);
    opacity: 0;
    transition: opacity 0.2s;
    z-index: -1;
    background: $white;
    width: 240px;
    height: calc(100% + 52px);
  }

  // .mobile-menu__item--lv1

  &__item--lv1 {
    padding: 0 32px 0 20px;
    justify-content: space-between;
  }

  // .mobile-menu__lv2-head

  &__lv2-head {
    height: 47px;
    background: $second;
    display: flex;
    align-items: center;
    padding: 0 15px;
    & svg {
      // margin-right: 8px;
      transform: translateY(-1px);
    }
  }

  // .mobile-menu__lv2-head-title

  &__lv2-head-title {
    color: $white;
    @include subtitle-small;
  }

  // .mobile-menu__lv2-item

  &__lv2-item {
    padding-top: 16px;
    padding: 16px 20px 0 20px;
    & a {
      @include main-text;
      color: $black;
    }
  }

  // .mobile-menu__footer

  &__footer {
    width: 240px;
    background: $dark;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  // .mobile-menu__footer-body

  &__footer-body {
    display: flex;
    margin-left: 20px;
  }

  // .mobile-menu__phone-ico

  &__phone-ico {
    margin-right: 8px;
  }

  // .mobile-menu__phone-wrap

  &__phone-wrap {
    display: flex;
    flex-direction: column;
    margin: 7px 0;
    & a {
      @include secondary-text;
      color: $white;
    }
  }

  &__social-wrap {
    margin-left: 25px;
  }
}

.search-mobile-wrap {
  height: 50%;
  display: none;
  position: absolute;
  right: 10px;
  background: $main;
  width: fit-content;
}
.search-mobile {
  border-bottom: 1px solid $white;
  height: fit-content;
  transform: translateY(30%);
  display: flex;
  align-items: flex-end;
  width: fit-content;
  // .search-mobile__bar
  .header-search__dropdown {
    top: 30px;
  }
  .header-search__drop-item {
    padding: 4px 20px;
  }
  &__bar {
    background: none;
    font-size: 10px;
    color: $white;
    margin-bottom: 2px;
    transition: 0.3s;
    &::placeholder {
      font-size: 10px;
      color: $white;
    }
    &:focus {
      width: calc(100vw - 45px);
    }
    &:focus ~ .header-search__dropdown {
      box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.05);
      transform: scale(1);
      opacity: 1;
    }
    &::-webkit-search-cancel-button {
      display: none;
    }
  }

  // .search-mobile__btn

  &__btn {
    background: none;
    padding: 0;
    & .header-search__ico {
    }
  }
}
.header-search {
  // .header-search__ico

  &__ico {
    width: 24px;
  }
}

.header-actions__item--phone {
  display: none;
}

.header-actions:hover .header-actions__item:hover {
  & .header-actions__modal-wrap .modal {
    opacity: 1;
    transform: scaleY(1);
  }
}

@media (max-width: 1200px) {
  .header__info-text {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1110px) {
  .header-actions {
    min-width: fit-content;
    &__item {
      &:not(:last-child) {
        margin-right: 12px !important;
      }
    }
  }
  .header-search {
    margin: 0 20px;
    width: 50%;
  }
}

@media only screen and (max-width: 1050px) {
  .header {
    &__social-link {
      margin-right: 12px;
    }
    &__main {
      & .container {
        justify-content: space-between;
      }
    }
    &-catalog__button-text {
      font-size: 14px;
    }
    &-about__item {
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 1240px) {
  .filters-list {
    display: none;
  }

  .catalog-lv1__item {
    .slider-container {
      display: none;
    }
  }
  .new-scroll-header {
    display: none;
  }
  header {
    position: fixed !important;
  }
  .mobile-menu {
    display: block;
  }
  .header-actions {
    min-width: auto;
    &__item {
      & svg {
        width: 24px;
        height: auto;
      }
    }
    &__counter {
      width: 12px;
      height: 12px;
      font-size: 8px;
      top: 0px;
      right: 0px;
    }
    .header-actions__item--profile {
      display: none;
    }
    &__item--phone {
      display: block;
    }
  }
  .header__main {
    height: 40px;
    & .container {
      justify-content: space-between;
    }
  }
  .header-search {
    display: none;
  }
  .header__top {
    display: none;
  }
  .menu-btn-wrap {
    display: flex;
  }
  .header__logo-wrap {
    min-width: auto;
    width: 72px;
    height: 100%;
    margin-left: 50px;
  }
  .header-about,
  .header-selection {
    display: none;
  }
  .search-mobile-wrap {
    display: block;
  }
  .header-catalog__button-text {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    text-transform: none;
  }
}

@media only screen and (max-width: 868px) {
  .categories-filters-links {
    .catalog-lv2__list-title {
      display: block;
    }
  }
  .brands-slider__item img {
    width: 100%;
  }
  .catalog-lv2-content {
    display: block !important;
  }
  .catalog-lv2__list {
    height: fit-content !important;
    margin-bottom: 0 !important;
  }
  .catalog-lv2 {
    position: static;
    min-width: auto;
    max-width: 100%;
    max-height: 0;
    transition: 0.3s;
    height: fit-content !important;

    .opt-category {
      position: static;
    }
  }
  .catalog-lv1__arrow {
    transform: rotate(90deg);
    transition: 0.3s;
  }
  .header-catalog {
    &.active {
      .grey-bg {
        display: block;
        width: 100vw;
        height: 100vh;
        background: rgba(24, 35, 22, 0.3);
        z-index: 9999;
        position: fixed;
        top: 0;
        left: 0;
      }
    }
  }
  .catalog-lv1 {
    overflow-y: auto;
    height: 100vh;
    max-height: calc(100vh + 200px) !important;
    z-index: 10999;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;

    &:hover {
      .catalog-lv2 {
        opacity: 0;
      }
    }
    .catalog-lv1__arrow {
      transform: rotate(0deg) !important;
    }
    .hidden-catalog {
      border-bottom: none !important;
      .catalog-lv1__button {
        display: none;
        height: 0;
      }
    }
    .catalog-name-item {
      background: $main;
      display: block;

      .catalog-lv1__button {
        height: 50px;
        color: white;
        background: $main;
        svg {
          path {
            fill: white;
          }
        }
        padding-left: 32px;
        .catalog-prev-arrow {
          transform: rotate(180deg);
          margin-top: 5px;
          margin-left: -10px;
          margin-right: 10px;
        }
        .catalog-close {
          position: absolute;
          right: 12px;
        }
      }
    }
    &__item {
      margin-bottom: 10px;
      &.hidden-catalog {
        height: 0;
        margin-bottom: 0;
      }
      margin-top: 0 !important;
      max-height: 60px;
      transition: 0.5s;
      &.active {
        max-height: 6000px;
        & .catalog-lv2 {
          box-shadow: none;
          opacity: 1;
          z-index: 5;
          // display: flex;
          transform: translateY(0%);
        }
        & .catalog-lv2 {
          max-height: 6000px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .catalog-lv1 {
    max-height: 80vh;
    overflow-y: auto;
    &__item {
      max-height: 40px;
    }
    &__button {
      height: 40px;
    }
  }
  .catalog-lv2 {
    padding: 10px 10px 10px 20px;
    flex-direction: column;

    &__list:not(:last-child) {
      margin-right: 0;
      margin-bottom: 10px;
    }
    &__list-title {
      margin-bottom: 5px;
      font-size: 16px;
    }
    &__list-item {
      & a {
        font-size: 15px;
      }
    }
  }
}

@media only screen and (min-width: 483px) {
  .mobile-menu__item--lv1 {
    cursor: pointer;
    & .mobile-menu__lv2-body.active {
      transform: translateX(0) scale(1);
      opacity: 1;
      z-index: 2;
    }
  }
}

@media only screen and (max-width: 482px) {
  .mobile-menu {
    &__item--lv1 {
      .mobile-menu__lv2-body.active {
        left: 0 !important;
        transform: translateX(0) scale(1);
        z-index: 6;
        opacity: 1;
        top: 30px;
      }
    }
  }
}

@media only screen and (max-width: 380px) {
  .header-catalog.active {
    & .catalog-lv1 {
      transform: translateX(-10px) !important;
    }
  }
}