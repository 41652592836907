@import '../../scss/reset/reser-style';

.about-us {

    padding-top: 30px;
    // .about-us__block
    .partners-block {
        padding-bottom: 0;
    }
    &__block {
        padding: 40px 84px;
        margin-bottom: 64px;
        background: $white;
    }

    // .about-us__section

    &__section {
        display: flex;
    }

    // .about-us__section--top

    &__section--top{
        justify-content: space-between;
    }


    // .about-us__container

    &__container {
        width: 660px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        & .about-us__paragraph{
            font-size: 16px;
            line-height: 150%;
            & span{
                font-weight: 600;
            }
            &:not(:last-child){
                margin-bottom: 1.5em;
            }
        }
    }

    // .about-us__paragraph

    &__paragraph {
        &:not(:last-child){
            margin-bottom: 0.5em;

        }
    }

    // .about-us__achievement

    &__achievement {
        display: flex;
        width: 324px;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    // .about-us__achievement-item

    &__achievement-item {
        width: 160px;
        height: 160px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        &:first-child{
            & .about-us__achievement-text{
                width: 75px;
            }
        }
        &:nth-child(1), &:nth-child(4){
            background: $white;
            border: 0.5px solid #868686;
        }
        &:nth-child(1), &:nth-child(2){
            margin-bottom: 4px;
        }
        &:nth-child(2), &:nth-child(3){
            background: $main;
            & > div{
                color: $white;
            }
        }
    }

    // .about-us__achievement-number

    &__achievement-number {
        font-weight: 800;
        font-size: 48px;
        line-height: 150%;
    }

    // .about-us__achievement-text

    &__achievement-text {
        font-size: 16px;
        line-height: 150%;
    }

    // .about-us__achievement-text-small

    &__achievement-text-small {
        font-size: 14px;
        line-height: 150%;
    }

    // .about-us__achievement-text-bold

    &__achievement-text-bold {
        font-weight: 800;
        font-size: 16px;
        line-height: 150%;
    }

    // .about-us__tabs

    &__tabs {
        display: flex;
        justify-content: space-between;
        text-align: center;
    }

    // .about-us__tabs--goals

    &__tabs--goals{
        & .about-us__tab{
            width: 260px;
            & img{
                margin-bottom: 32px;
            }
            & .about-us__tab-text{
                text-align: center;
            }
        }
    }

    // .about-us__tabs--reasons

    &__tabs--reasons{
        & .about-us__tab{
            width: 212px;
            & img{
                margin-bottom: 24px;
            }
        }
    }

    // .about-us__tab

    &__tab {
        display: flex;
        flex-direction: column;
        align-items: center;
        & img{
            width: 80px;
            height: 80px;
        }
    }

    // .about-us__tab-text

    &__tab-text {
        font-size: 14px;
        line-height: 150%;
    }

    // .about-us__values

    &__values {
        display: flex;
        justify-content: space-between;
    }

    // .about-us__values-img

    &__values-img {
        width: 520px;
        height: 268px;
        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    // .about-us__values-text

    &__values-text {
        width: 520px;
        margin-left: 20px;
    }

    // .about-us__values-p

    &__values-p {
        font-size: 14px;
        line-height: 150%;
        margin-bottom: 16px;
    }

    // .about-us__list

    &__list {
    }

    // .about-us__list-item

    &__list-item {
        font-size: 18px;
        line-height: 150%;
        & .li-dot{
            margin-right: 8px;
        }
    }

    // .about-us__tab-title

    &__tab-title {
        text-align: center;
        font-weight: 800;
        font-size: 14px;
        line-height: 150%;
        margin-bottom: 8px;
    }

    // .about-us__slider-wrap

    &__slider-wrap{
        position: relative;
        padding: 0 30px;
        & .slider-arrow-left, & .slider-arrow-right{
            position: absolute;
            top: 50%;
            transform: translateY(-150%);
            transition: .1s;
            transform-origin: top;
            cursor: pointer;
            &:active{
                transform: scale(.8) translateY(-150%);
            }
        }
        & .slider-pagination{
            display: none;
        }
    }

    // .about-us__slider

    &__slider {
        & .slick-slide > div{
            display: flex;
        }
    }

    // .about-us__slider-item

    &__slider-item {
        position: relative;
        width: fit-content;
        max-width: fit-content;
        margin: 10px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover{
            & .about-us__slider-title{
                color: $main;
            }
        }
    }

    // .about-us__slider-img

    &__slider-img {
        width: 100%;
        & img{
            width: 100%;
            height: 170px;
            object-fit: cover;
        }
    }

    // .about-us__slider-date

    &__slider-date {
        position: absolute;
        top: 0;
        left: 0;
        background: $main;
        color: $white;
        font-size: 14px;
        line-height: 150%;
        padding: 2px 10px;
    }

    // .about-us__slider-title

    &__slider-title {
        min-height: 69px;
        font-weight: 600;
        font-size: 18px;
        line-height: 130%;
        text-align: center;
        display: flex;
        justify-content: center;
        margin-top: 10px;
        transition: .2s;
    }
}
.container {
}
.page-title {
}
.slider-container {
}
.subs-wrap {
}
.subs-container {
}
.subs-mail {

    // .subs-mail__title

    &__title {
    }

    // .subs-mail__img

    &__img {
    }

    // .subs-mail__form

    &__form {
    }

    // .subs-mail__email

    &__email {
    }

    // .subs-mail__sumbit

    &__sumbit {
    }
}
.btn-dark {
}
.mail-validation {

    // .mail-validation__error-ico

    &__error-ico {
    }

    // .mail-validation__error-text

    &__error-text {
    }
}

.about-us{
    .brands-slider-wrap {
        .container {
            padding-right: 0px;
            padding-left:  0px;
        }
    }
}

@media only screen and (max-width: 1196px) {
    .about-us{
        &__values{
            flex-direction: column-reverse;
        }
        &__values-img{
            margin: 0 auto;
        }
        &__values-text{
            margin: 0 auto;
            margin-bottom: 20px;
        }
    }
}

@media only screen and (max-width: 1100px) {
    .about-us{
        &__tabs--reasons{
            & .about-us__tab{
                padding: 0 10px;
            }
        }
    }
}

@media only screen and (max-width: 1020px) {
    .about-us{
        &__section--top{
            flex-direction: column;
        }
        &__achievement{
            margin: 0 auto;
        }
        &__container{
            width: 100%;
            margin-bottom: 20px;
        }
    }
}

@media only screen and (max-width: 976px) {
    .about-us{
        padding: 10px;
        & .page-title{
            margin: 24px 0;
        }
        &__block{
            padding: 15px;
            margin-bottom: 40px;
        }
        &__container{
            & .about-us__paragraph{
                font-size: 12px;
                &:not(:last-child){
                    margin-bottom: 0.5em;
                }
            }
        }
        &__tab{
            & img{
                width: 60px;
                height: 60px;
                margin-bottom: 12px !important;
            }
        }
        &__tab-text{
            text-align: center;
            font-size: 12px;
        }
        &__paragraph{
            font-size: 14px;
        }
        & .slider-container{
            margin-bottom: 0;
        }
        &__values-p{
            margin-bottom: 12px;
            font-size: 12px;
        }
        &__list-item{
            font-weight: 600;
            font-size: 12px;
        }
        &__tab-title{
            font-size: 12px;
            margin-bottom: 6px;
        }
        &__slider {
            &-wrap {
                .slider-arrow-left {
                    left: -15px;
                }
                .slider-arrow-right {
                    right: -15px;
                }
            }
        }
        &__slider-date{
            padding: 2px 10px;
            font-weight: 600;
            font-size: 14px;
        }
        &__slider-title{
            font-size: 12px;
            min-height: 32px;
            margin-top: 5px;
        }
    }
}

@media only screen and (max-width: 812px) {
    .about-us{
        &__tabs{
            flex-wrap: wrap;
            justify-content: center;
        }
        &__tab{
            width: 25%;
            margin-bottom: 12px;
            padding: 14px 11px;
        }
    }
}


@media only screen and (max-width: 660px) {
    .about-us{
        & .slick-arrow{
            display: none !important;
        }
        &__slider-wrap{
            padding: 0;
        }
        &__values-text{
            width: 100%;
        }
    }
}

@media only screen and (max-width: 612px) {
    .about-us__slider-wrap{
        & .slider-pagination{
            display: flex;
        }
    }
    .about-us__slider {
        &-item {
            margin: auto;
        }
    }
}

@media only screen and (max-width: 582px) {
    .about-us{
        &__achievement-item{
            width: 138px;
            height: 138px;
        }
        &__achievement-number{
            font-size: 38px;
        }
        &__achievement-text, &__achievement-text-small, &__achievement-text-bold{
            font-size: 12px;
        }
        &__achievement{
            width: 280px;
        }
    }
}

@media only screen and (max-width: 545px) {
    .about-us{
        &__values-img{
            width: 100%;
            height: fit-content;
        }
    }
}

// @media only screen and (max-width: 582px) {
//     .about-us{
//         &__tabs--reasons{
//             width: 258px;
//         }
//     }
// }