@import '../../../../scss/reset/reser-style';
.modal-basket--credit{
  background: #FFFFFF;
  padding: 24px 40px;
  .modal__close {
    position: absolute;
    right: 40px;
  }
  .empty-catalog-card {
    margin-bottom: -150px;
    margin-top: -50px;
  }
  height: fit-content;
  position: absolute;
  width: 960px;
  & .modal-basket {

    // .modal-basket__items-wrap

    &__items-wrap {
      overflow-x: hidden;
    }

    // .modal-basket__item-name

    &__item-name {
      width: 250px;
    }

    // .modal-basket__counter


    // .modal-basket__footer-top

    &__footer-top {
      justify-content: flex-end;
      margin-top: 16px;
    }


    // .modal-basket__footer-bottom

    &__footer-bottom {
      margin-top: 42px;
    }

    // .modal-basket__back-link

    &__back-link {
      text-decoration-line: none;
      cursor: pointer;
      background: none;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      color: #257226;
      padding-left: 20px;
      span {
        margin-left: -20px;
        margin-top: -2px;
        position: absolute;
      }
    }

    // .modal-basket__confirm-btn

    &__confirm-btn {
      text-align: center;
      padding: 13px;
      background: #257226;
      width: 278px;
      height: 44px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $white;
      cursor: pointer;
      transition: .3s;
      &:hover{
        background: #124D13;
      }
    }
  }

  .radio-wrap {
    // .radio-wrap__drop-section

    &__drop-section {
      width: 100%;
    }

    // .radio-wrap__drop-section-title

    &__drop-section-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      margin-bottom: 24px;
    }
  }
}



@media only screen and (max-width: 976px){
  .modal-basket--credit{
    width: 96%;
    max-height: 100vh;
    height: fit-content;
    overflow-y: auto;
  }
}

@media only screen and (max-width: 680px){
  .modal-basket--credit{
    padding: 14px;
    & .modal-basket{
      &__title{
        margin-bottom: 12px;
        font-size: 14px;
      }
      &__item-name{

      }
    }
  }
}

@media only screen and (max-width: 506px){
  .modal-basket--credit{
    & .modal-basket{
      &__footer-bottom{
        flex-direction: column-reverse;
      }
      &__confirm-btn{
        margin-bottom: 16px;
      }
    }
  }
}


@media only screen and (max-width: 480px){
  .modal-basket--credit{
    .credit-item {
      max-height: fit-content;
    }
  }
}