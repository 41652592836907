@import '../../../../scss/reset/reser-style';
.review-modal {
  background: $white;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.05);
  position: relative;
  margin: 0 20px;
  padding: 16px 16px 20px 16px;
  max-width: 600px;
  width: 100%;
  display: none;
  &.active{
    display: block;
  }
  & .close-btn {
    position: absolute;
    cursor: pointer;
    background: none;
    padding: 0;
    top: 10px;
    right: 10px;
  }
  & .product-page__container-title{
    font-size: 16px;
    margin: 0;
    margin-bottom: 16px;
  }
  & .review-add__rating{
    margin-bottom: 20px;
  }
  & .review-add__rating-stars {
    display: grid;
  }
}