@import '../../scss/reset/reser-style';

.services-page {
    margin-top: 32px;
    & .services {
        margin-bottom: 70px;

        // .services__item

        &__item {
            max-width: 410px;
            margin-bottom: 2%;
        }

        // .services__item--middle

        &__item--middle {
        }

        // .services__img

        &__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

@media only screen and (max-width: 976px) {
    .services-page {
        & .services {
            margin-bottom: 50px;
        }
    }
}

.white-service-name {
    color: white !important;
}