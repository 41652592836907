// px to rem
@function rem($pixels, $context: $mainFontSize) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }
  @if (unitless($context)) {
    $context: $context * 1px;
  }
  @return $pixels / $context * 1rem;
}

@mixin size($width,$height: $width) {
  width: $width;
  height: $height;
}

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}

@mixin flex-center {
  justify-content: center;
  align-items: center;
}

@mixin h1 {
  font-weight: bold;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

@mixin title {
  font-weight: 600;
  font-size: 36px;
  line-height: 150%;
}
@mixin description-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
}
@mixin subtitle {
  font-weight: bold;
  font-size: 18px;
  line-height: 150%;
}
@mixin button {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
@mixin subtitle-main-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
}
@mixin regular {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
}

@mixin subtitle-small {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
}
@mixin main-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}

@mixin secondary-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
}

@mixin link{
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-decoration-line: underline;
}

@mixin scroll-style {
  &::-webkit-scrollbar-button {
    width:6px;
    height:0px;
  }

  &::-webkit-scrollbar-track {
    background-color: $label;
    box-shadow:0px 0px 3px $label inset;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background-color: $main;
    box-shadow:0px 1px 1px #fff inset;
  }

  &::-webkit-resizer{
    width:4px;
    height:0px;
  }

  &::-webkit-scrollbar{
    width: 4px;
    transform: translateX(20px);
  }
}