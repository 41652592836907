@import "../../scss/reset/reser-style";

.compare-table-div {
    overflow: auto;
}
.compare-table-mb {
    margin-bottom: 100px;
}
.visible-mobile {
    display: none;
}
.simplebar-content-wrapper {
    height: 100% !important;
}
.simplebar-content {
    display: flex;
    align-items: center;
    flex-direction: column;
}
#compare-cards {
    @include scroll-style;

    &::-webkit-scrollbar{
        height: 10px;
    }
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
    opacity: 1;
}
.simplebar-scrollbar:before {
    background: $main !important;
}
.simplebar-track.simplebar-horizontal {
    border-radius: 5px;
    height: 12px !important;
}
.simplebar-scrollbar {
    height: 9px !important;
}
.simplebar-track {
    bottom: inherit !important;
    top: 0;
}

.solo-product {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.compare-page {
    margin-bottom: 100px;
    .product-card-box {
        justify-content: center;
    }
    & .container{
        overflow-x: hidden;
    }
    // tr.compare-page__list-title-wrap

    &__list-title-wrap{
        display: flex;
        padding: 15px 0;
        align-items: stretch;
        position: relative;
        width: 100%;
        background: inherit;
    }

    .compare-table__list.compare-table__list--cards{
        background: none;
        margin-top: 10px;
        margin-left: -10px;
        margin-bottom: 20px;
    }

    // .compare-page__list-title

    &__list-title {
        display: flex;
        align-items: center;
        position: sticky;
        text-align: center;
        width: fit-content;
        left: 50%;
        transform: translatex(-50%);
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
    }
}
.container {
}
.bread-crumbs {

    // .bread-crumbs__item

    &__item {
        font-size: 15px;
    }
}
.compare {

    // .compare__title

    &__title {
    }
}

.compare-filter {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    // .compare-filter__compare-products

    &__compare-products {
        display: flex;
        margin-right: 30px;
    }

    // .compare-filter__filter-title

    &__filter-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        margin-right: 20px;
    }

    // .compare-filter__dropdown

    &__dropdown {
        position: relative;
        display: flex;
        align-items: center;
        &.active{
            & .arrow-down{
                transform: rotate(180deg);
            }
            & .compare-filter__dropdown-body{
                transform: scaleY(1);
                z-index: 5;
            }
        }
    }

    // .compare-filter__dropdown-head

    &__dropdown-head {
        display: flex;
        cursor: pointer;
        min-width: 180px;
        & .compare-filter__item{
            color: $main;
            & span{
                color: $main;
            }
        }
        & .arrow-down{
            margin-left: 6px;
            transition: .2s;
            transform: rotate(0);
        }
    }

    // .compare-filter__item

    &__item {
        @include main-text;
    }

    // .compare-filter__item-quantity

    &__item-quantity {
        margin-left: 5px;
    }

    // .compare-filter__dropdown-body

    &__dropdown-body {
        position: absolute;
        left: -5%;
        right: 0;
        top: 24px;
        width: 110%;
        background: #FFFFFF;
        box-shadow: 0px 20px 40px rgba(219, 226, 234, 0.7), 0px 7.10219px 33.4221px rgba(219, 226, 234, 0.0503198), 0px 3.79717px 17.869px rgba(219, 226, 234, 0.0417275), 0px 2.12866px 10.0172px rgba(219, 226, 234, 0.035), 0px 1.13052px 5.32008px rgba(219, 226, 234, 0.0282725), 0px 0.470434px 2.21381px rgba(219, 226, 234, 0.0196802);
        transform: scaleY(0);
        transform-origin: top;
        transition: .3s;
        & .compare-filter__item{
            padding: 10px 12px;
            transition: .2s;
            cursor: pointer;
            &:hover{
                background: $label;

            }
            &:hover > span{
                // color: $second;
            }
        }
    }

    // .compare-filter__clear-btn

    &__clear-btn {
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        text-decoration-line: underline;
        padding: 0;
        cursor: pointer;
        background: none;
        transition: .2s;
        margin-left: 20px;
        &:hover{
            color: $main;
        }
    }

    // .compare-filter__compare-sort

    &__compare-sort {
        display: flex;
        align-items: center;
    }

    // .compare-filter__sort-form

    &__sort-form {
        display: flex;
        & .radio-wrap{
            &:not(:last-child){
                margin-right: 18px;
            }
            & .radio{
                @include main-text;
            }
        }
    }

    // .compare-filter__add-product-btn

    &__add-product-btn {
        display: flex;
        width: fit-content;
        margin: 40px auto 45px auto;
        align-items: center;
        cursor: pointer;
    }

    // .compare-filter__add-product-ico

    &__add-product-ico {
        margin-right: 12px;
    }

    // .compare-filter__add-product-text

    &__add-product-text {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
    }
}
.arrow-down {
}
.radio-wrap {
}
.radio {
}
.compare-table {
    width: max-content;
    & > tbody{
        overflow: hidden;
        width: 100%;
    }
    & > tbody > tr > td{
        position: relative;
        @include scroll-style;

        &::-webkit-scrollbar{
            height: 10px;
            position: fixed;
        }
    }
    // .compare-table__list

    &__list {
        background: $white;
        width: max-content;
        &:nth-of-type(2n) {
            background: #EEEEEE;
        }
        &>tbody{
            display: block;
        }
    }

    // .compare-table__list--cards

    &__list--cards {
        z-index: 1;
        background: #FCFCFD;
    }

    // .compare-table__item

    &__item {
        width: 290px;
        margin-right: 20px;
        text-align: center;
        padding: 12px;
        @include main-text;
    }

    // .compare-table__item--card

    &__item--card {
        & .product-card-box{
            margin-bottom: 0;
            & .delete-btn{
                position: absolute;
                z-index: 1;
                right: 12px;
                top: 12px;
                cursor: pointer;
            }
        }
        .product-card{
            &__price-section{
                margin-bottom: 0;
            }
            &__buy-btn{
                justify-content: flex-end;
            }
            &__name{
                text-align: left;
            }
        }
    }
}

@media only screen and (max-width: 1132px) {
    .compare-filter{
        justify-content: center;
        &__compare-sort{
            margin-top: 20px;
        }
    }
}

@media only screen and (min-width: 976px) {
    .compare-table {
        &__item--card {
            & .product-card-box {
                & .product-card__buy-btn {
                    transform: translateX(0);
                }
            }

            &:hover {
                & .product-card__buy-btn {
                    & svg {
                        transform: translateX(0);
                        animation-name: running;
                        animation-duration: 0.3s;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1120px) {
    .compare-table__item--card .product-card-wrap{
        max-width: 238px;
        width: auto;
        &:not(last-child){
            border-right: 1px solid $second-grey;
        }

    }
    .compare-table{
        &__item--card{
            padding: 0;
        }
    }
}
@media only screen and (max-width: 976px) {

    .compare-table {
        &__item--card {
            & .product-card-box {
                & .product-card__buy-btn {
                    transform: translateX(0);
                }
            }

            &:hover {
                & .product-card__buy-btn {
                    & svg {
                        transform: translateX(-145%);
                        animation: none;
                    }
                }
            }
        }
    }

    .compare-filter{
        &__filter-title{
            font-size: 12px;
        }
        &__item{
            font-size: 12px;
        }
        &__dropdown-head{
            min-width: 206px;
        }
    }
    .compare-page{
        .simplebar-content {
            display: block;
        }
        .product-card-wrap {
            min-width: 180px;
        }
        .product-card-box {
            justify-content: normal;
        }
        &__list-title-wrap{
            padding: 12px 0;
        }
        &__list-title{
            left: 50vw;
            font-size: 12px;
            max-width: 320px;
        }
    }
    .compare-table{
        margin-bottom: 70px;
        margin-top: 100px;
        // .compare-table__list--cards

        &__list--cards{
            .compare-table__item--card{
                width: auto;
                position: relative;
                height: 270px;
                width: 238px;
                & .product-card-box{
                    position: absolute;
                    & .delete-btn{
                        right: 0;
                        top: 0;
                    }
                }
            }
        }
        &__item{
            font-size: 12px;
            padding: 10px;
            width: 200px;
        }
        &__item--card{
            width: 200px !important;
        }
        .compare-table__list{
            // padding-right: 500px;
            // margin-right: -500px;
            overflow-x: visible;
        }
    }
    .product-card-wrap--compare{
        width: 200px !important;
        //height: 280px;
        & .product-card{
            &__img-wrap{
                margin: 0;
                width: auto;
            }
            &__buy-btn{
                width: fit-content;
                background: none;
                &:hover{
                    background: none;
                }
                & svg path{
                    fill: $black;
                    stroke: $black;
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .visible-mobile {
        display: block;
    }
    .compare-filter {
        &__compare-products{
            margin-right: 0;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 20px;
        }
        &__clear-btn{
            margin-left: 0;
        }
        &__filter-title{
            margin-left: 0;
            margin-bottom: 10px;
        }
        &__dropdown{
            margin-bottom: 14px;
        }
        &__compare-sort{
            margin-top: 0;
            flex-direction: column;
            align-items: flex-start;
        }
        &__sort-form{
            flex-direction: column;
            & .radio-wrap:not(:last-child){
                margin-bottom: 10px;
            }
        }
        &__add-product-btn{
            margin: 26px 0 24px 0;
        }
        &__add-product-ico{
            & svg{
                width: 20px;
                height: 20px;
            }
        }
        &__add-product-text{
            @include subtitle-small;
        }
    }

}

@media only screen and (max-width: 410px) {
    .compare-table{
        &__item{
            font-size: 12px;
            padding: 10px;
            width: 182px;
        }
        &__item--card{
            width: 182px !important;
            padding: 0;
        }
    }
    .product-card-wrap--compare{
        width: 150px !important;
    }
}

@keyframes running {
    0% {
        transform: translateX(0%);
    }
    50% {
        transform: translateX(-145%);
    }
    100% {
        transform: translateX(0%);
    }
}
