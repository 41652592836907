@import '../../../scss/reset/vars';
@import '../../../scss/reset/reser-style';
.bread-crumbs{
  display: flex;
  margin-top: 32px;
  margin-bottom: 32px;
  @include secondary-text;
  color: $grey;
  width: 100%;
  // overflow-y: auto;
  &__item{
    margin-right: 10px;
    color: inherit;
    min-width: max-content;
    display: block;
    &:not(:last-child){
      transition: .15s;
      &::after{
        content: '/';
        display: inline-block;
        transform: translateX(5px);
      }
      &:hover{
        color: $main;
      }
    }
  }
  &__dots,
  &__dots.show {
    display: none;
  }
}
@media only screen and (max-width: 1024px) {
  .bread-crumbs{
    font-size: 11px;
    line-height: 120%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 740px) {
  .bread-crumbs {
    display: block;
    &__item {
      display: inline-block;
      min-width: auto;
      margin: 0 10px 7px;
      line-height: 1.2;
      color: #000;
      font-weight: bold;
      &:not(:last-child):after {
          margin-right: -5px;
      }
    }
    &__dots {
        font-size: 24px;
        line-height: 0;
        display: inline-block;
        margin: 0 8px;
        &:not(.show) ~ .bread-crumbs__item {
            display: none;
        }
    }
  }
}