@import '../../../../scss/reset/reser-style';
.buy-on-click {
  width: 370px;
  background: #FFFFFF;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.05);
  position: relative;
  flex-direction: column;
  align-items: center;
  display: none;
  &.active{
    display: flex;
  }
  // .buy-on-click__title

  &__title {
    margin-top: 60px;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
  }

  // .buy-on-click__subtitle

  &__subtitle {
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 40px;
    max-width: 250px;
    text-align: center;
  }

  // .buy-on-click__done-btn

  &__done-btn {
    display: block;
    width: fit-content;
    margin-bottom: 60px;
  }

  & .close-btn {
    position: absolute;
    cursor: pointer;
    background: none;
    padding: 0;
    top: 10px;
    right: 10px;
  }
}