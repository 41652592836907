@import '../../../scss/reset/vars';
@import '../../../scss/reset/reser-style';

.slider-container--product{
  margin-bottom: 64px !important;
}
.product-specifications-tag {
  position: absolute;
  top: 4px;
  right: 10px;
  z-index: 1;
  .wifi {
    margin-bottom: 6px;
    margin-left: 20px;
  }
  .temperature {
    display: flex;
    margin-top: 6px;
    margin-left: 20px;
    .text {
      color: #2DD9FD;
      font-weight: 500;
      font-size: 10.3704px;
      line-height: 150%;
    }
  }
}

.card-name-box {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 43px;
}

.hidden {
  display: none !important;
}

.products-slider-wrap {
  & .slider-arrow-left, & .slider-arrow-right {
    position: absolute;
    top: 50%;
    transform: scale(1) translateY(25%);
    transition: .1s;
    transform-origin: center;
    &:active{
      transform: scale(0.8) translateY(25%);
    }
  }
}
.slider-container{
  margin: 40px auto 40px auto !important;
  width: calc(100% - 80px);
}
.slider-arrow-left {
  left: -40px;
}
.slider-arrow-right {
  right: -40px;
}
.products-slider {
}
.products-slider--new-products {
}
.product-slide-wrap {
  padding: 16px;
  position: relative;
  margin-top: 32px;
  margin-bottom: 32px;
  transition: .2s;
  max-width: 258px;
  backface-visibility: hidden;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.0);
  .product-specifications-tag {
    display: none;
  }
  &:hover {
    .product-specifications-tag {
      display: block;
    }
  }
  //&::after{
  //  content: '';
  //  position: absolute;
  //  top: 0;
  //  right: 0px;
  //  height: 100%;
  //  width: 1px;
  //  background: $second-grey;
  //}
  &:hover{
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
    transform: translateY(-1%);
    z-index: 1;
    &::after{
      opacity: 0;
    }
  }
  & .product-card__payment-mobile{
    margin-left: 45px;
    display: flex !important;
    flex-wrap: wrap;
    & img{
      width: 20px;
      height: 20px;
      display: inline-block;
      &:not(:last-child){
        margin-right: 10px;
      }
    }
  }
}

.product-slider--label-wrap{
  position: absolute;
}

.product-card {
  background: $white;
  position: relative;
  margin-top: 20px;
  & .product-card__buy-btn{
    &:hover{
      background: $second;
    }
    svg {
      margin-top: 3px;
    }
    & svg path{
      transition: 0.3s;
    }
  }
  &:hover{
    & .product-card__buy-btn{
      animation: 0.5s buy-btn 1 both;
      width: 83px;
      height: 26px;
      & svg{
        transition: 0.3s;
        transition-delay: 0.1s;
        transform: translateX(145%);
      }
      & svg path{
        fill: $white;
        stroke: $white;
      }
    }
  }
  // .product-card__label-wrap
  &__label-wrap{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }

  // .product-card__label

  &__label {
    position: relative;
    top: 0;
    left: 0;
    z-index: 10;
    height: 20px;
    width: fit-content;
    padding: 0 18px;
    display: flex;
    align-items: center;
    @include secondary-text;
    color: $white;
    &:not(:last-child){
      margin-bottom: 4px;
    }
  }

  // .product-card__label--new

  &__label--new {
    background: $second;
  }

  // .product-card__label--discount

  &__label--discount {
    background: #FB8200;
  }


  // .product-card__img-wrap

  &__img-wrap {
    max-width: 206px;
    min-width: 100%;
    min-height: 140px;
    height: 200px;
    display: flex;
    justify-content: center;
    & img{
      object-fit: cover;
      height: 200px;
    }
  }

  // .product-card__name

  &__name {
    @include subtitle-main-text;
    color: $black;
    min-height: 48px;
    margin-bottom: 4px;
  }

  // .product-card__rating

  &__rating {
    display: flex;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  // .product-card__stars

  &__stars {
    display: flex;
    align-items: center;
    margin-right: 10px;
    & img{
      min-height: 11px !important;
      min-width: 11px !important;
      margin-top: -2px;
    }
  }

  // .product-card__response-link

  &__response-link {
    font-size: 12px;
    line-height: 150%;
    color: $grey;
    position: relative;
    &::after{
      content: '';
      position: absolute;
      bottom: 2px;
      left: 0;
      right: 0;
      height: 1px;
      background: $grey;
      width: 100%;
    }
  }

  // .product-card__price-section

  &__price-section {
    min-height: 54px;
    height: 54px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 16px;
  }

  // .product-card__discount

  &__discount {
    display: flex;
    margin-bottom: 2px;
  }

  // .product-card__discount-val

  &__discount-val {
    font-size: 14px;
    line-height: 150%;
    text-decoration-line: line-through;
    color: $grey;
    margin-right: 3px;
  }

  // .product-card__discount-percentage

  &__discount-percentage {
    padding: 2px 16px;
    margin-left: 10px;
    background: #FB8200;
    @include main-text;
    color: $white;
  }

  // .product-card__price-wrap

  &__price-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  // .product-card__price

  &__price {
    @include subtitle;
    color: $black;

  }

  // .product-card__credit-conditions

  &__credit-conditions {
    @include secondary-text;
    color: $grey;
  }

  // .product-card__ui-section

  &__ui-section {
    display: flex;
    justify-content: space-between;
  }

  // .product-card__buy-btn

  &__buy-btn {
    cursor: pointer;
    &:hover{
      & svg, & svg path{
        fill: $main;
        stroke: $main;
      }
    }
  }

  // .product-card__btns-wrap

  &__btns-wrap {
    display: flex;
  }

  // .product-card__fav-btn

  &__fav-btn {
    cursor: pointer;
    & svg .fill{
      fill: transparent;
    }
    & svg .line{
      fill: $black;
    }
    &:hover{
      & svg .line{
        fill: #DB4915;
      }
    }
    &:active, &.active{
      & svg, & svg path{
        fill: #DB4915;
      }
    }
  }

  // .product-card__compare-btn

  &__compare-btn {
    cursor: pointer;
    & svg .fill{
      fill: transparent;
    }
    & svg .circle, & svg .arrow{
      fill: $black;
    }
    &:hover{
      & svg .circle, & svg .arrow{
        fill: $main;
      }
    }
    &:active, &.active{
      & svg .fill, & svg .circle{
        fill: $main;
      }
      & svg .arrow{
        fill: $white;
      }
    }
  }
}


@media only screen and (max-width: 976px) {
  .product-card{
    &__name{
      font-size: 14px;
      margin-bottom: 0;
    }
    &__discount-val{
      font-size: 12px;
    }
    &__discount-percentage{
      font-size: 10px;
    }
    &__discount{
      margin-bottom: 0;
    }
    &__price{
      font-size: 14px;
    }
    &__credit-conditions{
      font-size: 10px;
    }
    &__price-section{
      min-height: 42px;
      height: auto;
    }
  }
  .products-slider-wrap{
    padding: 20px 8px 4px 8px;
    & .slider-arrow-left, & .slider-arrow-right{
      display: none !important;
    }
  }
  .slider-container{
    background: $white;
    margin: 0 0 40px 0;
    width: 100%;
    padding-left: 20px;
    & .main-page__title{
      margin-top: 24px;
      margin-bottom: -10px !important;
    }
  }
  .product-slide-wrap{
    margin: 4px 0 16px 0;
    padding: 0 12px 12px 12px;
    & .product-card__payment{
      top: -23px;
      display: flex;
      flex-wrap: wrap;
      & img{
        width: 20px;
        height: 20px;
        &:not(:last-child){
          margin-right: 8px;
        }
      }
    }
  }
}

@media only screen and (max-width: 410px) {
  .products-slider{
    & .slick-slide{
      & > div{
        display: flex;
        justify-content: center;
      }
    }
  }
}
.slider-container--product{
  margin-bottom: 64px;
}

.products-slider-wrap {
  & .slider-arrow-left, & .slider-arrow-right {
    position: absolute;
    top: 50%;
    transform: scale(1) translateY(25%);
    transition: .1s;
    transform-origin: center;
    &:active{
      transform: scale(0.8) translateY(25%);
    }
  }
}
.slider-arrow-left {
  left: -40px;
}
.slider-arrow-right {
  right: -40px;
}
.products-slider {
}
.products-slider--new-products {
}
.product-slide-wrap{
  padding: 16px;
  position: relative;
  margin-top: 32px;
  margin-bottom: 32px;
  margin: 32px 10px 0;
  transition: .2s ;
  transform: translateY(0);
  background: $white;
  //&::after{
  //  content: '';
  //  position: absolute;
  //  top: 0;
  //  right: 0px;
  //  height: 100%;
  //  width: 1px;
  //  background: $second-grey;
  //}
}
.product-slide-wrap:hover{
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
  transform: translateY(-1%);
}
@media only screen and (max-width: 976px) {
  .products-slider-wrap .slider-arrow-left,
  .products-slider-wrap .slider-arrow-right {
      display: block !important;
      z-index: 1;
  }
  .slider-arrow-right {
      right: 0;
  }
  .slider-arrow-left {
      left: 0;
  }
}