@import '../../scss/reset/reser-style';

.services-info {
    position: relative;
    // .services-info__bg-left

    &__bg-left {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    // .services-info__bg-right

    &__bg-right {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
    }

    // .services-info__title

    &__title {
        margin-bottom: 83px;
        padding-top: 43px;
        @include title;
        color: $second;
        text-align: center;
    }

    // .services-info__head

    &__head {
        display: flex;
        padding: 24px;
        justify-content: space-between;
        margin-bottom: 48px;
    }

    // .services-info__head-list

    &__head-list {
        width: 49.5%;
    }

    // .services-info__head-list-title

    &__head-list-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 16px;
    }

    // .services-info__head-list-item

    &__head-list-item {
        @include main-text;
        display: flex;
    }

    // .services-info__li-dot

    &__li-dot{
        height: 100%;
        padding-right: 8px;

        box-sizing: border-box;
        & span{
            @include main-text;
        }
        & img{
            width: 16px;
            min-width: 16px;
            height: 16px;
        }
    }

    // .services-info__head-alert

    &__head-alert {
        border: 1px solid $grey;
        padding: 24px;
        height: fit-content;
        width: 49.5%;
        margin-top: 12px;
    }

    // .services-info__alert-title

    &__alert-title {
        @include subtitle-small;
        color: #DB4915;
        margin-bottom: 24px;
    }

    // .services-info__alert-list

    &__alert-list {
    }

    // .services-info__alert-list-item

    &__alert-list-item {
        @include main-text;
    }

    // .services-info__table-section

    &__table-section{
        margin-bottom: 32px;
    }

    // .services-info__table-section--type2

    &__table-section--type2{
        margin-bottom: 48px;
        & .services-info__table:not(:last-child), & .services-info__description{
            margin-bottom: 40px;
        }
        &:last-child{
            margin-bottom: 100px;
        }
    }

    // .services-info__table

    &__table {
        width: 100%;
        & th{
            background: $main;
            color: $white;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            padding: 16px 24px;
            vertical-align: middle;
            &:not(:first-child){
                text-align: center;
            }
        }
        & td{
            border: 1px solid $second-grey;
            padding: 12px 24px;
            font-size: 14px;
            line-height: 150%;
            vertical-align: middle;
            &:not(:first-child){
                text-align: center;
            }
        }
    }

    // .services-info__table-description

    &__table-description {
        margin-top: 24px;
        @include main-text;
    }

    // .services-info__descr-list

    &__descr-list {
        padding: 32px 24px;
        margin-bottom: 0;
    }

    // .services-info__descr-list-title

    &__descr-list-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 16px;
    }

    // .services-info__descr-list-item

    &__descr-list-item {
        display: flex;
        @include main-text;
        & a{
            color: $main;
        }
    }

    // .services-info__description

    &__description {
    }

    // .services-info__description-title

    &__description-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 16px;
    }

    // .services-info__description-text

    &__description-text {
        @include main-text;
    }
}

.bread-crumbs {
   &__item {
        font-size: 15px;
    }
}

@media only screen and (max-width: 976px) {
    .services-info{
        &__bg-left, &__bg-right{
            width: 20%;
            height: auto;
        }
        &__title {
            padding-top: 14px;
            margin-bottom: 30px;
            font-size: 24px;
        }
        &__head{
            padding: 16px 0px;
            flex-direction: column;

        }
        &__head-list{
            width: 100%;
        }
        &__head-alert{
            width: 100%;
            margin-top: 24px;
            padding: 16px;
        }
        &__alert-title{
            margin-bottom: 8px;
        }
        &__alert-list-item{
            font-size: 12px;
        }
        &__head{
            margin-bottom: 24px;
        }
        &__head-list-title{
            font-size: 14px;
            font-size: 12px;
        }
        &__head-list-item{
            font-size: 12px;
        }
        &__table{
            & td, & th{
                font-size: 10px;
                padding: 8px 1px 8px 8px;
            }
        }
        &__table-description{
            margin-top: 8px;
            font-size: 10px;
        }
        &__table-section{
            margin-bottom: 24px;
        }
        &__descr-list{
            padding: 16px 0px;
            margin-bottom: 24px;
        }
        &__descr-list-title{
            font-size: 14px;
        }
        &__descr-list-item{
            font-size: 12px;
        }
        &__table-section--type2{
            margin-bottom: 24px;
            & .services-info__table:not(:last-child), & .services-info__description{
                margin-bottom: 32px;
            }
            &:last-child{
                margin-bottom: 70px;
            }
        }
    }
}