@import '../../scss/reset/reser-style';

.content-filter {
    position: relative;
    height: fit-content;
    & .arrow-down{
        transition: .2s;
    }
    &.active{
        & .arrow-down{
            transform: rotate(180deg);
        }
        & .content-filter__dropdown{
            transform: scaleY(1);
            z-index: 1;
        }

    }
    // .content-filter__head

    &__head {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $second-grey;
    }

    // .content-filter__title

    &__title {
        @include subtitle-main-text;
        cursor: pointer;
    }

    // .content-filter__picked

    &__picked {
        display: flex;
        justify-content: space-between;
        width: 189px;
        margin-left: 3px;
        cursor: pointer;
        & .content-filter__item{
            @include main-text;
            margin-left: 5px;
            margin-top: 2px;
        }
    }

    // .content-filter__item

    &__item {
        @include secondary-text;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    // .content-filter__dropdown

    &__dropdown {
        box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
        position: absolute;
        width: 100%;
        top: 26px;
        transform: scaleY(0);
        transform-origin: top;
        transition: .3s;
        & .content-filter__item{
            height: 40px;
            display: flex;
            align-items: center;
            padding: 1px 8px;
            background: $white;
            transition: .2s;
            cursor: pointer;
            &:hover{
                background: $label;
                color: $second;
            }
        }
    }
}
@media only screen and (max-width: 976px) {
    .content-filter{
        &__title{
            font-size: 12px;
        }
        &__item{
            font-size: 12px;
        }
        &__picked{
            & .content-filter__item{
                font-size: 12px;
            }
        }
        &__dropdown{
            &__item{
                height: 38px;
                font-size: 10px;
            }
        }
    }
}

.blog {

    // .blog__header

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 32px 0;
        & .page-title {
            margin-bottom: 0;
        }
    }

    // .blog__body

    &__body {
        display: grid;
        grid-template-columns: 32% 32% 32%;
        column-gap: 2%;
    }
    & .pagination-wrap{
        margin: 20px auto 100px auto;
    }
}

.blog-item-wrap {
    width: 100%;
    margin-bottom: 6%;
    &:hover{
        & .blog-item{
            box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
        }
    }
}
.blog-item {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    transition: .2s;
    // .blog-item__img

    &__img {
        width: 100%;
        height: 190px;
        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    // .blog-item__description

    &__description {
        padding: 24px;
        background: $white;
    }

    // .blog-item__info

    &__info {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        flex-wrap: wrap;
    }

    // .blog-item__category

    &__category {
        padding: 5px 18px;
        color: $white;
        @include subtitle-small;
        margin-right: 16px;
    }

    // .blog-item__category--air-clean

    &__category--air-clean {
        background: $main;
    }

    // .blog-item__publication-date

    &__publication-date {
        @include main-text;
        color: $grey;
    }

    // .blog-item__title

    &__title {
        @include subtitle;
        min-height: 54px;
        margin-bottom: 16px;
    }

    // .blog-item__subtitle

    &__subtitle {
        @include main-text;
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        //height: 86px;
        img {
            display: none;
        }
    }

    // .blog-item__category--energy-saving

    &__category--energy-saving {
        background: #BB6BD9;
    }

    // .blog-item__category--heating

    &__category--heating {
        background: #DB4915;
    }

    // .blog-item__category--dehumidifier

    &__category--dehumidifier {
        background: #F2C94C;
    }

    // .blog-item__category--vac-clean

    &__category--vac-clean {
        background: #174C94;
    }

    // .blog-item__category--ventilation

    &__category--ventilation {
        background: #82C0CC;
    }

    // .blog-item__category--conditioning

    &__category--conditioning {
        background: #008792;
    }
}

@media only screen and (max-width: 1165px) {
    .blog-item{
        &__category{
            font-size: 10px;
            margin-right: 8px;
        }
        &__publication-date{
            font-size: 10px;
        }
        &__description{
            padding: 16px 8px;
        }
    }
}

@media only screen and (max-width: 976px) {
    .blog-item{
        &__info{
            margin-bottom: 8px;
        }
        &__title{
            min-height: 42px;
            font-size: 14px;
            margin-bottom: 8px;
        }
        &__subtitle{
            font-size: 12px;
        }
        &__img{
            height: 130px;
        }
    }
    .blog{
        .container {
            padding-right: 20px;
            padding-left: 20px;
        }
        & .pagination-wrap{
            margin: 10px auto 70px auto;
        }
    }
}


@media only screen and (max-width: 840px) {
    .blog__body {
        display: grid;
        grid-template-columns: 49% 49%;
        column-gap: 2%;
    }
}

@media only screen and (max-width: 542px) {
    .blog__body {
        display: grid;
        grid-template-columns: 100%;
        column-gap: 0%;
    }
}
@media only screen and (max-width: 434px) {
    .blog{
        &__header{
            flex-direction: column;
            align-items: flex-start;
        }
        & .content-filter{
            margin-top: 10px;
        }
    }
}
