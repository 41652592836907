@import '../../../../scss/reset/reser-style';
.entrance-modal {
  background: #FFFFFF;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.05);
  padding: 32px;
  max-width: 392px;
  position: relative;
  transition: .2s;
  display: none;
  &.active{
    display: block;
  }

  .login-social-block {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .grey-line {
      background: #DCDCDC;
      height: 1px;
      width: 100%;
      margin-bottom: 14px;
    }
    .social-title {
      background: #FFFFFF;
      align-self: center;
      padding-left: 10px;
      padding-right: 10px;
      margin-top: -25px;
      width: 50px;
      margin-bottom: 14px;
    }
    .socials {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 14px;
      .social-item-facebook {
        padding: 15px 65px;
        background: #F7F7F9;
        cursor: pointer;
      }
      .social-item-google {
        box-shadow: none !important;
        border: none !important;
        div {
          padding: 15px 65px !important;
          margin: 0 !important;
          background: #F7F7F9 !important;
          cursor: pointer;
        }
        span {
          display: none;
        }
      }
    }
  }

  // .entrance-modal__title

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    margin-bottom: 16px;
  }

  // .entrance-modal__subtitle

  &__subtitle {
    font-size: 16px;
    line-height: 150%;
  }

  // .entrance-modal__switch-link

  &__switch-link {
    cursor: pointer;
    font-size: 16px;
    line-height: 150%;
    color: $grey;
    text-decoration-line: underline;
    margin-bottom: 32px;
    transition: .2s;
    display: block;
    &:hover{
      color: $main;
    }
  }

  // .entrance-modal__form
  .validation {
    margin-top: -15px;
    margin-bottom: 15px;
  }

  & .entrance-modal__form .mail-validation{
    opacity: 0;
  }

  // .entrance-modal__btn

  &__btn {
    cursor: pointer;
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $white;
    background: $main;
    margin-top: 8px;
    transition: .2s;
    padding: 10px;
    & span{
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $white;
      margin: 0 0.2em;
    }
    &:hover{
      background: #257226;
    }
  }

  // .entrance-modal__secondary-text

  &__secondary-text {
    font-size: 16px;
    line-height: 150%;
    color: $grey;
  }
  & .form__input{
    width: 100%;
  }
  & .form__label{
    width: 100%;
    display: block;
    margin-bottom: 20px !important;
  }
  & .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0;
    background: none;
    cursor: pointer;
  }
  & .mail-validation {
    opacity: 1;
    margin-left: 14px;
    display: flex;
    align-items: center;
    margin-top: 2px;
    // .mail-validation__error-ico

    &__error-ico {
    }

    // .mail-validation__error-text

    &__error-text {
      font-weight: 500;
      font-size: 10px;
      line-height: 120%;
      color: #DB4915;
      width: 250px;
    }
  }
  & .form__label--email{
    margin-bottom: 24px;
  }

  & .forgot-password {
    cursor: pointer;
    display: block;
    font-size: 14px;
    line-height: 150%;
    color: $grey;
    margin-top: 8px;
    transition: .2s;
    &:hover{
      color: $main;
    }
  }

  & .checkbox-label {
    margin-top: 16px;
    .checkbox{
      align-items: flex-start;
      & img{
        margin: 0;
        top: 8px;
      }
      &::before{
        margin-top: 3px;
      }
    }
    & .input-text{
      font-size: 12px;
      line-height: 150%;
      color: $grey;
      & a{
        text-decoration-line: underline;
        color: $grey;
      }
    }
  }
  & .entrance {
  }
  & .forgot {
    & .entrance-modal__subtitle{
      margin-bottom: 10px;
    }
    & .entrance-modal__secondary-text{
      margin-bottom: 24px;
    }
  }



  & .success {
    height: 509px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // .success__title

    &__title {
      font-weight: 600;
      font-size: 24px;
      line-height: 150%;
      margin-bottom: 36px;
      text-align: center;
    }

    // .success__sub-title

    &__sub-title {
      font-size: 16px;
      line-height: 150%;
      margin-bottom: 50px;
      text-align: center;
    }
  }
}