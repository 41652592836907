@import '../../scss/reset/reser-style';

.seminar-page {

    // .seminar-page__body

    &__body {
    }

    // .seminar-page__title

    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
        margin-bottom: 34px;
    }

    // .seminar-page__article

    &__article {
        margin-bottom: 70px;
    }

    // .seminar-page__paragraph

    &__paragraph {
        font-size: 16px;
        line-height: 150%;
    }

    // .seminar-page__list

    &__list {
        margin-top: 1.2em;
    }

    // .seminar-page__list-item

    &__list-item {
        font-size: 16px;
        line-height: 150%;
    }
}


@media only screen and (max-width: 976px) {
    .seminar-page{
        &__title{
            font-size: 18px;
            margin-bottom: 16px;
        }
        &__paragraph{
            font-size: 12px;
        }
        &__list{
            
        }
        &__list-item{
            font-size: 12px;
        }
        &__article{
            margin-bottom: 20px;
        }
    }
}