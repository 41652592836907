.delivery-payment {
    & .container{
        margin-top: 32px;
    }


    &__section-wrap{
        background: #FFF;
        padding: 40px 60px;
        margin-bottom: 64px;
    }

    // .delivery-payment__section

    &__section {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
    }

    // .delivery-payment__section--column

    &__section--column{
        flex-direction: column;
    }

    // .delivery-payment__section-block

    &__section-block {
        padding: 40px;
        max-width: 550px;
        border: 1px solid #DCDCDC;

    }

    // .delivery-payment__content-title

    &__content-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
        margin-bottom: 14px;
        text-align: center;
    }

    // .delivery-payment__content-subtitle

    &__content-subtitle {
        font-size: 14px;
        line-height: 150%;
        min-height: 63px;
    }

    // .delivery-payment__post-items

    &__post-items {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 209px;
    }

    // .delivery-payment__post-img

    &__post-img {
        & img{
            width: 100%;
            height: auto;
        }
    }

    // .delivery-payment__img-delivery

    &__img-delivery {
    }

    // .delivery-payment__tab

    &__tab {
        max-width: 260px;
        width: 260px;
        display: flex;
        flex-direction: column;
        align-items: center;
        & img{
            width: 80px;
            height: 80px;
            object-fit: cover;
            margin-bottom: 16px;
        }
    }

    // .delivery-payment__tab-title

    &__tab-title {
        font-weight: 800;
        font-size: 14px;
        line-height: 150%;
        margin-bottom: 8px;
        text-align: center;
    }

    // .delivery-payment__tab-text

    &__tab-text {
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
    }

    // .delivery-payment__list

    &__list {
        width: 100%;
        margin-bottom: 32px;
    }

    // .delivery-payment__list-item

    &__list-item {
        font-size: 14px;
        line-height: 150%;
        display: flex;
        &:not(:last-child){
            margin-bottom: 24px;
        }
    }

    // .delivery-payment__li-dot

    &__li-dot{
        margin-right: 16px;
        min-width: 16px;
        min-height: 16px;
    }

    // .delivery-payment__partners

    &__partners {
    }

    // .delivery-payment__partners-title

    &__partners-title {
        margin-bottom: 16px;
    }

    // .delivery-payment__partners-logos

    &__partners-logos {
        display: flex;
        align-items: center;
    }

    // .delivery-payment__partners-logo

    &__partners-logo {
        &:not(:last-child){
            margin-right: 64px;
        }
    }
}


@media only screen and (max-width: 1310px) {
    .delivery-payment{
        &__section-wrap{
            padding-left: 0;
            padding-right: 0;
        }
    }
}

@media only screen and (max-width: 1205px) {
    .delivery-payment{
        &__section-wrap{
            padding: 20px;
        }
        &__section--delivery{
            flex-direction: column;
            align-items: center;
        }
        &__section-block{
            max-width: 90%;
            width: 90%;
            &:not(:last-child){
                margin-bottom: 20px;
            }
        }
        &__img-delivery{
            text-align: center;
        }
        &__content-subtitle{
            text-align: center;
        }
    }
}

@media only screen and (max-width: 976px) {
    .delivery-payment{
        &__tab{
            & img{
                width: 60px;
                height: 60px;
                margin-bottom: 12px;
            }
        }
        &__section-wrap{
            padding: 15px;
        }
        &__section-block{
            padding: 20px 18px;
            margin-bottom: 40px;
        }
        &__content-title{
            font-size: 12px;
            margin-bottom: 12px;
        }
        &__content-subtitle{
            min-height: auto;
            font-size: 12px;
            margin-bottom: 18px;
        }
        &__post-items{
            height: fit-content;
        }
        &__post-img{
            width: 147px;
        }
        &__post-img--sat{
            width: 112px;
        }
        &__tab-title{
            font-weight: 600;
            font-size: 12px;
            margin-bottom: 6px;
        }
        &__tab-text{
            font-weight: normal;
            font-size: 12px;
        }
        &__li-dot{
            display: flex;
            align-items: flex-start;
            padding-top: 2px;
        }
        &__list-item{
            font-size: 12px;
            margin-bottom: 18px;
        }
        &__list{
            margin-bottom: 24px;
        }
        &__partners-title{
            font-size: 14px;
            margin-bottom: 8px;
        }
        
        &__tab{
            padding: 10px;
        }
    }
}

@media only screen and (max-width: 640px) {
    .delivery-payment{
        &__section-block{
            width: 100%;
            max-width: 100%;
        }
        &__section--tabs{
            flex-wrap: wrap;
            justify-content: space-around;
        }
        &__tab{
            margin-bottom: 12px;
        }
    }
}

@media only screen and (max-width: 440px) {
    .delivery-payment{
        &__post-items{
            flex-direction: column;
        }
        &__post-img{
            &:not(:last-child){
                margin-bottom: 10px;
            }
        }
    }
}
