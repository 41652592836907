@import '../../scss/reset/reser-style';

// .content-filter

.content-filter {
    position: relative;
    height: fit-content;
    & .arrow-down{
        transition: .2s;
    }
    &.active{
        & .arrow-down{
            transform: rotate(180deg);
        }
        & .content-filter__dropdown{
            transform: scaleY(1);
            z-index: 1;
        }

    }
    // .content-filter__head

    &__head {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $second-grey;
    }

    // .content-filter__title

    &__title {
        @include subtitle-main-text;
        cursor: pointer;
    }

    // .content-filter__picked

    &__picked {
        display: flex;
        justify-content: space-between;
        width: 189px;
        margin-left: 3px;
        cursor: pointer;
        & .content-filter__item{
            @include main-text;
        }
    }

    // .content-filter__item

    &__item {
        @include secondary-text;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    // .content-filter__dropdown

    &__dropdown {
        box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
        position: absolute;
        width: 100%;
        top: 26px;
        transform: scaleY(0);
        transform-origin: top;
        transition: .3s;
        & .content-filter__item{
            height: 40px;
            display: flex;
            align-items: center;
            padding: 1px 8px;
            background: $white;
            transition: .2s;
            cursor: pointer;
            &:hover{
                background: $label;
                color: $second;
            }
        }
    }
}
@media only screen and (max-width: 976px) {
    .content-filter{
        &__title{
            font-size: 12px;
        }
        &__item{
            font-size: 12px;
        }
        &__picked{
            & .content-filter__item{
                font-size: 12px;
            }
        }
        &__dropdown{
            &__item{
                height: 38px;
                font-size: 10px;
            }
        }
    }
}





.cases {

    // .cases__header

    &__header {
        display: flex;
        justify-content: space-between;
        margin-top: 32px;
        align-items: center;
        margin-bottom: 32px;
        & .page-title{
            height: fit-content;
            margin-bottom: 0;
        }
    }

    // .cases__body

    &__body {
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-between;
        display: grid;
        grid-template-columns: 32% 32% 32%;
        column-gap: 2%;
    }

    
    & .pagination-wrap{
        margin: 20px auto 100px auto;
    }
}

.case-item-wrap {
    margin-bottom: 8%;
    &:hover{
        & .case-item{
            box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
        }
    }
}
.case-item {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    transition: box-shadow .2s;
    height: 100%;
    background: #FFFFFF;
    // .case-item__head

    &__head {
        width: 100%;
        height: 226px;
        position: relative;
        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    // .case-item__label

    &__label {
        position: absolute;
        left: 0;
        top: 0;
        width: fit-content;
        color: $white;
        padding: 5px 18px;
        @include subtitle-small;
    }

    // .case-item__label--type1

    &__label--type1 {
        background: $second;
    }

    // .case-item__description

    &__description {
        padding: 0 16px 14px 16px;
    }

    // .case-item__title

    &__title {
        @include subtitle;
        color: $second;
        min-height: 48px;
        padding-top: 24px;
        margin-bottom: 4px;
    }

    // .case-item__subtitle

    &__subtitle {
        @include main-text;
    }

    // .case-item__label--type2

    &__label--type2 {
        background: $orange;
    }
}


@media only screen and (max-width: 976px) {
    .cases__body {
        display: grid;
        grid-template-columns: 49% 49%;
        column-gap: 2%;
    }
    .case-item{
        &__title{
            font-size: 14px;
            min-height: 42px;
            padding-top: 8px;
        }
        &__head{
            height: 175px;
        }
        &__subtitle{
            font-size: 12px;
        }
        &__label{
            font-size: 12px;
            padding: 4px 8px;
        }
    }
    .cases{
        .container {
            padding-left: 20px;
            padding-right: 20px;
        }
        & .pagination-wrap{
            margin: 10px auto 70px auto;
        }
    }
}

@media only screen and (max-width: 576px) {
    .cases__body {
        display: grid;
        grid-template-columns: 100%;
        column-gap: 0;
    }
}

@media only screen and (max-width: 430px) {
    .cases{
        &__header{
            flex-direction: column;
            align-items: flex-start;
        }
    }
    .case-item{
        &__head{
            height: 150px;
        }
    }
    .content-filter{
        margin-top: 10px;
    }
}