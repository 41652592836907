@import "src/scss/style";

.footer__logo-wrap {
  height: 100px;
}
.footer {
  background: $black;
  position: relative;
  & .container {
    position: relative;
  }

  // .footer__body

  &__body {
    display: flex;
    justify-content: space-between;
    padding: 55px 0 35px;
    width: 90%;
  }

  // .footer__logo-wrap

  // .footer__link-section

  &__link-section {
    display: flex;
    flex-direction: column;
  }

  // .footer__link

  &__link {
    @include regular;
    color: $white;
    position: relative;
    width: fit-content;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      border-bottom: 1px solid $white;
      transform: scaleX(0);
      transition: 0.2s;
    }
    &:hover {
      &::before {
        transform: scaleX(1);
      }
    }
  }

  // .footer__schedule

  &__schedule {
    display: flex;
    flex-direction: column;
  }

  // .footer__section-title

  &__section-title {
    @include subtitle-small;
    color: $white;
    margin-bottom: 16px;
  }

  // .footer__schedule-item

  &__schedule-item {
    @include main-text;
    color: $white;
  }

  // .footer__address-item

  &__address-item {
    @include main-text;
    color: $white;
    margin-top: 10px;
  }

  // .footer__city-item

  &__city-item {
    @include main-text;
    color: $white;
    text-align: center;
  }
  @media only screen and (max-width: 1120px) {
    &__city-item {
      text-align: left;
      margin-bottom: 16px;
    }
  }

  // .footer__contacts

  &__contacts {
    display: flex;
    flex-direction: column;
  }

  // .footer__phones

  &__phones {
    display: flex;
    flex-direction: column;
    margin-bottom: 14px;
  }

  //.footer__contacts-phone

  &__contacts-phone {
    @include main-text;
    color: $white;
    transition: 0.1s;
    &:hover {
      color: $main;
    }
  }

  // .footer__social

  &__social {
  }

  // .footer__social-item

  &__social-item {
    & svg path {
      transition: 0.1s;
    }
    &:not(:last-child) {
      margin-right: 17px;
    }
    &:hover {
      & svg path {
        fill: $white;
      }
    }
  }

  // .footer__bottom-section

  &__bottom-section {
    height: 40px;
    border-top: 1px solid #3d3c3c;
    & .container {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  // .footer__copyright

  &__copyright {
    @include secondary-text;
    color: #c6c6c6;
    a {
      font-weight: 900;
      @include secondary-text;
      color: #ffffff;
      margin-left: 5px;
    }
  }

  // .footer__sitemap

  &__sitemap {
    @include secondary-text;
    color: #c6c6c6;
    a {
      font-weight: 900;
      @include secondary-text;
      color: #ffffff;
      margin-left: 5px;
    }
  }

  // .footer__ico-box

  &__ico-box {
    & img:not(:last-child) {
      margin-right: 18px;
    }
  }
}
.container {
}
.scroll-up-btn {
  position: absolute;
  top: 25%;
  right: 10px;
  background: none;
  cursor: pointer;
  & svg rect,
  & svg path {
    transition: 0.2s;
  }
  &:hover {
    & svg {
      & rect {
        fill: $main;
      }
      & path {
        fill: $white;
      }
    }
  }
}
.footer__content-wrap {
  display: flex;
  width: 80%;
  justify-content: space-between;
}
@media only screen and (max-width: 1580px) {
  .scroll-up-btn {
    right: 50px;
  }
}

@media only screen and (max-width: 1310px) {
  .footer {
    &__body {
      width: 95%;
    }
  }
}

@media only screen and (max-width: 1120px) {
  .footer {
    &__content-wrap {
      flex-wrap: wrap;
    }
    &__link-section--first {
      order: 0;
      margin-bottom: 10px;
    }
    &__link-section--second {
      order: 2;
      margin-bottom: 10px;
    }
    &__schedule {
      order: 1;
      width: 50%;
    }
    &__contacts {
      order: 3;
      width: 50%;
    }
    &__link-section {
      width: 50%;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .scroll-up-btn {
    right: 20px;
    top: 32px;
  }
}

@media only screen and (max-width: 976px) {
  .footer {
    &__logo-wrap {
      min-width: 143px;
      margin-right: 20px;
    }
    &__link,
    &__schedule-item,
    &__contacts-phone {
      @include secondary-text;
    }
    &__section-title {
      font-size: 12px;
      margin-bottom: 8px;
    }
    .footer__sitemap{
      margin-bottom: 10px;
    }
    &__sitemap {
      a {
        margin-left: 0px;
      }
    }
  }
}
@media only screen and (max-width: 598px) {
  .footer {
    &__logo-wrap {
      display: none;
    }
  }
}

@media only screen and (max-width: 502px) {
  .footer {
    &__link-section--first {
      margin-bottom: 0;
    }
    &__link-section--second {
      order: 1;
      margin-bottom: 14px;
    }
    &__schedule {
      width: 100%;
      margin-bottom: 14px;
    }
    &__contacts {
      width: 100%;
    }
    &__link-section {
      width: 100%;
    }
    &__link {
      margin-bottom: 4px !important;
    }
    &__body {
      padding: 32px 0 20px;
    }
  }
}

@media only screen and (max-width: 492px) {
  .footer {
    &__bottom-section {
      height: fit-content;
      padding-bottom: 32px;
      & .container {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &__copyright {
      margin: 8px 0;
    }
  }
}
