@import '../../../scss/reset/vars';
@import '../../../scss/reset/reser-style';

.services-wrap {
    margin-bottom: 64px;
}
.main-page {

    // .main-page__title

    &__title {
        @include title;
        color: $black;
        margin-bottom: 24px;
    }
}
.services {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    // .services__item

    &__item {
        position: relative;
        overflow: hidden;
        transition: 0.4s;
        margin: 0;
        &::after{
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: linear-gradient(96.23deg, rgba(24, 35, 22, 0.25) 34.2%, rgba(24, 35, 22, 0) 99.5%);
            opacity: 1;
            transition: .3s;
        }
        &:hover{
            &::after{
                opacity: 0;
            }
            & .services__title-box-bg{
                // transform: scale(1.2);
                & svg path{
                    fill-opacity: 1;
                }
            }
        }
    }

    // .services__item--big

    &__item--big {
        width: 49%;
        height: 210px;
        position: relative;
        margin-bottom: 2%;
        & .services__img {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            background-position-y: center;
        }
    }
    &__item--center-bg{
        & .services__img {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            margin: auto;
            background-position-y: center;
        }
    }
    // .services__img

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        object-fit: cover;
        background-position-y: center;
    }

    // .services__title-box

    &__title-box {
        position: relative;
        height: 100%;
        max-width: 186px;
        display: flex;
        align-items: flex-end;
        z-index: 1;
    }

    // .services__title-box-bg

    &__title-box-bg {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        transform: scale(1);
        transform-origin: center;
        opacity: 1;
        transition: .4s;
        & svg{
            object-fit: contain;
            & path{
                fill-opacity: 0.9;
                transition: fill-opacity .2s;
            }
        }
    }

    // .services__title

    &__title {
        margin-left: 24px;
        margin-bottom: 24px;
        @include subtitle;
        color: $white;
        z-index: 1;
    }

    // .services__title-box-bg--white

    &__title-box--white{
        & .services__title{
            color: $main;
        }
    }

    // .services__item--middle

    &__item--middle {
        width: 32%;
        height: 250px;
        & .services__img {
            height: 100%;
        }
    }

    // .services__item-wrap

    &__item-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    // .services__item--small

    &__item--small {
        width: 100%;
        height: 110px;
        & .services__title-box-bg {
            position: absolute;
            left: 0;
            top: -2px;
            & svg{
                object-fit: contain;
                transform: translateY(-30%);
            }
        }
    }

    // .services__item-wrap

    .services__item-wrap{
        width: 32%;
    }
}

.services__title-box-bg{
    & svg{
        & .cube{
            transform: translateY(-50%);
            opacity: 0.9;
            // animation-fill-mode: backwards;
        }
        & .cube1{
            transform: translateY(-50%);
            opacity: 0;
        }

        & .row1.col1{
            transition: 0.35s;
            animation-duration: 0.35s;
        }
        & .row1.col2{
            transition: 0.3s;
            transition-delay: 0.2s;
            animation-duration: 0.3s;
        }
        & .row1.col3{
            transition-delay: 0.1s;
            transition: 0.35s;
            animation-duration: 0.35s;
        }
        & .row1.col4{
            transition: 0.25s;
            // transition-delay: 0.05s;
            animation-duration: 0.25s;
        }
        & .row2.col1{
            transition: 0.3s;
            transition-delay: 0.2s;
            animation-duration: 0.3s;
        }
        & .row2.col2{
            transition: 0.35s;
            transition-delay: 0.1s;
            animation-duration: 0.35s;
        }
        & .row2.col4{
            transition: 0.25s;
            transition-delay: 0.15s;
            animation-duration: 0.25s;
        }
        & .row3.col1{
            transition: 0.3s;
            transition-delay: 0.3s;
            animation-duration: 0.3s;
        }
        & .row3.col3{
            transition: 0.35s;
            transition-delay: 0.2s;
            animation-duration: 0.35s;
        }
        & .row3.col5{
            transition: 0.25s;
            transition-delay: 0.35s;
            animation-duration: 0.35s;
        }





        & .r3.c5{
            transition: transform 0.35s;
            animation-duration: 0.35s;
        }
        & .r1.c1{
            transition: transform 0.3s;
            transition-delay: 0.2s;
            animation-duration: 0.3s;
        }
        & .r2.c2{
            transition-delay: 0.1s;
            transition: transform 0.35s;
            animation-duration: 0.35s;
        }
        & .r1.c2{
            transition: transform 0.25s;
            // transition-delay: 0.05s;
            animation-duration: 0.25s;
        }
        & .r3.c3{
            transition: transform 0.3s;
            transition-delay: 0.2s;
            animation-duration: 0.3s;
        }
        & .r1.c3{
            transition: transform 0.35s;
            transition-delay: 0.1s;
            animation-duration: 0.35s;
        }
        & .r2.c4{
            transition: transform 0.25s;
            transition-delay: 0.15s;
            animation-duration: 0.25s;
        }
        & .r1.c5{
            transition: transform 0.3s;
            transition-delay: 0.3s;
            animation-duration: 0.3s;
        }
    }
}

.services__item.active{
    & .services__title-box-bg svg{
        & .cube {

            transform: translateY(0);
        }
    }
}
.services__item:hover{
    & .services__title-box-bg svg{
        & .cube {
            transform: translateY(-50%);
            opacity: 0;
            animation: cubesUp 0.4s;

            // transition: 0.3s;
        }
        & .cube1{
            transform: translateY(0%);
            opacity: 0.9;
            // animation: cubesDown 1s both;
        }
    }
}
@media only screen and (max-width: 976px) {
    .services{
        &__title{
            @include subtitle-small;
            padding-right: 10px;
        }
    }
    .services-wrap{
        margin-bottom: 40px;
    }
}



@media only screen and (min-width: 976px) {
    .services{
        &__item--small{
            & .services__title{
                margin-left: 14px;
                margin-bottom: 10px;
            }
        }
    }
}

@media only screen and (max-width: 896px) {
    .services{
        &__item{
            width: 49%;
            height: 232px;
            margin-bottom: 2%;
        }
        &__img{
            width: 100% !important;
            height: 100% !important;
        }
        & &__item-wrap{
            width: 100%;
            flex-direction: row;
        }
        &__item--center-bg{
            .services__img{
                object-fit: cover;
                width: auto;
                height: 100%;
            }
        }
        &__item--small{
            & .services__title-box-bg{
                & svg{
                    transform: translateY(0%);
                }
            }
        }
    }
}

@media only screen and (max-width: 630px) {
    .services{
        &__item{
            width: 100%;
            height: 180px;
            margin-bottom: 24px;
        }
        & &__item-wrap{
            width: 100%;
            flex-direction: column;
        }
        &__title{
            height: 65px;
            display: flex;
            align-items: center;
            margin-bottom: 4px;
            margin-left: 4px;
        }
        &__item:nth-child(even){
            & .services__title-box-bg{
                & svg rect{
                    fill: $white !important;
                }
            }
            & .services__title{
                color: $main !important;
            }
        }
        &__item:nth-child(odd){
            & .services__title-box-bg{
                & svg rect{
                    fill: $main !important;
                }
            }
            & .services__title{
                color: $white !important;
            }
        }
    }
}

@media only screen and (max-width: 380px) {
    .services{
        &__item{
            height: 140px;
        }
    }
}