@import '../../../scss/reset/vars';
@import '../../../scss/reset/reser-style';

.main-page {

    .main-sl-item__bg-left.mobile {
        display: none;
    }

    // .main-page__main-slider-wrap

    &__main-slider-wrap {
        width: 100%;
        position: relative;
        height: fit-content;
        & .main-slider-arrows__arrow-left, & .main-slider-arrows__arrow-right {
            top: 0;
            bottom: 0;
            //left: 0;
            //right: 0;
            margin: auto;
            position: absolute;
            z-index: 3;
            background: none;
            padding: 0;
        }
        & .main-slider-arrows__arrow-left{
            left: 0;
        }
        & .main-slider-arrows__arrow-right {
            right: 0;
        }
    }
}
.main-slider {
    width: 100%;
    position: relative;

    // .main-slider__arrow-left

    &__arrow-left {
    }

    // .main-slider__arrow-right

    &__arrow-right {
    }
}
.main-sl-item {
    position: relative;
    // .main-sl-item__bg

    &__bg {
        display: flex;
        height: 100%;
        position: relative;
    }

    // .main-sl-item__bg-left

    &__bg-left {
        left: 0;
        z-index: 2;
        width :100%;
        max-width: 100%;
        height: 100%;
        // max-width: fit-content;
        & > img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: right;
        }
    }

    // .main-sl-item__flag

    &__flag {
        max-width: fit-content;
        position: absolute;
        right: 17px;
        top: 8%;
    }

    // .main-sl-item__bg-right

    &__bg-right {
        position: absolute;
        right: 0;
        z-index: 1;
        min-width: 50%;
        height: 100%;
        & img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: left;
        }
    }

    // .main-sl-item__body

    &__body {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        width: 100%;
        display: flex;
        align-items: center;
        z-index: 2;
        & .container{
            display: flex;
            flex-direction: column;
        }
    }

    // .main-sl-item__title-box

    &__title-box {
        width: fit-content;
        border-left: 1px solid $white;
        position: relative;
        padding-left: 32px;
        max-width: 550px;
        transform: translateY(-10px);
        // min-height: 214px;
        padding: 50px 0 50px 32px;
        display: flex;
        align-items: center;
        &::before{
            content: '';
            position: absolute;
            width: 45%;
            height: 1px;
            background: white;
            left: -1px;
            top: -1px;
        }
        &::after{
            content: '';
            position: absolute;
            width: 90%;
            height: 1px;
            background: white;
            left: -1px;
            bottom: -1px;
        }
    }

    // .main-sl-item__title

    &__title {
        @include h1;
        width: 100%;
        color: $white;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
        word-wrap: break-word;
    }

    // .main-sl-item__catalog-btn

    &__catalog-btn {
        position: absolute;
        left: 0;
        bottom: 40px;
        right: 0;
        display: block;
        margin: 0 auto;
        width: fit-content;
        transition: .3s;
        &:hover {
            background: $main;
            color: $white
        }
    }
}

.main-sl-dots{
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 20px;
    display: flex !important;
    justify-content: center;
    & li{
        &:not(:last-child){
            margin-right: 16px;
        }
    }
    & .slick-active button{
        background: transparent;
        &::before{
            background: $main;
        }
    }
    & button{
        font-size: 0;
        padding: 0;
        height: 20px;
        width: 32px;
        cursor: pointer;
        position: relative;
        background: transparent;
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            margin: auto;
            height: 2px;
            background: $second-grey;
            transition: .2s;
        }
        &:hover{
            &::before{
                background: $label;
            }
        }
    }
}
.main-slider-arrows__arrow-left, .main-slider-arrows__arrow-right{
    cursor: pointer;
    & svg rect{
        transition: .2s;
    }
    &:hover svg{
        & rect{
            fill: $main;
            fill-opacity: 1;
        }
    }
}
@media only screen and (max-width: 1460px) {
    .main-page__main-slider-wrap {
        width: 100%;
        position: relative;
        height: fit-content;
        // & .main-slider-arrows__arrow-left, & .main-slider-arrows__arrow-right {
        // }
        & .main-slider-arrows__arrow-left{
            left: 5px;
            margin-left: 5px;
        }
        & .main-slider-arrows__arrow-right {
            right: 5px;
            margin-right: 5px;
        }
    }
}

@media only screen and (max-width: 1170px) {
    .main-sl-item{
        &__title-box{
            align-self: flex-end;
            border-color: $dark;
            margin-right: 3%;
            &::before, &::after{
                background: $dark;
            }
        }
        &__title{
            color: $dark;
        }
    }
}

@media only screen and (max-width: 1035px) {
    .main-sl-item{
        &__title-box{
            margin-right: 3%;
        }
    }
}
@media only screen and (max-width: 976px) {
    .main-sl-item{
        &__title-box{
            padding: 16px 0 16px 12px;
            margin: 0 auto !important;
            // max-width: 75%;
        }
        &__title{
            font-size: 32px;
            line-height: 150%;
            // max-width: 250px;
        }
        &__body .container{
            height: 100%;
            justify-content: center;
            position: relative;
        }
        &__catalog-btn{
            position: absolute;
            left: 0;
            right: 0;
            bottom: 25px;
            @include subtitle-small;
            padding: 8px 24px;
            border: none;
            background: $main;
            color: $white;
        }
        &__flag{
            top: 0 !important;
            right: 1px !important;
        }
    }
    .main-slider{
        & .main-sl-dots{
            bottom: 0;
        }
    }
    .main-slider-arrows__arrow-left, .main-slider-arrows__arrow-right{
        display: none !important;
    }
}

@media only screen and (max-width: 788px) {
    .main-sl-item{
        &__bg-left{
            max-width: 100%;
        }
        &__title-box{
            margin-left: auto !important;
            margin-right: 0 !important;
            max-width: 90%;
        }
        &__title{
            font-size: 22px;
            // max-width: 250px;
        }
    }
}
@media only screen and (max-width: 600px) {
    .main-sl-item__bg-left {
        display: none;
    }
    .main-sl-item__bg-left.mobile {
        display: block !important;
    }
}