@import '../../scss/reset/reser-style';

// .product-card__label--discount

.mobile-one-click {
    display: none;
}

.slick-list {
    height: 100% !important;
    width: 100% !important;
}

.slick-track {
    display: flex !important;
    align-items: center;
}

.advantages-icons {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.product-page {
    .main-page__title {
        font-size: 24px;
    }
}
.not-buy-product {
    font-size: 16px;
    line-height: 150%;
    color: #DB4915;
    margin-top: 42px;
}

.empty-feedback {
    border: 1px solid #DB4915 !important;
}
.empty-feedback-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #DB4915;
    svg {
        margin-right: 5px;
        margin-top: -2px;
    }
}
.not-white {
    background: none !important;
}

.product-card__label--discount {
    background: #FB8200;
}

.product-info__delivery-section {
    width: 100%;
}

.product-info__additional-services {
    width: 100%;
    margin-top: 8px;
}

.hidden {
    display: none !important;
}

.product-page__see-all-btn {
    padding-left: 24px;
}

    //.slider-container {
//    margin: 0;
//}
//
//.slider-container--related {
//    background: #FFFFFF;
//}

.product-page .slider-container--related {
    margin-left: 0 !important;
    margin-top: 32px;
    margin-bottom: 50px;
}

// .product-card__discount

.product-card__discount {
    display: flex;
    margin-bottom: 2px;
}
.product-card__payment {
    margin-bottom: 0;
    margin-top: 3px;
    img {
        width: 34px;
        height: 24px;
        margin-left: 5px;
    }
}
.product-card__price-top {
    display: flex;
    justify-content: space-between;
}

// .product-card__discount-val

.product-card__discount-val {
    font-size: 14px;
    line-height: 150%;
    text-decoration-line: line-through;
    color: $grey;
    margin-right: 3px;
}

// .product-card__discount-percentage

.product-card__discount-percentage {
    padding: 2px 16px;
    margin-left: 10px;
    background: #FB8200;
    @include main-text;
    color: $white;
}

.product-page {

    // .product-page__anchors-wrap

    &__anchors-wrap {
        background: $white;
        width: 100%;
        display: flex;
        justify-content: space-around;
        height: 50px;
        margin-bottom: 16px;
        cursor: pointer;
    }

    // .product-page__anchor

    &__anchor {
        position: relative;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        display: flex;
        align-items: center;
        transition: .2s;
        &::after{
            content: '';
            position: absolute;
            height: 100%;
            width: 1px;
            background: $second-grey;
            top: 0;
            right: -3.7vw;
        }
        &>span{
            transition: .2s;
        }
        &:hover, &.active{
            color: $main;
            &>span{
                color: $main;
            }
        }
    }

    // .product-page__anchor-reviews-counter

    &__anchor-reviews-counter {
        margin-left: 6px;
    }

    // .product-page__title-mobile

    &__title-mobile{
        display: none;
    }

    // .product-page__content-wrap

    &__content-wrap {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 8px;
        padding-bottom: 8px;
    }

    // .product-page__characteristics

    &__characteristics {
        width: 49.5%;
        background: #FFFFFF;
        padding-bottom: 24px;
        & .product-page__container-title{
            padding-left: 24px;
            margin-top: 24px;
        }
        .green {
            .product-page__characteristics-name, .product-page__characteristics-val {
                color: #257226 !important;
                font-weight: 700;
            }
        }

    }

    // .product-page__container-title

    &__container-title {
        @include description-title;
        margin-bottom: 32px;
    }

    // .product-page__characteristics-table

    &__characteristics-table {
        height: 0;
        transform: scale(0);
        overflow: hidden;
        transform-origin: top;
        transition: .4s;
        opacity: 0;
        &.active{
            height: auto;
            transform: scaleY(1);
            opacity: 1;
        }
    }

    // .product-page__characteristics-item

    &__characteristics-item {
        @include main-text;
        display: flex;
        justify-content: space-between;
        padding: 10px 24px;
        border-bottom: 1px solid $second-grey;
        width: 100%;
    }

    // .product-page__characteristics-name

    &__characteristics-name {
        width: 276px;
        padding-right: 8px;
    }

    // .product-page__characteristics-val

    &__characteristics-val {
        width: 276px;
    }

    // .product-page__see-all-btn
    &__see-all-btn{
        margin-top: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover{
            color: $second;
            & span{
                color: $second;
            }
        }
    }

    &__see-all-btn, &__see-all-btn span {
        @include main-text;
        background: none;
        transition: .2s;
        font-weight: 700;
        color: #257226;
        &.active {
            span {
                transform: rotate(180deg);
            }
        }
    }

    &__see-all-btn span{
        font-size: 10px;
        margin-left: 4px;
        display: block;
    }

    // .product-page__description

    &__description {
        width: 49.5%;
        background: #FFFFFF;
        padding: 24px;
    }

    // .product-page__description-text

    &__description-text {
    }

    // .product-page__description-list

    &__description-list {
        margin-bottom: 24px;
        ul {
            padding-left: 20px;
            li {
                list-style-type: disc;
            }
        }
    }

    // .product-page__description-item

    &__description-item {
        @include main-text;
    }

    // .product-page__description-video

    &__description-video {
        width: 100%;
    }
    &__description-text {
        margin-top: 20px;
    }

    // .product-page__description-video-wrap

    &__description-video-wrap {
        position: relative;
        height: 315px;
    }

    // .product-page__play-video-btn

    &__play-video-btn {
        position: absolute;
        width: 76% !important;
        height: 76% !important;
        top: 12%;
        right: 0;
        bottom: 0;
        left: 12%;
        cursor: pointer;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: scale(1.3);
        & img{
            transition: .2s;
            width: 60px;
            height: 60px;
        }
        &:hover{
            & img{
                transform: scale(1.5);
            }
        }
    }

    // .product-page__reviews-wrap

    &__reviews-wrap{
        width: 49.5%;
        background: #FFFFFF;
        padding-top: 24px;
        height: 80px;
        transition: .3s;
        transform-origin: top;
        position: relative;
        .product-page__reviews-empty {
            display: none;
            transition: .3s;
        }
        .toggle-feedback-block {
            display: none;
            transition: .3s;
        }
        .set-toggle-feedback {
            position: absolute;
            top: 28px;
            right: 34px;
            transform: rotate(90deg);
            cursor: pointer;
            transition: .3s;
        }
        &.active {
            height: auto;
            .product-page__reviews-empty {
                display: flex;
            }
            .toggle-feedback-block {
                display: block;
            }
            .set-toggle-feedback {
                transform: rotate(-90deg);
            }
        }
    }

    // .product-page__reviews

    &__reviews {
        width: 100%;
        padding: 0 24px;
        max-height: 0;
        overflow: hidden;
        transform: scaleY(0);
        transform-origin: top;
        transition: transform .4s;
        &.active{
            max-height: fit-content;
            transform: scaleY(1);
        }
    }

    .product-page__reviews-wrap .product-page__review.review,
    .product-page__reviews-wrap .product-page__see-all-section {
        display: none;
    }
    .product-page__reviews-wrap.active .product-page__review.review,
    .product-page__reviews-wrap.active .product-page__see-all-section {
        display: block;
    }

    // .product-page__review

    &__review {
    }

    // .product-page__add-review-btn

    &__add-review-btn{
        display: none;
    }

    // .product-page__reviews--empty

    &__reviews--empty {
        height: 100%;
        position: relative;
        & .product-page__container-title{
            margin-bottom: 0;
        }
    }

    // .product-page__reviews-empty

    &__reviews-empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0;
    }

    // .product-page__reviews-empty-text

    &__reviews-empty-text {
        font-size: 18px;
        line-height: 150%;
        color: #000;
        width: 334px;
        text-align: center;
        margin-bottom: 30px;
    }

    // .product-page__reviews-empty-arrow

    &__reviews-empty-arrow {
        background: none;
        cursor: pointer;
    }

    // .product-page__review-add

    &__review-add {
        width: 49.5%;
        background: #FFFFFF;
        padding: 24px 24px 32px 24px;
        height: 80px;
        transition: .3s;
        transform-origin: top;
        .review-add {
            display: none;
            transition: .3s;
        }
        &.active {
            height: auto;
            .review-add {
                display: block;
            }
        }
    }
}
.container {
}
.bread-crumbs {

    // .bread-crumbs__item

    &__item {
        font-size: 15px;
    }
}
.product-demo {
    width: 49.5%;
    background: $white;
    padding: 24px;
    // .product-demo__slider-wrap

    &__slider-wrap {
        margin-bottom: 30px;
    }

    // .product-demo__main-slider-wrap
    &__main-slider-wrap{
        width: 100%;
        height: 470px;
        display: flex;
        align-items: center;
        .slider-arrow-left, .slider-arrow-right {
            position: absolute;
            top: 50%;
        }
        .slider-arrow-left {
            left: -20px;
        }
        .slider-arrow-right {
            right: -20px;
        }
    }

    // .product-demo__main-slider

    &__main-slider {
        width: 100%;
        // margin-bottom: 24px;
        & .product-demo__slide-item{
            width: 90%;
            margin-left: 5%;
            height: 470px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    // .product-demo__slide-item

    &__slide-item {

    }

    // .product-demo__thumbnail-slider

    &__thumbnail-slider {
        width: 90%;
        margin-left: 5%;
        margin-top: 24px;
        .slider-arrow-left, .slider-arrow-right {
            position: absolute;
            top: 30%;
        }
        .slider-arrow-left {
            left: -20px;
        }
        .slider-arrow-right {
            right: -20px;
        }
        & .slick-current.slick-active{
            & .product-demo__slide-item{
                border: 1px solid #DCDCDC;
                transition: .2s;
            }
        }
        & .product-demo__slide-item{
            width: 100px;
            max-width: 100px;
            height: 100px;
            position: relative;
            & img{
                display: block;
                width: 100%;
                height: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    // .product-demo__advantages

    &__advantages {
        margin-top: 50px;
        display: flex;
        flex-wrap: wrap;
    }

    // .product-demo__advantages-item

    &__advantages-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        margin-left: 10px;
        text-align: center;
    }

    // .product-demo__advantages-ico

    &__advantages-ico {
        margin-right: 4px;
    }

    // .product-demo__advantages-text

    &__advantages-text {
        @include main-text;
    }
}

.product-info {
    width: 49.5%;
    padding: 24px 32px;
    background: $white;

    // .product-info__title

    &__title {
        font-weight: 600;
        font-size: 32px;
        line-height: 150%;
    }

    // .product-info__top-section

    &__top-section {
        display: flex;
        height: 21px;
        margin-top: 8px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 32px;
    }

    // .product-info__rating-wrap

    &__rating-wrap {
        display: flex;
    }

    // .product-info__stars

    &__stars {
        margin-right: 8px;
        img {
            margin-right: 6px;
        }
    }

    // .product-info__feedback-link

    &__feedback-link {
        font-size: 14px;
        line-height: 150%;
        color: $grey;
        align-items: center;
        position: relative;
        &::after{
            content: '';
            position: absolute;
            bottom: 2px;
            left: 0;
            right: 0;
            height: 1px;
            background: $grey;
            width: 100%;
        }
    }

    // .product-info__logo-wrap

    &__logo-wrap {
        width: 110px;
        height: 56px;
        & img{
            object-fit: cover;
        }
    }

    // .product-info__pricing

    &__pricing {
        display: flex;
        align-items: flex-end;
        margin-bottom: 32px;
    }

    // .product-info__price-wrap

    &__price-wrap {
        margin-right: 33px;
    }

    // .product-info__discounted-price

    &__discounted-price {
        font-size: 16px;
        line-height: 150%;
        text-decoration-line: line-through;
        color: $grey;
    }

    // .product-info__price

    &__price {
        font-weight: 600;
        font-size: 32px;
        line-height: 150%;
    }

    // .product-info__discount

    &__discount {
        margin-right: 32px;
        margin-bottom: 7px;
    }

    // .product-info__discount-text

    &__discount-text {
        @include main-text;
        color: #000;
    }

    // .product-info__discount-val

    &__discount-val {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #44DB15;
    }

    // .product-info__cashback-wrap

    &__cashback-wrap {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 4px;
        cursor: pointer;
        & img{
            margin-right: 2px;
        }
        .cashback-text {
            font-size: 14px;
            line-height: 150%;
            margin-right: 4px;
        }
        .cashback-val {
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            text-decoration-line: underline;
            color: $orange;
        }
    }

    // .product-info__cashback-description

    &__cashback-description {
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        width: 105%;
        padding: 10px;
        background: $white;
        font-size: 12px;
        line-height: 150%;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
        transform: scale(0);
        opacity: 0;
        transition: opacity .5s;
        & p:not(:last-child){
            margin-bottom: 8px;
        }
    }
    &__cashback-wrap:hover .product-info__cashback-description{
        transform: scale(1);
        opacity: 1;
        z-index: 1;
    }
    // .product-info__buy-section

    &__buy-section {
    }

    // .product-info__buy-section-top

    &__buy-section-top {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 24px;
        align-items: center;
        gap: 10px 15px;
    }

    @media only screen and (max-width: 1175px) {
        &__opt-btn {
            margin-left: 0px;
        }
    }

    // .product-info__buy-credit-btn

    &__buy-credit-btn {
        margin-right: 32px;
    }

    // .product-info__banking

    &__banking {
        display: flex;
    }

    // .product-info__banking-privat

    &__banking-privat {
        margin-right: 24px;
        position: relative;
    }

    // .product-info__banking-description

    &__banking-description {
        position: absolute;
        width: 300px;
        left: -12px;
        top: calc(100% + 10px);
        padding: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
        background: $white;
        font-size: 12px;
        line-height: 150%;
        opacity: 0;
        transform: scale(0);
        z-index: 21;
        transition: opacity .3s;
    }

    // .product-info__banking-monobank

    &__banking-monobank {
        position: relative;
    }
    &__banking-privat, &__banking-monobank{
        cursor: pointer;
        &:hover .product-info__banking-description{
            opacity: 1;
            transform: scale(1);
        }
    }

    // .product-info__buy-section-bottom

    &__buy-section-bottom {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin-bottom: 32px;
    }

    // .product-info__phone-input

    &__phone-input {
        width: 175px;
        height: 44px;
        padding-left: 10px;
        border: 1px solid $second-grey;
        letter-spacing: 1px;
        font-size: 14px;
        line-height: 150%;
        &::placeholder{
            letter-spacing: 1px;
            font-size: 14px;
            line-height: 150%;
        }
    }

    // .product-info__phone-input

    &__phone-submit {
        padding: 0 13px;
        background: $white;
        border: 2px solid $dark;
        margin-right: 32px;
        @include button;
        cursor: pointer;
        transition: .2s;
        &:hover{
            border-color: $second;
            color: $second;
        }
    }
    &__phone-submit{
        &:hover{
            & + .product-info__phone-popup{
                transform: scale(1);
                animation: fade 4s 1;
                text-align: center;
                // animation-iteration-count: 1;
            }
        }
    }
    // .product-info__phone-popup

    &__phone-popup{
        position: absolute;
        background: $white;
        padding: 12px 22px;
        top: 60px;
        font-size: 14px;
        line-height: 150%;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
        width: 300px;
        z-index: 1;
        transform: scale(0);
    }

    // .product-info__fav-compare

    &__fav-compare {
        display: flex;
    }

    // .product-info__fav-btn

    &__fav-btn {
        position: relative;
        background: none;
        cursor: pointer;
        margin-right: 32px;
        &::before{
            content: "";
            -webkit-clip-path: polygon(50% 0,0 100%,100% 100%);
            clip-path: polygon(50% 0,0 100%,100% 100%);
            transform: rotate(180deg);
            width: 14px;
            height: 14px;
            background: $white;
            position: absolute;
            left: 0;
            right: 0;
            top: -10px;
            margin: auto;
            z-index: 3;
            opacity: 0;
            transition: 0.2s;
        }
        &.active .product-info__popup--fav{
            opacity: 1;
            transform: scale(1) translateX(-80%);
            transition: opacity 0.7s;
        }
        &.active::before{
            opacity: 1;
        }

        & > svg .fill{
            fill: transparent;
        }
        & > svg .line{
            fill: $black;
        }
        &:hover{
            & > svg .line{
                fill: #DB4915;
            }
        }
        &:active, &.active{
            & > svg, & > svg path{
                fill: #DB4915;
            }
        }
    }

    // .product-info__banking-mobile

    &__banking-mobile{
        display: none;
    }

    // .product-info__compare-btn

    &__compare-btn {
        position: relative;
        background: none;
        cursor: pointer;
        &::before{
            content: "";
            -webkit-clip-path: polygon(50% 0,0 100%,100% 100%);
            clip-path: polygon(50% 0,0 100%,100% 100%);
            transform: rotate(180deg);
            width: 14px;
            height: 14px;
            background: $white;
            position: absolute;
            left: 0;
            right: 0;
            top: -10px;
            margin: auto;
            z-index: 3;
            opacity: 0;
            transition: 0.2s;
        }

        & > svg .fill{
            fill: transparent;
        }
        & > svg .circle, & > svg .arrow{
            fill: $black;
        }
        &:hover{
            & > svg .circle, & > svg .arrow{
                fill: $main;
            }
        }
        &:active, &.active{
            & > svg .fill, & > svg .circle{
                fill: $main;
            }
            & > svg .arrow{
                fill: $white;
            }
        }

        &.active .product-info__popup--compare{
            opacity: 1;
            transform: scale(1) translateX(-80%);
            transition: opacity 0.7s;
        }
        &.active::before{
            opacity: 1;
        }
    }

    // .product-info__popup

    &__popup {
        position: absolute;
        right: 0;
        left: 0;
        background: #FFFFFF;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
        height: 54px;
        width: 300px;
        display: flex;
        align-items: center;
        bottom: 55px;
        z-index: 2;
        padding-left: 10px;
        opacity: 0;
        transform: scale(0);
    }

    // .product-info__popup--fav

    &__popup--fav {
    }

    // .product-info__popup-text

    &__popup-text {
        font-size: 10px;
        line-height: 150%;
        color: $black;
    }

    // .product-info__popup-link

    &__popup-link {
        color: $grey;
        text-decoration-line: underline;
        margin-left: 5px;
    }

    // .product-info__popup-close-btn

    &__popup-close-btn {
        align-self: flex-start;
    }

    // .product-info__popup--compare

    &__popup--compare {
    }

    // .product-info__section-title

    &__section-title {
        @include subtitle;
        white-space: nowrap;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        & .arrow-down{
            display: none;
            margin-left: 16px;
        }
    }

    // .product-info__additional-services

    &__additional-services{
        max-width: 570px;
        .grey-line {
            height: 1px;
            background: #DCDCDC;
            width: 100%;
            margin-top: 16px;
        }
        .services-header {
            display: flex;
            margin-top: 16px;
            h6 {
                margin-right: 30px;
            }
            .services-switch {
                display: flex;

                &-item {
                    margin-top: 4px;
                    color: #868686;
                    font-weight: 600;
                    font-size: 14px;
                    // border-bottom: 1px solid #868686;
                    cursor: pointer;
                    height: 17px;
                    transition: .3s;
                    margin-right: 5px;
                    white-space: nowrap;
                    &.active {
                        color: #FB8200;
                        border-bottom: 1px solid #FB8200;
                    }
                    &-ico{
                        background: center center  no-repeat url("../../img/info.png");
                        background-size: 70%;
                        margin-top: 5px;
                        width: 20px;
                        height: 20px;
                        padding: 4px;
                        cursor: pointer;
                        z-index: 20;
                        &:hover {
                            .services-switch-message {
                                display: block;
                            }
                        }
                    }
                }
            }


            .services-switch-message {
                position: absolute;
                bottom: 100%;
                left: 40%;
                font-size: 12px;
                line-height: 150%;
                padding: 5px;
                display: none;
                min-height: 30px;
                min-width: 95%;
                background-color: $white;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
                transition: 0.2ms;

                &::after {
                    content: ' ';
                    position: absolute;
                    top: 90%;
                    left: 40%;
                    width: 2px;
                    height: 6px;
                    border: 15px solid transparent;
                    border-top: 15px solid $white;

                }
            }
        }

        .checkbox {
            img {
                display: none !important;
            }
            &::before {
                display: none !important;
            }
        }
        .services-toggle {
            margin-top: 4px;
            height: 24px;
            background: #43B02A;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1px 18px 2px;
            cursor: pointer;
            transition: .3s;
            width: 35%;
            &:hover {
                background: #257226;
            }
            svg {
                margin-right: 6px;
                margin-top: -2px;
            }
            p {
                color: white;
                font-weight: 600;
                font-size: 14px;
                line-height: 150%;
            }
            &.active {
                background: #257226;
                svg {
                    transform: rotate(45deg);
                }
            }
        }
    }

    // .product-info__additional-services-item

    &__additional-services-item {
        display: flex;
        justify-content: space-between;
        margin-top: 6px;
        &:first-child {
            margin-top: 0;
        }
        & .input-text{

        }
        .checkbox-label {
            width: 70%;
        }
        .product-info__additional-services-price {
            width: 15%;
            white-space: nowrap;
        }
    }

    // .product-info__additional-services-price

    &__additional-services-price {
        @include main-text;
        width: 100px;
        margin-right: 30px;
        margin-top: 4px;
    }

    // .product-info__delivery-section

    &__delivery-section {
        margin-top: 16px;
        max-width: 585px;
    }

    // .product-info__delivery-item

    &__delivery-item {
        display: flex;
        @include regular;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    // .product-info__delivery-text

    &__delivery-text {
        width: 215px;
        display: flex;
        //&:before{
        //    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.54545 5L0 11H10.1818L16 5H6.54545Z' fill='%2343B02A'/%3E%3C/svg%3E%0A");
        //    width: 16px;
        //    height: 16px;
        //    transform: translateY(10%);
        //    margin-right: 8px;
        //}
        & span{
            font-weight: 500;
            margin-left: 0.2em;
        }
    }

    // .product-info__delivery-timing

    &__delivery-timing {
        width: 78px;
    }

    // .product-info__delivery-price

    &__delivery-price {
        width: 100px;

        &--free {
            color: $main;
        }

    }
}
.review {
    padding: 18px 24px;
    border: 1px solid $second-grey;
    margin-bottom: 24px;
    // .review__header

    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
    }

    // .review__user-name

    &__user-name {
        @include subtitle-main-text;
        margin-right: 32px;
    }

    // .review__user-rating

    &__user-rating {
        margin-right: 32px;
    }

    // .review__date

    &__date {
        @include secondary-text;
    }

    // .review__review

    &__review {
        @include main-text;
        margin-bottom: 8px;
    }

    // .review__footer

    &__footer {
    }

    // .review__features

    &__features {
        display: flex;
        margin-bottom: 4px;
        align-items: center;
    }

    // .review__features--advantages

    &__features--advantages {
    }

    // .review__feature-title

    &__feature-title {
        @include subtitle-small;
        margin-right: 16px;
    }

    // .review__feature-text

    &__feature-text {
        @include main-text;
    }

    // .review__features--disadvantages

    &__features--disadvantages {
    }

    // .review__feedback

    &__feedback {
        margin-top: 16px;
        margin-left: 24px;
        padding-top: 20px;
        border-top: 1px solid $second-grey;
    }

    // .review__manager

    &__manager {
        @include subtitle-main-text;
        margin-right: 32px;
    }

    // .review__feedback-text

    &__feedback-text {
        @include main-text;
    }
}

.review-add {

    // .review-add__rating

    &__rating {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
    }

    // .review-add__rating-stars

    &__rating-stars {
        margin-right: 16px;
        display: grid;
    }

    // .review-add__rating-text

    &__rating-text {
    }

    // .review-add__review-item

    &__review-item {
        display: block;
        height: fit-content;
        margin-bottom: 8px;
    }

    // .review-add__review-add-title

    &__review-add-title {
        @include main-text;
        margin-bottom: 8px;
    }

    // .review-add__textarea

    &__textarea{
        width: 100%;
        border: 1px solid $second-grey;
        padding: 8px 16px;
    }

    // .review-add__text-input

    &__text-input{
        width: 100%;
        height: 40px;
        border: 1px solid $second-grey;
        padding: 0 16px;
    }

    // .review-add__submit-btn

    &__submit-btn {
        display: block;
        margin: 32px auto 0 auto;
    }
}

.products-slider--related{
    & .product-slide-wrap{
        border: 1px solid #E5E5E5;
        &::after{
            display: none;
        }
    }
    .slick-slide.slick-active > div{
        display: flex;
        justify-content: center;
    }
}


.mobile-buy-section{
    display: none;
}

.product-info, .product-page__characteristics, .product-page__description, .product-page__review-add, .product-page__reviews{
    &.highlight{
        animation: highlight 3s 1 ease-in-out;
    }
}
@media only screen and (max-width: 1400px) {
    .services-header {
        flex-wrap: wrap;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 1175px) {
    .product-info{
        &__banking{
            width: 100%;
        }
        &__fav-compare{
            width: 100%;
            margin-top: 12px;
        }
    }
}

@media only screen and (max-width: 1000px) {
    .product-demo{
        &__thumbnail-slider{
            margin-top: 8px;
            & .product-demo__slide-item{
                width: 70px !important;
                height: 70px;
            }
        }
        // &__main-slider &__main-slider-wrap{
        //     height: fit-content;
        // }
    }

    .services-switch {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}

@media only screen and (max-width: 976px) {
    .product-page {
        &__characteristics-name {
            width: 300px;
            padding-right: 8px;
        }
        &__characteristics-val {
            width: 150px;
        }
    }
    .product-page__reviews-wrap {
        padding-top: 0 !important;
        .product-page__container-title {
            margin-top: 0 !important;
        }
        .set-toggle-feedback {
            top: 25px;
        }
    }
    .product-demo{
        &__advantages{
            margin-top: 20px;
        }
        &__advantages-ico{
            display: flex;
            & img{
                width: 12px;
                height: 12px;
            }
        }
        &__advantages-text{
            font-size: 10px;
            line-height: 150%;
        }
        &__advantages-item{
            align-items: flex-start;
            margin-bottom: 9px;
        }
    }
    .product-info{
        &__top-section{
            margin-bottom: 8px;
        }
        &__title{
            @include subtitle-main-text;
        }
        &__feedback-link{
            font-size: 12px;
        }
        &__discounted-price{
            font-size: 12px;
        }
        &__price{
            font-size: 24px;
        }
        &__discount-text{
            font-size: 12px;
        }
        &__discount-val{
            font-size: 14px;
        }
        &__cashback-wrap{
            & img{
                width: 24px;
                height: 24px;
            }
            & .cashback-text{
                font-size: 12px;
            }
            & .cashback-val{
                font-size: 12px;
            }
        }
        &__buy-credit-btn, &__phone-submit{
            margin-right: 0;
        }
        &__section-title{
            @include subtitle-small;
        }
        &__additional-services-item{
            & .input-text{
                @include secondary-text;
            }
        }
        &__additional-services-price{
            @include secondary-text;
        }
        &__delivery-item{
            @include secondary-text;
        }
        &__delivery-text{
            &:before{
                transform: scale(0.9) translateY(1px);
            }
        }
    }
    .product-page{
        // &__title-mobile{
        //     @include subtitle-main-text;
        //     display: block;
        // }
        &__reviews-empty-text{
            font-size: 14px;
            margin-bottom: 20px;
            max-width: 334px;
            width: 100%;
        }
        &__container-title{
            @include subtitle-main-text;
            margin-bottom: 16px;
        }
        &__characteristics-item{
            @include secondary-text;
        }
        &__anchors-wrap{
            height: 23px;
            justify-content: flex-start;
        }
        &__description-item{
            @include secondary-text;
        }
        &__play-video-btn {
            width: 40px;
            height: 40px;
        }
        &__anchor{
            font-weight: 600;
            font-size: 12px;
            &:not(:last-child){
                margin-right: 8px;
            }
            &::after{
                display: none;
            }
            &:hover, &.active{
                border-bottom: 1px solid $second-grey;
            }
        }
        &__see-all-btn{
            font-size: 12px;
            & span{

            }
        }
        .review{
            padding: 8px;
            margin-bottom: 16px;
            &__user-name{
                font-size: 12px;
            }
            &__date{
                @include secondary-text;
            }
            &__review{
                font-size: 10px;
            }
            &__feature-title{
                font-size: 12px;
            }
            &__feature-text{
                font-size: 10px;
            }
            &__feedback{
                margin-top: 16px;
                margin-left: 0;
                padding-top: 10px;
            }
            &__manager{
                font-size: 12px;
            }
            &__feedback-text{
                font-size: 10px;
            }
            &__header{
                margin-bottom: 12px;
            }
        }
        &__review-add{
            padding: 24px;
        }
        &__reviews{
            padding: 24px;
        }
    }
    .review-add{
        &__rating{
            flex-direction: column-reverse;
            align-items: flex-start;
        }
        &__rating-text{
            @include main-text;
        }
        &__rating-stars{
            & img{
                width: 20px;
                height: 20px;
            }
        }
        &__review-add-title{
            @include secondary-text;
            margin-bottom: 4px;
        }
        &__textarea{
            @include secondary-text;
            height: 70px;
        }
        &__text-input{
            @include secondary-text;
        }
        &__submit-btn{
            padding: 12px 40px;
            @include button;
        }
    }

    .services-switch-message {
        left: 0 !important;
        max-width:10%;
    }
}

@media only screen and (max-width: 840px) {
    .product-info__phone-submit {
        display: none;
    }
    .mobile-one-click {
        display: block;
    }
    .product-info{
        &__phone-input{
            display: none;
        }
        &__phone-submit{
            padding: 8px 24px;
            font-size: 12px;
        }
        &__buy-section-bottom{
            display: flex;
            justify-content: space-between;
        }
        &__fav-compare{
            width: fit-content;
            margin-top: 0;
        }
    }
}

@media only screen and (max-width: 824px) {
    .product-info{
        &__pricing{
            margin-bottom: 24px;
        }
        &__buy-btn, &__opt-btn {
            margin-right: 0;
            width: 100%;
        }
        &__buy-credit-btn{
            width: 100%;
        }
        &__banking{
            display: none;
        }
        &__pricing{
            flex-wrap: wrap;
            margin-top: 40px;
        }
        &__cashback-wrap{
            width: 100%;
        }
        &__banking-mobile{
            display: flex;
            margin-left: auto;
        }
        &__discount{
            margin: 0;
        }
    }
}
.mobile-review-add {
    display: none;
}

@media only screen and (max-width: 740px) {
    .product-page__reviews-empty-arrow {
        display: none;
    }
    .product-demo__slide-item {
        height: 400px;
    }
    .mobile-review-add {
        display: block;
    }
    .product-info{
        padding-bottom: 0 !important;
    }
    .mobile-buy-section {
        position: sticky;
        left: 0;
        right: 0;
        bottom: 0;
        transform-origin: bottom;
        transform: scaleY(0);
        transition: .3s;
        display: block;
        background: $white;
        &.active{
            box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.1);
            transform: scaleY(1);
        }

        // .mobile-buy-section__content

        &__content {
            padding: 4px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        // .mobile-buy-section__product-name

        &__product-name {
            font-weight: 600;
            font-size: 12px;
            line-height: 150%;
            max-width: 60%;
        }

        // .mobile-buy-section__buy-btn

        &__buy-btn {
            padding: 0;
            background: $main;
            width: 82px;
            height: 27px;
        }

        .services-switch-message {
            left: 50px;
        }
    }

    .product-page{
        &__anchors-wrap{
           display: block;
           height: auto;
            background: transparent;
            &.active{
                margin-left: -10px;
                margin-right: -10px;
                padding-left: 10px;
                position: fixed;
                top: 88px;
                z-index: 55;
                transition: 0.3s;
            }
        }
        &__anchor{
            min-width: max-content;
            padding: 4px;
            display: inline-block;
        }
        &__characteristics{
            & .product-page__container-title{
                margin-top: 0;
                padding-left: 0;
            }
        }
        &__content-wrap{
            flex-direction: column;
            align-items: center;
        }
        &__title-mobile{
            display: block;
            text-align: center;
        }
        &__characteristics, &__description, &__reviews-wrap{
            max-width: 520px;
            width: 100%;
        }
        &__characteristics {
            padding: 24px;
        }
        &__description-text, &__description-video{
            padding-left: 0;
        }
        &__review-add{
            display: none;
        }
        &__anchor--add-review{
            display: none;
        }
        &__add-review-btn{
            display: block;
            font-size: 12px;
            line-height: 150%;
            background: none;
            position: relative;
            padding: 0;
            cursor: pointer;
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                left: 0;
                right: 0;
                bottom: 2px;
                border-bottom: 1px solid $black;
            }
        }
        &__see-all-section{
            display: flex;
            justify-content: space-between;
        }
        .product-page__reviews--empty{

        }
        .product-page__reviews-empty{
            position: static;
            margin-top: 40px;
        }
    }
    .product-info{
        max-width: 520px;
        width: 100%;
        padding: 74px 10px 24px 10px;
        &__title{
            display: none;
        }
        &__buy-btn, &__opt-btn, &__buy-credit-btn{
            max-width: 350px;
            margin-left: auto;
            margin-right: auto;
        }
        &__buy-credit-btn{
            font-size: 14px;
        }
    }
    .product-demo{
        width: 100%;
        max-width: 520px;
        height: 400px;
        padding-bottom: 0;
        .product-demo__slider-wrap {
            margin-bottom: 0;
        }
        &__thumbnail-slider{
            display: none;
        }
        &__main-slider{
            & .slick-dots{
                display: flex;
                justify-content: center;
                & li{
                    display: flex;
                    align-items: center;
                    height: 24px;
                }
                & li button{
                    font-size: 0;
                    background: $second-grey;
                    height: 2px;
                    width: 24px;
                    margin: 0 4px;
                }
                & li.slick-active button{
                    background: $main;
                }
            }
        }
    }
}
@media only screen and (max-width: 500px) {
    .services-header {
        display: block !important;
    }
    .product-info__additional-services-price {
        margin-right: 20px;
    }
    .services-switch {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}
#product-reviews {
    scroll-margin-top: 70px;
}