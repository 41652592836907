@import '../../../scss/reset/vars';
@import '../../../scss/reset/reser-style';

.category-section {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0;
    margin-top: 70px;
    // .category-section__item

    &__item {
        max-width: 190px;
        width: 190px;
        height: 157px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $white;
        transform: translateY(0);
        backface-visibility: hidden;
        box-shadow: 0px 20px 50px rgba(0, 0, 0, 0);
        transition: .3s;
        cursor: pointer;
        &:hover{
            box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
            transform: translateY(-2%);
        }
    }

    // .category-section__item-body

    &__item-body {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    // .category-section__item-img

    &__item-img {
        width: 80px;
        height: 80px;
        margin-top: 12px;
        margin-bottom: 8px;
        & img{
            object-fit: none;
        }
    }

    // .category-section__item-title

    &__item-title {
        text-align: center;
        max-width: 131px;
        margin: 0 auto;
        @include subtitle-small;
        color: $black;
    }
}


@media only screen and (max-width: 1378px) {
    .category-section{
        &__item{
            &:not(:last-child){
                margin-right: 10px;
            }
        }
    }
}
@media only screen and (max-width: 976px) {
    .category-section{
        margin-top: 30px;
        margin-bottom: 40px;
        &__item-title{
            font-size: 12px;
        }
    }
}
@media only screen and (max-width: 895px) {
    .category-section{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-items: center;
        &__item{
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
            &:first-child{
                margin-bottom: 8%;
            }
            &:not(:last-child){
                margin-right: 0;
            }
        }
    }
}

@media only screen and (max-width: 636px) {
    .category-section{
        &__item{
            width: 130px;
            height: 98px;
            &:first-child{
            }
            &:not(:last-child){
            }
        }
        &__item-img{
            width: 48px;
            height: 48px;
            margin-top: 8px;
            margin-bottom: 0;
            & img{
                object-fit: cover;
            }
        }
        &__item-title{
            height: 30px;
            display: flex;
            align-items: center;
            margin-top: 5px;
        }
    }
}

@media only screen and (max-width: 430px) {
    .category-section{
        grid-template-columns: 1fr 1fr;
        &__item{
            margin-bottom: 8%;
        }
    }
}
