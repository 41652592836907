@import "../../../../scss/reset/reser-style";
.subs-done {
  background: #FFFFFF;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.05);
  padding: 32px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 392px;
  display: none;
  &.active{
    display: flex;
  }
  // .subs-done__title

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    color: $main;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 8px;
  }

  // .subs-done__subtitle

  &__subtitle {
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    margin-bottom: 28px;
  }
  & .close-btn {
    position: absolute;
    cursor: pointer;
    background: none;
    padding: 0;
    top: 10px;
    right: 10px;
  }

  // .subs-done__done-btn

  &__done-btn {
    width: 250px;
  }
}

@media only screen and (max-width: 976px) {
  .subs-done {
    width: 280px;
    &__title{
      font-size: 16px;
      line-height: 20px;
      color: $black;
      margin-bottom: 10px;
    }
    &__subtitle{
      font-size: 14px;
    }
    &__done-btn {
      width: 176px;
    }
  }
}