@import '../../../scss/reset/vars';

.brands-slider-wrap {
    display: flex;
    align-items: center;
    margin-top: 4px;
    position: relative;
    & .slider-arrow-left, & .slider-arrow-right{
        transform: scale(1) translateY(-50%);
        transition: .1s;
        position: absolute;
        top: 50%;
        &:active{
            transform: scale(0.8) translateY(-50%);
        }
    }
}
.brands-slider {
    width: 100%;
    &__item {
        transform: translateY(0);
        transition: 0.3s;
        position: relative;
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: center;
        display: flex !important;
        justify-content: center;
        &:hover{
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
            transform: translateY(-2%);
            z-index: 1;
        }
        & img{
            height: 107px;
        }
    }
}
.slider-arrow-left, .slider-arrow-right{
    background: none;
    height: fit-content;
    padding: 0;
    cursor: pointer;
}

@media only screen and (max-width: 1384px) {
    .brands-slider-wrap{
        & .slider-arrow-right, & .slider-arrow-left{
            display: none;
        }
    }
    .brands-slider__item img {
        height: 80px;
    }
}