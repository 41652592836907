@import "src/scss/style";

.page-404 {
    & .container{
        position: relative;
    }
    // .page-404__background

    &__background {
        position: absolute;
        width: 100%;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        overflow: hidden;
        & img{
            // object-fit: cover;
            object-position: 0 180px;
        }
    }

    // .page-404__body

    &__body {
        // margin-top: 105px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: flex-end;
        // height: 90vh;
        height: 100%;
    }

    // .page-404__text-wrap

    &__text-wrap{
        width: 810px;
    }

    // .page-404__title

    &__title {
        font-weight: 600;
        font-size: 36px;
        line-height: 150%;
        margin-top: 80px;
        margin-bottom: 20px;
    }

    // .page-404__bottom-wrap

    &__bottom-wrap{
        display: flex;
    }

    // .page-404__subtitle

    &__subtitle {
        font-size: 18px;
        line-height: 150%;
    }

    // .page-404__back-to-main

    &__back-to-main {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #FFF;
        padding: 12px 40px;
        background: $second;
        transition: .3s;
        height: fit-content;
        transform: translateY(32px);
        &:hover{
            background: #124D13;
        }
    }

    // .page-404__img

    &__img {
        position: relative;
        z-index: 2;
        margin-bottom: 20%;
    }
}


@media only screen and (max-width: 976px) {
    .page-404{
        &__body{
            justify-content: center;
        }
        &__title{
            font-size: 16px;
            margin-top: 40px;
            margin-bottom: 20px;
            text-align: center;
        }
        &__subtitle{
            font-size: 14px;
            line-height: 150%;
            text-align: center;
        }
        &__img {
            margin-bottom: -20px;
            margin-top: -20px;
            width: 265px;
        }
        &__bottom-wrap{
            flex-direction: column-reverse;
            align-items: center;
        }
        &__back-to-main{
            width: fit-content;
            display: block;
            margin: 0 auto;
            margin-bottom: 200px;
            font-size: 12px;
            padding: 8px 32px;
        }
        &__background {
            & img{
                object-fit: cover;
                object-position: 0 7em;
            }
        }
    }
}

@media only screen and (max-width: 700px) {
    .page-404{
        & .container{
            display: flex;
            flex-direction: column-reverse;
        }
        &__body{
            width: 100%;
        }
        &__background{
            & img{
                width: 100%;
                height: auto;
                transform: translateY(20%);
                object-position: 0 0;
            }
        }
    }
}