@import '../../scss/reset/reser-style';

.account {
    position: relative;
    .form__input:focus ~ span, .form__input:not(:focus):valid ~ span, .form__input:not(:placeholder-shown) ~ span {
        background: #F5F5F7;
        padding: 5px;
    }
    .container {
        position: relative;
    }
    .cashback-block {
        position: absolute;
        right: 80px;
        display: flex;
        align-items: center;

        .left-cashback {
            margin-right: 5px;
        }

        .right-cashback {
            p {
                font-weight: normal;
                font-size: 14px;
                line-height: 150%;
                span {
                    color: #FB8200;
                    text-decoration: underline;
                    font-weight: 600;
                    font-size: 16px;
                    margin-left: 5px;
                    cursor: pointer;
                    &:hover {
                        .cashback-description {
                            display: block;
                        }
                    }
                    .cashback-description {
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 150%;
                        cursor: auto;
                        transition: .3s;
                        margin-top: 10px;
                        background: #FFFFFF;
                        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
                        width: 172px;
                        padding: 10px;
                        position: absolute;
                        display: none;
                        z-index: 10;
                    }
                }
            }
        }
    }
    .empty-catalog-card {
        margin-bottom: -100px;
    }
    margin-top: 32px;

    .profile_valid {
        margin-top: -10px;
        margin-bottom: 10px;
    }

    .logout-btn {
        margin-top: 50px;
        display: flex;
        background: none;
        padding: 0;
        align-items: center;
        cursor: pointer;
        &:hover{
            & .logout-btn__text {
                color: #43B02A;
            }
        }
        // .logout-btn__ico

        &__ico {
            margin-right: 8px;
        }

        // .logout-btn__text

        &__text {
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
            color: #868686;
            transition: .2s;
        }
    }

    // .account__body

    &__body {
        margin-bottom: 100px;
    }

    // .account__body-tabs

    &__body-tabs {
        display: flex;
    }

    // .account__tab

    &__tab {
        width: 430px;
        border-bottom: 4px solid transparent;
        font-weight: bold;
        font-size: 18px;
        line-height: 150%;
        padding: 14px 40px;
        cursor: pointer;
        transition: .2s;
        &.active{
            border-bottom: 4px solid #43B02A;
        }
    }

    // .account__body-tabs-content

    &__body-tabs-content {
        width: 100%;
        overflow: hidden;
        display: flex;
    }
}

.account-profile {
    width: 100%;
    padding: 40px;
    margin-left: -100%;
    transition: .3s;
    min-width: 100%;
    .account-profile-flex {
        display: flex;
    }
    &.active{
        margin-left: 0;
    }
    // .account-profile__edit-btn

    &__edit-btn {
        background: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: $grey;
        cursor: pointer;
        & svg{
            margin-right: 8px;
        }
    }
}
.account-form {
    width: 50%;

    // .account-form__title

    &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 16px;
    }

    .empty-feedback-text {
        margin-bottom: 20px;
        margin-top: -8px;
    }

    & .form__label {
        display: flex;
        flex-direction: column;
        &:not(:last-child){
            margin-bottom: 18px;
        }
    }

    & .account-form__body {
        width: fit-content;
    }


    & .form__input {
    }

    & .form__label--email {
    }
}


.standart {
}
.street {
}
.account-history {
    display: flex;
    padding: 40px;
    margin-right: 100%;
    transition: .3s;
    &.active{
        display: flex;
        margin-right: 0;
    }
}

.history-table {
    width: 100%;
    min-width: 854px;
    // .history-table__row

    &__row {
        display: flex;
    }

    // .history-table__row--header

    &__row--header {
        background: #183029;
        & > div {
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
            color: #FFFFFF;
            height: 60px;
        }
    }

    // .history-table__wrap

    &__wrap {
    }

    // .history-table__table

    &__table {
        display: flex;
        border: 1px solid $grey;
        background: $white;
        margin-top: 16px;
        border-left: 4px solid $main;
    }

    // .history-table__col

    &__col {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        min-height: 48px;
        padding: 5px;
    }

    // .history-table__inner

    &__inner {
        & .history-table__row {
            min-height: 48px;
            &:not(:last-child){
                border-bottom: 1px solid $second-grey;
            }
        }
    }

    // .history-table__col--number

    &__col--number {
        width: 110px;
        max-width: 110px;
    }

    // .history-table__col--name

    &__col--name {
        width: 320px;
        max-width: 320px;
    }

    // .history-table__col--quantity

    &__col--quantity {
        width: 110px;
        max-width: 110px;
    }

    // .history-table__col--services

    &__col--services {
        width: 250px;
        max-width: 250px;
        display: flex;
        flex-direction: column;
        & .history-table__col-item{
            &:not(:last-child){
                border-bottom: 1px solid $second-grey;
            }
        }
    }

    // .history-table__col-item

    &__col-item {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    // .history-table__col--date

    &__col--date {
        width: 168px;
        max-width: 168px;
    }

    // .history-table__col--total

    &__col--total {
        width: 177px;
        max-width: 177px;
    }
}

@media only screen and (max-width: 1546px) {
    .account-history{
        padding: 40px 0;
        flex-wrap: nowrap;
        overflow-y: hidden;
        overflow-x: auto;
    }

}

@media only screen and (max-width: 1310px) {
    .history-table {
        width: fit-content;
        &__col--number{
            max-width: 97px;
        }
        &__col--total{
            max-width: 104px;
        }
        &__col--date{
            max-width: 119px;
        }
        &__col--services{
            max-width: 217px;
        }
        &__col--quantity{
            max-width: 87px;
        }
        &__col--name{
            max-width: 225px;
        }
    }
    .account-history{
        width: 100%;
        display: flex;
    }
}

@media only screen and (max-width: 976px) {
    .account-profile-flex {
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    .account{
        .cashback-block {
            position: relative;
            right: 0;
        }
        &__tab {
            padding: 10px 12px;
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
            max-width: 230px;
            width: 50%;
        }
        &__body {
            margin-bottom: 70px;
        }
    }
    .account-profile {
        flex-direction: column;
        align-items: center;
        padding: 20px 10px;
    }
    .account-form {
        width: fit-content;
        justify-content: center;
        &:not(:last-child){
            margin-bottom: 40px;
        }
        & .form__input{
            width: 370px;
        }
        &__body{
            width: 100% !important;
        }
    }
    .history-table{
        &__col, &__row--header > div {
            font-size: 12px;
        }
        &__table{
            margin-top: 14px;
        }
    }
    .account-history{
        padding: 20px 0 !important;
    }
}

@media only screen and (max-width: 430px) {
    .account-form {
        width: 100%;
        & .form__label{
            width: 100%;
        }
        & .form__input{
            width: 100%;
        }
    }
    .account{
        &__tab{
            text-align: center;
            padding-left: 0;
        }
     }
}
