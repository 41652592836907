@import '../../../../scss/reset/reser-style';
.phones-modal {
  width: 220px;
  position: relative;
  background: #FFFFFF;
  flex-direction: column;
  box-shadow: 0px 100px 80px rgba(24, 48, 41, 0.07), 0px 41.7776px 33.4221px rgba(24, 48, 41, 0.0503198), 0px 22.3363px 17.869px rgba(24, 48, 41, 0.0417275), 0px 12.5216px 10.0172px rgba(24, 48, 41, 0.035), 0px 6.6501px 5.32008px rgba(24, 48, 41, 0.0282725), 0px 2.76726px 2.21381px rgba(24, 48, 41, 0.0196802);
  display: none;
  &.active{
    display: flex;
  }
  // .phones-modal__phone

  &__phone {
    padding: 16px 0;
    text-align: center;
    font-size: 14px;
    line-height: 150%;
    &:not(:last-child){
      border: 1px solid $second-grey;
    }
  }

  & .close-btn {
    position: absolute;
    cursor: pointer;
    background: none;
    padding: 0;
    top: 4px;
    right: 4px;
  }
  .phone-modal-info {
    padding: 24px 40px;
    .service-phone {
      margin-top: 8px;
      margin-bottom: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .phone-service-item {
        width: 111px;
        &:hover{
          a {
            color: #257226;
          }
        }
        p {
          color: #868686;
          font-weight: 500;
          font-size: 12px;
          line-height: 150%;
        }
        a {
          font-weight: 600;
          font-size: 12px;
          line-height: 150%;
          color: $black;
        }
      }
    }
    .work-days {
      margin-bottom: 12px;
      padding: 0 5px;
      &_item {
        display: flex;
        justify-content: space-between;
        .left {
          color: #257226;
          text-align: right;
          font-weight: 600;
          font-size: 12px;
          line-height: 150%;
        }
        .right {
          color: #000000;
          font-weight: normal;
          font-size: 12px;
          line-height: 150%;
        }
      }
    }
    .support-email {
      padding-top: 12px;
      border-top: 1px solid #DCDCDC;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover{
        a {
          color: #257226;
        }
      }
      a {
        color: #000000;
        font-weight: normal;
        font-size: 12px;
        line-height: 150%;
        margin-left: 10px;
      }
    }
  }
}