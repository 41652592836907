
.product-slider-modal__main-slider {
  width: 100% !important;
}
html, * {
  font-family: 'Montserrat', sans-serif;
  color: $black;
}
body{
  position: relative;
  background: #F5F5F7 !important;
}
.body-shade{
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(24, 48, 41, 0.25);
  opacity: 0;
  z-index: -2;
  transition: 0.25s;
  &.shaded{
    opacity: 1;
    z-index: 4;
  }
}
.content-wrap{
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 158px;
  &::after{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(24, 48, 41, 0.25);
    opacity: 0;
    z-index: -1;
    transition: 0.25s;
  }
  &.shaded{
    &::after{
      opacity: 1;
      z-index: 2;
    }
  }
}

.dark_bg {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 0;
}

.page-title{
  @include title;
  border-left: 4px solid $main;
  padding-left: 8px;
  margin-bottom: 32px;
}

@media only screen and (max-width: 1240px) {
  .content-wrap{
    padding-top: 88px;
  }
  .page-title{
    @include subtitle-main-text;
    border-left: 2px solid $main;
    padding-left: 2px;
  }
}

.container{
  margin-right: auto;
  margin-left:  auto;
  max-width: 1310px;
  width: 100%;
  padding-right: 10px;
  padding-left:  10px;
}
@media only screen and (min-width: 1024px) and (max-width: 1580px) {
  .container{
    padding-right: 50px;
    padding-left:  50px;
  }
}
.container-header{
  margin-right: auto;
  margin-left:  auto;
  max-width: 1550px;
  width: 100%;
  padding-right: 10px;
  padding-left:  10px;
}
.slider-container{
  max-width: 1290px;
  width: 100%;
  margin: 0;
  position: relative;
}
.product-item{
  display: block;
  text-decoration: none;
}

.arrow-down{
  cursor: pointer;
  transform: rotateX(0deg);
  transition: 0.2s;
}
.arrow-down.active{
  transform: rotateX(180deg);
}


.modal__close{
  cursor: pointer;
}

//==========================================BUTTONS
.btn-light{
  text-align: center;
  padding: 12px 35px;
  background: $main;
  color: $white;
  @include button;
  display: block;
  cursor: pointer;
  transition: .2s;
  &:hover{
    background: $second;
  }
}
.btn-dark{
  padding: 12px 40px;
  background: $second;
  color: $white;
  @include button;
  display: block;
  cursor: pointer;
  transition: .2s;
  &:hover{
    background: #124D13;
  }
}

.btn-white{
  border: 1px solid $main;
  padding: 14px 36px;
  background: $white;
  @include button;
  color: $main;
  cursor: pointer;
  transition: .3s;
  &:hover{
    background: $main;
    color: $white;
  }
}

.btn-white-second{
  border: 2px solid $second;
  padding: 12px 40px;
  background: $white;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: $second;
  cursor: pointer;
  transition: .3s;
  &:hover{
    background: $second;
    color: $white;
  }
}

.btn-main-white{
  padding: 8px 32px;
  background: $white;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  border: 1px solid $main;
  color: $main;
  transition: .3s;
  cursor: pointer;
  &:hover{
    background: $main;
    color: $white;
  }
}

input[type=email]{
  transition: .15s;
  border: 1px solid $second-grey;
  // &:hover{
  //   border-color: #257226;
  // }
  // &:focus{
  //   border: 2px solid #257226;
  // }
  // &:valid{
  //   border: 1px solid #257226;
  // }
  // &:invalid{
  //   border: 1px solid #DB4915;
  // }
}
// ============================================================================ ANIMATIONS
@keyframes rotate{
  1%   {transform: rotate(15deg);}
  5%  {transform: rotate(-15deg);}
  8%  {transform: rotate(15deg);}
  11%  {transform: rotate(-15deg);}
  14%  {transform: rotate(-0deg);}
  16%  {transform: translateY(1%);}
  40% {transform: translateY(3%);}
  60%  {transform: translateY(-3%);}
  80% {transform: translateY(3%);}
  99% {transform: translateY(0%);}
}

@keyframes cubesUp{
  0% {transform: translateY(0); opacity: 0.9;}
  99% {transform: translateY(50%);}
  100% {transform: translateY(-50%); opacity: 0;}
}

@keyframes fade{
  0% {opacity: 0.0;}
  10% {opacity: 1;}
  90% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes highlight{
  0% {box-shadow: 0px 0px 20px rgba(67, 176, 42, 0.3);}
  100% {box-shadow: 0px 0px 0px rgba(67, 176, 42, 0);}
}

@keyframes buy-btn{
  0% {width: 22px;}
  10% {width: 22px; background: $main;}
  100% {width: 83px; background: $main;}
}


.checkbox-label {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  & .input-text{
    @include main-text;
  }
  & input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  & .checkbox{
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    & img{
      position: absolute;
      top: 0;
      left: 4px;
      bottom: 0;
      margin: auto;
      opacity: 1;
    }
    &::before{
      content: '';
      margin-right: 10px;
      display: block;
      width: 16px;
      min-width: 16px;
      height: 16px;
      background: $white;
      border: 1px solid #257226;
      cursor: pointer;
    }
  }
  & .input-text {
    transform: translateY(1px);
  }

  // Disabled state label.
  & input:disabled + .checkbox {
    background: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  & input:disabled + .checkbox:before {
    box-shadow: none;
    background: #ddd;
  }

  & input:checked ~ .checkbox::before{
    background: #43B02A !important;
    border: 1px solid #43B02A !important;
  }

}



.checkbox-wrap{
  cursor: pointer;
  display: flex;
  align-items: center;
  & input{
    position: absolute;
    opacity: 0;
  }
  & .checkbox{
    display: flex;
    align-items: center;
    position: relative;
    & img{
      position: absolute;
      top: 8.5px;
      left: 7px;
      opacity: 0;
    }
    &::before{
      content: '';
      margin-right: 10px;
      display: block;
      width: 24px;
      height: 24px;
      background: $white;
      border: 1px solid #257226;
      box-shadow: 1px 2px 10px rgba(235, 244, 248, 0.5);
      border-radius: 6px;
      cursor: pointer;
    }
  }

  // Disabled state label.
  & input:disabled + .checkbox {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  & input:disabled + .checkbox:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  & input:checked + .checkbox img {
    opacity: 1;
  }
}



.radio-wrap{
  cursor: pointer;
  display: flex;
  align-items: center;
  & input{
    position: absolute; // take it out of document flow
    opacity: 0;
  }
  & .radio{
    display: flex;
    align-items: center;
    position: relative;
    &::before{
      content: '';
      box-sizing: border-box;
      margin-right: 10px;
      display: block;
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      background: $white;
      border: 2px solid $main;
      box-shadow: 1px 2px 10px rgba(235, 244, 248, 0.5);
      border-radius: 50%;
      cursor: pointer;
    }
    &::after{
      content: '';
      display: block;
      box-sizing: border-box;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $main;
      position: absolute;
      z-index: 3;
      top: 0;
      bottom: 0;
      left: 4px;
      margin: auto 0;
      opacity: 0;
      cursor: pointer;
    }
  }

  // Disabled state label.
  & input:disabled + .radio{
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  & input:disabled + .radio::after {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  & input:checked + .radio::after {
    opacity: 1;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media only screen and (max-width: 976px) {
  .radio-wrap{
    & .radio{
      font-size: 12px !important;
      &::before{
        margin-right: 8px;
        width: 16px;
        height: 16px;
        min-width: 16px;
        min-height: 16px;
      }
      &::after{
        width: 8px;
        height: 8px;
      }
    }
  }
}


.form{
  &__label{
    position: relative;
    margin-bottom: 18px;
    width: fit-content;
    & span{
      position: absolute;
      left: 16px;
      top: 0;
      bottom: 0;
      line-height: 150%;
      margin: auto 0;
      font-size: 14px;
      color: $grey;
      transition: 0.2s;
      background: #F5F5F7;
      height: fit-content;
    }
    & .required{
      &::after{
        content: '*';
        color: red;
        margin-left: 2px;
      }
    }
  }
  &__input{
    width: 100%;
    border: 1px solid $second-grey;
    padding: 10px 16px;
    height: 41px;
    width: 370px;
    font-size: 14px;
    line-height: 150%;
    background: #F5F5F7;
    &:focus ~ span, &:not(:focus):valid ~ span, &:not(:placeholder-shown) ~ span{
      top: -100%;
      font-size: 12px;
      background: none;
    }
  }
}

.slider-pagination{
  margin: 10px auto;
  display: flex;
  justify-content: center;
  &__current, &__amount{
    font-size: 16px;
  }
}


.photos-section-wrap{
  position: relative;
  padding: 0 30px;
  margin-bottom: 100px;
  & .slider-pagination{
    display: none;
  }
  & .slider-arrow-left, & .slider-arrow-right{
    position: absolute;
    top: 50%;
    transform: scale(1) translateY(-50%);
    transition: .1s;
    transform-origin: center;
    display: none;
    &:active{
      transform: scale(0.8) translateY(-50%);
    }
  }
  & .slider-arrow-left{
    left: 0;
  }
  & .slider-arrow-right{
    right: 0;
  }
}


.photos-section {
  display: grid;
  column-gap: 2%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  & .slick-slide > div{
    display: flex;
    justify-content: center;
    height: fit-content;
  }
  &__photo {
    max-width: 300px;
    height: 350px;
    margin-bottom: 6%;
    & img{
      width: auto;
      height: 100%;
      object-fit: cover;
    }
  }
}
@media only screen and (max-width: 1290px) {
  .photos-section-wrap{
    & .slider-arrow-left, & .slider-arrow-right{
      display: block !important;
    }
  }
  .photos-section{
    &__photo{
      margin-bottom: 0;
    }
  }
}
@media only screen and (max-width: 976px) {
  .photos-section {
    display: block !important;
  }
  .photos-section-wrap{
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 698px) {
  .photos-section {
    display: block !important;
  }
  .photos-section-wrap{
    & .slider-arrow-left, & .slider-arrow-right{
      display: none !important;
    }
    & .slider-pagination{
      display: flex;
    }
  }
}
.container-small{
  margin-right: auto;
  margin-left:  auto;
  max-width: 1210px;
  width: 100%;
  padding-right: 10px;
  padding-left:  10px;
}

.modals{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  //padding-top: 150px;
  height: 100vh;
  background: rgba(24, 35, 22, 0.3);
  z-index: 999999;
  overflow-y: auto;
  overflow-x: hidden;
  display: none;
  justify-content: center;
  align-items: center;
  &.active{
    display: flex;
  }
}

@media (max-height: 980px) {
  .modals {
    padding-top: 150px;
  }
}

.products-empty {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // .products-empty__to-main

  &__to-main {
    position: absolute;
    top: 0;
    right: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    text-decoration-line: underline;
    transition: .2s;
    &:hover{
      color: $main;
    }
  }

  // .products-empty__title

  &__title {
    margin-top: 68px;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    margin-bottom: 30px;
  }

  // .products-empty__subtitle

  &__subtitle {
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    margin-bottom: 60px;
  }
}


@media only screen and (max-width: 976px) {
  .products-empty {
    // .products-empty__to-main

    &__to-main {
      top: auto;
      right: auto;
      left: 0;
      bottom: 0;
    }

    // .products-empty__title

    &__title {
      font-size: 14px;
      margin-top: 0;
      margin-bottom: 20px;
    }

    // .products-empty__subtitle

    &__subtitle {
      font-size: 14px;
      margin-bottom: 32px;
    }
  }
}