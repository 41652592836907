@import '../../../scss/reset/vars';
@import '../../../scss/reset/reser-style';

.pagination-wrap {
  margin-bottom: 100px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.pagination {
  display: flex;
  font-size: 16px;
  line-height: 150%;
  &>div{
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s;
    user-select: none;
    cursor: pointer;
    &:hover{
      background: $label;
    }
    &:active{
      transform: scale(0.9);
    }
  }
  // .pagination__arrow

  &__arrow {

  }

  // .pagination__arrow--left

  &__arrow--left {
  }

  // .pagination__item

  &__item {
    &.active {
      background: $main;
      color: $white;
      font-weight: 600;
      &:hover{
        background: $main;
      }
    }
  }

  // .pagination__arrow--right

  &__arrow--right {
  }
}