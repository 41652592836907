@import "src/scss/style";

.order-page {
  .grey-line {
    height: 1px;
    width: 100%;
    background: #F1F1F5;
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .inline-input {
    width: fit-content;
    display: none;

    &.active {
      display: flex;
    }

    label {
      margin-right: 10px !important;
    }
  }

  .block-input {
    display: flex;
    flex-direction: column;
  }

  .product-footer-block {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .price-block {
      width: 50%;
    }

    .basket-item__counter {
      background: #FFFFFF;
    }
  }

  .price-service-block {
    display: flex;
    justify-content: space-between;
  }

  .cashback-block {
    .empty-feedback-text {
      margin-top: 10px;
    }

    .top {
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      .right-cashback {
        margin-left: 5px;

        p {
          font-weight: normal;
          font-size: 14px;
          line-height: 150%;

          span {
            color: #FB8200;
            text-decoration: underline;
            font-weight: 600;
            font-size: 16px;
            margin-left: 5px;
            cursor: pointer;
          }
        }
      }

      input {
        margin-left: 16px;
        padding: 10px 40px 10px 30px;
        width: 130px;
        border: 1px solid #DCDCDC;
        background: #F5F5F7;
      }

      span {
        margin-left: -40px;
      }
    }
  }

  // .order-page__header

  &__header {
    margin-top: 27px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  & .page-title {
    margin-top: 36px;
  }

  // .order-page__logo

  &__logo {

  }

  // .order-page__work-data

  &__work-data {

  }

  // .order-page__hotline

  &__hotline {
    display: flex;
    margin-bottom: 10px;
  }

  // .order-page__hotline-title

  &__hotline-title {
    color: #000;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    align-items: center;
    margin-right: 10px;
  }

  // .order-page__hotline-number

  &__hotline-number {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: $second;
  }

  // .order-page__worktime

  &__worktime {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  // .order-page__worktime-item

  &__worktime-item {
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  // .order-page__worktime-days

  &__worktime-days {
    margin-right: 8px;
  }

  // .order-page__worktime-time

  &__worktime-time {
  }

  &__worktime-days, &__worktime-time {
    font-size: 14px;
    line-height: 140%;
    color: $grey;
  }

  // .order-page__body

  &__body {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  // .order-page__footer

  &__footer {
    width: 100%;
    border-top: 1px solid #3D3C3C;

    & .container {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      height: 33px;
    }
  }

  // .order-page__footer-copyrights

  &__footer-copyrights {
    @include secondary-text;
    color: $grey;
    margin-bottom: 5px;
  }

  // .order-page__footer-icons

  &__footer-icons {
    display: flex;
    height: 28px;
    align-items: center;
    align-self: center;

    & img:not(:first-child) {
      margin-left: 24px;
    }
  }
}

.container {
}

.required {
  cursor: text;
}

.order-data {
  .filter__form {
    position: relative;
    padding-top: 0;
    padding-left: 0;

    & label {
      display: flex;
      align-items: center;
    }

    & .checkbox {
      &::before {
        align-self: flex-start;
        margin-top: 1px;
      }

      & img {
        align-self: flex-start;
        margin-top: 6px;
      }

      & .input-text {
        font-size: 12px;
        position: relative;
        z-index: -1;
      }

      & .input-text-link {
        text-decoration-line: underline;
        color: $main;
        margin-left: 2px;
      }
    }
  }

  // padding: 24px 40px 0 40px;
  width: 65.89%;
  // .order-data__section

  &__section {
    margin-bottom: 12px;
    padding: 24px 40px;
    background: #FFFFFF;

  }

  // .order-data__section--double

  &__section--double {
    display: flex;
    padding-bottom: 24px;
    margin-top: 0 !important;
  }

  // .order-data__user-form

  &__user-form {
    display: flex;
    flex-direction: column;
  }

  // .order-data__section-title

  &__section-title {
    @include subtitle;
    margin-bottom: 24px;
  }

  .phone-input {
    .form__input:focus ~ span, .form__input:not(:focus):valid ~ span, .form__input:not(:placeholder-shown) ~ span {
      width: 18%;
    }

    .required {
      width: 90%;
    }
  }

  // .order-data__form-subtitle

  &__form-subtitle {
    font-size: 12px;
    line-height: 150%;
    max-width: 370px;
  }

  & .form__label--email {
    margin-bottom: 8px;
  }

  // .order-data__login-section

  &__login-section {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-self: center;
    max-width: 320px;

    h6 {
      margin-top: 34px;
      margin-bottom: 16px;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
    }

    .socials {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 14px;

      .social-item-facebook {
        padding: 15px 65px;
        background: #F7F7F9;
        cursor: pointer;
      }

      .social-item-google {
        box-shadow: none !important;
        border: none !important;

        div {
          padding: 15px 65px !important;
          margin: 0 !important;
          background: #F7F7F9 !important;
          cursor: pointer;
        }

        span {
          display: none;
        }
      }
    }
  }

  // .order-data__login-title

  &__login-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 10px;
  }

  // .order-data__login-subtitle

  &__login-subtitle {
    @include main-text;
    margin-bottom: 30px;
  }

  // .order-data__login-btn

  &__login-btn {
    width: fit-content;
  }

  // .order-data__delivery-form

  &__delivery-form {
    & .radio-wrap {
      position: relative;
      margin-bottom: 25px;

      & .radio {
        @include main-text;

        & .order-data__post {
          margin-left: 5px;
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
        }

        & img {
          margin-right: 6px;
        }

        svg {
          margin-right: 6px;
        }
      }

    }

    & .order-data__dropdown-selector, & .order-data__address-delivery {
      height: 0;
      overflow: hidden;
      transform: scaleY(0);
      transform-origin: top;

      &.active {
        padding: 10px 16px;
        height: fit-content;
        transform: scaleY(1);
      }
    }

    .block-input {
      z-index: 399;
      position: relative;
      display: none !important;

      &.active {
        display: flex !important;
        padding: 10px 16px;
        height: fit-content;
        transform: scaleY(1);
      }
    }

    & .order-data__dropdown-selector {
      background: #F5F5F7;
    }

    & .order-data__address-delivery, .block-input {
      display: flex;
      justify-content: space-between;
      max-width: 740px;

      &.active {
        padding: 10px 30px 24px 30px;
      }

      & .form__label {
        & .form__input {
          width: 100%;
        }
      }

      & .form__label:first-child {
        max-width: 334px;
        width: 334px;
      }

      & .form__label:not(:first-child) {
        max-width: 100px;
      }
    }
  }

  // .order-data__dropdown-selector

  &__dropdown-selector {
    border: 1px solid $second-grey;
    position: relative;
    width: 334px;
    min-width: 334px !important;
    margin-bottom: 0;
    margin-left: 30px;

    &.active {
      overflow: visible;
      margin-bottom: 24px;
      z-index: 6;
    }
  }

  // .order-data__dropdown-selector--city

  &__dropdown-selector--city {
    z-index: 9 !important;
    width: fit-content;
    margin-left: 0;
    transform: scaleY(1) !important;
    padding: 10px 16px !important;
    height: fit-content !important;
    margin-bottom: 20px !important;
  }

  // .order-data__select-input

  &__select-input {
    @include main-text;
    width: 282px;
    background: #F5F5F7;
  }

  // .order-data__dropdown

  &__dropdown {
    position: absolute;
    top: 41px;
    left: 0;
    right: 0;
    width: 100%;
    background: $white;
    z-index: 1;
    max-height: 200px;
    overflow-y: auto;
    @include scroll-style;
    transform: scaleY(0);
    transform-origin: top;
    transition: .3s;

    &.active {
      position: absolute;
      transform: scaleY(1);
      background: $white;
      box-shadow: 0 20px 40px rgba(219, 226, 234, 0.7), 0 7.10219px 33.4221px rgba(219, 226, 234, 0.0503198), 0 3.79717px 17.869px rgba(219, 226, 234, 0.0417275), 0 2.12866px 10.0172px rgba(219, 226, 234, 0.035), 0 1.13052px 5.32008px rgba(219, 226, 234, 0.0282725), 0 0.470434px 2.21381px rgba(219, 226, 234, 0.0196802);
    }
  }

  // .order-data__dropdown-item

  &__dropdown-item {
    cursor: pointer;
    width: 100%;
    @include main-text;
    padding: 10px 16px;
    position: relative;

    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        height: 1px;
        background: $second-grey;
        width: calc(100% - 32px);
      }
    }

    &:hover {
      background: $label;
      color: $second;
    }
  }

  // .order-data__post

  &__post {
  }

  // .order-data__delivery-price

  &__delivery-price {
    margin-left: auto;
    @include main-text;
  }

  // .order-data__address-delivery

  &__address-delivery {
  }

  // .order-data__delivery-price--free

  &__delivery-price--free {
  }

  // .order-data__payment-form

  &__payment-form {
    h6 {
      color: #868686;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      margin-bottom: 14px;
    }

    .payment-item-content {
      display: flex;
      justify-content: space-between;

      label {
        width: 50%;

        img {
          margin-right: 8px;
        }

        svg {
          margin-right: 8px;
        }
      }
    }

    & .radio-wrap__drop-section {
      margin-left: 34px;

      & .form__label {
        .standart {
          width: max-content;
          top: 40px;
        }

        & .form__input {
          margin-left: 0;

          &:focus ~ span, &:not(:focus):valid ~ span, &:not(:placeholder-shown) ~ span {
            top: 0;
            font-size: 12px;
          }
        }
      }
    }

    & .radio-wrap {
      margin-bottom: 16px;

      & .radio {
        @include main-text;
      }
    }

    & .radio-wrap__drop-section-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      margin-bottom: 19px;
    }

    & .radio-wrap__drop-section {
      margin-bottom: 0;
      // height: 0;
      // overflow: hidden;
      // transform: scaleY(0);
      // transform-origin: top;
      // &.active{
      //     padding: 10px 16px;
      // }
      height: 0;
      overflow: visible;
      transform: scaleY(0);
      transition: transform .3s;

      &.active {
        margin-bottom: 24px;
        height: fit-content;
        transform: scaleY(1);
        transform-origin: top;
      }

      & input {
        display: block;
        border: 1px solid $second-grey;
        padding: 10px 16px;
        max-width: 500px;
        width: 100%;
        @include main-text;
        margin-left: 34px;

        &:not(:last-child) {
          margin-bottom: 18px;
        }
      }
    }

  }
}

.form {

  // .form__label

  &__label {
  }

  // .form__input

  &__input {
  }
}

.arrow-down {
}

.radio-wrap {

  // .radio-wrap__drop-section

  &__drop-section {
    // margin-top: 60px;
  }

  &__drop-section-title {

  }

  // .radio-wrap__drop-section-descr

  &__drop-section-descr {
    font-size: 12px;
    line-height: 140%;
    margin-bottom: 24px;
  }
}

.radio {
}

.street {
}

.building {
}

.apartment {
}

.floor {
}

.credit-item {
  display: flex;

  & .term-picker {

  }

  &:not(:last-child) {
    margin-bottom: 19px;
  }

  // .credit-item__price

  &__price {
    @include subtitle-small;
    margin: 0 auto;
  }

  & .radio-label {
    display: flex;
    align-items: center;
    max-width: 220px;
  }

  & .radio-label__text {
    @include main-text;
    margin-left: 12px;
  }

  // .credit-item__conditions

  &__conditions {
    max-width: 162px;
    text-align: center;
    @include secondary-text;
    margin-left: auto;
  }
}

.radio-label {

  // .radio-label__text

  &__text {
  }
}

.term-picker {
  position: relative;
  margin: 0 auto;
  // .term-picker__choosed
  &.active {
    & .arrow-down {
      transform: rotate(180deg);
    }

    & .term-picker__dropdown {
      transform: scaleY(1);
    }
  }

  &__choosed {
    border: 1px solid $second-grey;
    display: flex;
    justify-content: space-between;
    padding: 4px 8px 4px 16px;
    width: 122px;

    & .term-picker__item {
      @include main-text;
    }
  }

  // .term-picker__item

  &__item {
    @include main-text;
  }

  // .term-picker__dropdown

  &__dropdown {
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    box-shadow: 0 20px 40px rgba(219, 226, 234, 0.7), 0 7.10219px 33.4221px rgba(219, 226, 234, 0.0503198), 0 3.79717px 17.869px rgba(219, 226, 234, 0.0417275), 0 2.12866px 10.0172px rgba(219, 226, 234, 0.035), 0 1.13052px 5.32008px rgba(219, 226, 234, 0.0282725), 0 0.470434px 2.21381px rgba(219, 226, 234, 0.0196802);
    z-index: 1;
    transform-origin: top;
    transform: scaleY(0);
    transition: .3s;

    & .term-picker__item {
      padding: 9px 16px 10px;
      background: $white;
      cursor: pointer;

      &:not(:last-child) {
        border-bottom: 0.5px solid $second-grey;
      }

      &:hover {
        background: $label;
        color: $second;
      }
    }
  }
}

.order-basket {
  width: 33%;
  background: $white;
  padding: 18px 24px;
  min-width: 384px;
  height: fit-content;
  position: sticky;
  top: 80px;
  // .order-basket__title

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
  }

  // .order-basket__products-section

  &__products-section {
    padding: 10px;
    background: #F5F5F7;
  }

  // .order-basket__product

  &__product {
    display: flex;
    border-bottom: 1px solid $second-grey;
    padding: 16px 0;
    flex-direction: column;

    &:last-child {
      border-bottom: none
    }
  }

  // .order-basket__product-body

  &__product-body {
    display: flex;
    align-items: center;
  }

  // .order-basket__product-img

  &__product-img {
    width: 117px;
    height: 80px;
    margin-right: 14px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      width: 100%;
      object-fit: cover;
    }
  }

  // .order-basket__product-data

  &__product-data {
    width: 250px;
  }

  // .order-basket__product-name

  &__product-name {
    font-size: 12px;
    line-height: 150%;
    margin-bottom: 4px;
  }


  &__product-name-services {
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
  }

  // .order-basket__product-price

  &__product-price {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 4px;
  }

  // .order-basket__quantity

  &__quantity {
    font-size: 12px;
    line-height: 150%;
  }

  // .order-basket__price-data

  &__price-data {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 16px;
    margin-top: 16px;
  }

  // .order-basket__price-item

  &__price-item {
    display: flex;
    margin-bottom: 5px;
    align-self: flex-end;
    align-items: center;
  }

  // .order-basket__price-item-title

  &__price-item-title {
    text-align: right;
    @include main-text;
  }

  // .order-basket__price-item-val

  &__price-item-val {
    width: 180px;
    min-width: 100px;
    margin-left: 16px;
    text-align: right;
    margin-top: auto;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
  }

  // .order-basket__price-item--cashback

  & .item-cashback {
    color: #FB8200;
    text-decoration: underline;
    font-weight: 700;
  }

  // .order-basket__price-item--total

  &__price-item--total {
    margin-top: 11px;

    & .order-basket__price-item-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
    }

    & .order-basket__price-item-val {
      @include subtitle;
    }
  }

  // .order-basket__confirm-btn

  &__confirm-btn {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  & .filter__form {
    & .checkbox {
      &::before {
        align-self: flex-start;
        margin-top: 4px;
      }

      & img {
        align-self: flex-start;
        margin-top: 9px;
      }

      & .input-text {
        font-size: 12px;
      }

      & .input-text-link {
        text-decoration-line: underline;
        color: $main;
        margin-left: 2px;
      }
    }
  }

  // .order-basket__product-services

  &__product-services {
    margin-left: 20px;
    border-top: 1px solid $second-grey;
    padding-top: 10px;
  }

  // .order-basket__product-service

  &__product-service {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .basket-item__delete-service-btn {
      margin-top: 4px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: .3s;
      color: white;
      background: transparent;

      svg {
        margin-right: 8px;
      }

      &.active {
        svg {
          transform: rotate(45deg)
        }
      }
    }
  }

  // .order-basket__service-name

  &__service-name {
    font-size: 12px;
    line-height: 150%;
    margin-bottom: 4px;
    width: 55%;
  }

  // .order-basket__service-price

  &__service-price {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
  }
}

.filter {

  // .filter__form

  &__form {
  }
}

.checkbox-label {
}

.checkbox {
}

.input-text {
}

.input-text-link {
}

@media only screen and (max-width: 1580px) {
  .order-data {
    width: fit-content;
  }
}

@media only screen and (max-width: 1340px) {
  .order-data {
    max-width: 950px;
    width: 100%;
    margin: 0 auto 100px;

    &__login-section {
      margin-left: 0;
      margin-bottom: 24px;
    }

    &__section--double {
      // flex-direction: column-reverse;
      justify-content: space-between;
    }

    &__login-section {
      margin-left: auto;
    }
  }
  .order-page {
    &__body {
      flex-direction: column;
      margin-bottom: 70px;
    }
  }
  .order-basket {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 976px) {
  .order-data__dropdown-selector {
    margin-left: 0;
  }
  .inline-input {
    width: 100% !important;

    .order-data__dropdown-selector--city {
      margin-left: 0 !important;
    }
  }
  .block-input {
    .order-data__dropdown-selector--city {
      margin-left: -10px !important;
    }
  }
  .order-basket {
    margin: 0 auto 70px;
    padding: 16px 20px;

    &__price-data {
      margin-bottom: 16px;
    }

    &__price-item-val {
      font-size: 14px;
    }

    &__price-item-title {
      font-size: 12px;
    }

    &__price-item-val {
      font-weight: 600;
      font-size: 16px;
    }

    &__price-item--total {
      margin-top: 0;
    }

    &__product-img {
      width: 92px;
      height: 63px;
    }

    &__product {
      padding: 8px 0;
    }

    &__price-item-title {
      font-size: 12px;
    }

    &__confirm-btn {
      font-size: 14px;
      margin-bottom: 6px;
    }

    & .input-text {
      font-size: 10px !important;
    }

    .basket-item__delete-service-btn {
      width: auto;
      background: transparent;

      font-size: 12px;
    }
  }
  .order-data {
    &__dropdown-selector--city {
      padding: 8px 16px !important;
      margin-left: -10px;
    }

    &__section-title {
      font-size: 16px;
      margin-bottom: 16px;
      font-weight: 600;
    }

    &__login-title {
      font-size: 14px;
      margin-bottom: 3px;
    }

    &__post {
      font-size: 12px !important;
      font-weight: 400 !important;
    }

    &__login-subtitle {
      font-size: 12px;
      margin-bottom: 12px;
    }

    &__form-subtitle {
      font-size: 10px;
    }

    &__login-btn {
      font-size: 12px;
      padding: 8px 32px;
    }

    &__dropdown {
      z-index: 5;
      top: 38px;
    }

    &__dropdown-selector.active {
      padding: 8px 10px !important;
    }

    &__dropdown-item {
      padding: 8px 16px;
      font-size: 12px;
    }

    &__select-input {
      font-size: 12px;
      height: 18px;
    }

    & .form__label {
      margin-bottom: 10px;

      & span {
        font-size: 12px;

        &:not(:first-child) {
          margin-left: -10px;
        }
      }

      & .form__input {
        height: 38px;

        &:focus ~ span, &:not(:focus):valid ~ span, &:not(:placeholder-shown) ~ span {
          font-size: 10px;
        }
      }
    }

    &__delivery-form {
      & .radio-wrap {
        margin-bottom: 12px;
      }

      .form__input {
        margin-bottom: 20px !important;
        margin-left: -10px;
      }
    }

    &__payment-form {
      & .radio-wrap {
        margin-bottom: 12px;
      }

      & .radio-wrap__drop-section {
        margin-left: 0;
      }
    }

    &__section {
      padding: 20px 10px;
    }
  }
  .credit-item {
    border: 1px solid $second-grey;
    padding: 12px 8px;
    flex-wrap: wrap;

    & .radio-wrap {
      margin-bottom: 12px !important;
    }

    & .radio-label {
      & img {
        width: 24px;
        height: 24px;
      }

      &__text {
        font-size: 12px;
        max-width: 225px;
      }
    }
  }
  .order-data {
    &__delivery-price {
      font-size: 12px;
    }
  }
  .order-page {
    &__hotline-title {
      font-size: 12px;
    }

    &__hotline {
      margin-bottom: 2px;
    }

    &__hotline-number {
      font-size: 12px;
    }

    &__worktime-days, &__worktime-time {
      font-size: 12px;
    }

    &__worktime-item {
      font-size: 12px;
      line-height: 140%;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }

    &__logo {
      width: 72px;
      height: 32px;
    }

    &__header {
      margin-top: 20px;
      align-items: flex-start;
    }

    & .page-title {
      margin: 20px 0;
    }
  }
}

@media only screen and (max-width: 758px) {
  .order-page .inline-input.active {
    display: block;
  }
  .order-data {
    &__section--double {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    &__login-section {
      max-width: 370px;
      margin-left: 0;
    }

    &__user-form {
      & .form__label {
        width: 100%;
      }

      & .form__input {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 628px) {
  .order-basket {
    &__products-section {
      background: white;
    }

    &__product-service {
      display: grid;
      grid-template-areas:
        "service-name delete-button"
        "service-price delete-button";
    }

    &__service-name {
      width: 80%;
      grid-area: service-name;
    }

    &__service-price {
      grid-area: service-price;
    }

    & .basket-item__delete-service-btn {
      grid-area: delete-button;
      justify-self: center;
    }
  }

  .order-page {
    & .product-footer-block {
      display: grid;
      grid-template-areas:
        "price-block price-block"
        "counter delete-btn";

      margin-bottom: 10px;

      & .price-block {
        grid-area: price-block;
      }

      & .basket-item {
        &__counter {
          grid-area: counter;
        }

        &__delete-btn {
          grid-area: delete-btn;
          align-self: center;

          & svg {
            height: 30px;
            width: 30px;
          }
        }
      }
    }
  }
  .order-data {
    &__address-delivery {
      flex-wrap: wrap;

      & .form__label.street {
        max-width: 100% !important;
        width: 100% !important;
      }

      & .form__label:not(:first-child) {
        width: 32%;
        max-width: 32% !important;
      }
    }
  }
}

@media only screen and (max-width: 520px) {
  .order-data {
    &__delivery-form {
      & .radio-wrap {
        flex-wrap: wrap;
      }
    }

    &__delivery-price {
      width: 100%;
      margin-top: 5px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .credit-item {
    align-items: center;
    flex-wrap: wrap;
    min-height: 50px;
    transition: .3s;

    &.active {
      max-height: 250px;
    }

    &__price {
      width: 100%;
      margin-left: 28px;
      margin-bottom: 4px;
      font-size: 12px;
    }

    & .term-picker {
      margin-left: 28px;
      margin-bottom: 10px;
    }

    &__conditions {
      max-width: 100%;
      margin-left: 28px;
      text-align: left;
    }

    & .radio-wrap {
      margin-bottom: 0;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 410px) {
  .order-page {
    &__footer {
      & .container {
        height: fit-content;
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__footer-icons {
      align-self: flex-start;
    }
  }
  .order-data {
    &__dropdown-selector {
      width: 100%;
      margin-left: 0;
      min-width: auto !important;

      & .order-data__select-input {
        width: 93%;
      }
    }
  }
}

@media (max-width: 390px) {
  .order-basket {
    min-width: 100%;
  }
  .credit-item {
    & .radio-label__text {
      overflow-y: visible;
      max-width: 200px;
      height: 20px;
      align-self: center;
      transform: translateY(-8px);
    }
  }
}

@media only screen and (max-width: 375px) {
  .order-data {
    &__dropdown-selector {
      width: 100%;
      min-width: auto !important;

      & .order-data__select-input {
        width: 90%;
      }
    }
  }
}
