@import '../../scss/reset/reser-style';

.basket-page {
    .basket {
        display: flex;
        justify-content: space-between;
        width: 100%;
        min-width: auto;
        margin-bottom: 85px;
            // .basket__items-wrap

            &__items-wrap {
                width: 100%;
            }
    }
}
.page-title {
}

.basket-item {
    width: 100%;
    padding: 24px;
    background: $white;
    display: flex;
    flex-direction: column;
    &__product{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    &:not(:last-child){
        margin-bottom: 32px;
    }
		// .basket-item__link

		&__link {
            display: flex;
            align-items: center;
            width: 526px;
		}

		// .basket-item__img

		&__img {
            height: 94px;
            margin-right: 56px;
            & img{
                height: 100%;
                object-fit: cover;
            }
		}

		// .basket-item__name

		&__name {
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
		}

		// .basket-item__counter

		&__counter {
            display: flex;
            height: 29px;
            align-items: center;
            border: 1px solid $second-grey;
            margin-top: 10px;
		}
        
		// .basket-item__counter-val

		&__counter-val {
            width: 20px;
            text-align: center;
        }
        
        &__counter-dec, &__counter-inc{
            cursor: pointer;
            & svg{
                transition: .2s;
            }
            &:hover{
                & svg{
                    fill: black;
                }
            }
            &:active{
                & svg{
                    transform: scale(0.8);
                }
            }
        }
		// .basket-item__item-pricing

		&__item-pricing {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
		}

		// .basket-item__item-sale

		&__item-sale {
            display: flex;
            margin-bottom: 6px;
		}

		// .basket-item__item-old-price

		&__item-old-price {
            font-size: 14px;
            line-height: 150%;
            text-decoration-line: line-through;
            color: $grey;
            margin-right: 16px;
		}

		// .basket-item__item-price-percentage

		&__item-price-percentage {
            font-size: 12px;
            line-height: 150%;
            color: $second;
            background: #E0F8D4;
            padding: 2px 16px;
		}

		// .basket-item__item-price

		&__item-price {
            font-weight: 600;
            font-size: 24px;
            line-height: 150%;
            margin-bottom: 8px;
		}

		// .basket-item__delete-btn

		&__delete-btn {
            font-size: 12px;
            line-height: 150%;
            color: $grey;
            background: none;
            cursor: pointer;
            display: flex;
            align-items: center;
		}
}
.basket-result {
    margin-left: 30px;
    width: 300px;
    min-width: 300px;
    background: $white;
		// .basket-result__body

		&__body {
            padding: 24px 10px 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-bottom: 1px solid $second-grey;
		}

		// .basket-result__title

		&__title {
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            margin-bottom: 24px;
		}

		// .basket-result__item-sale

		&__item-sale {
            display: flex;
		}

		// .basket-result__item-old-price

		&__item-old-price {
            font-size: 14px;
            line-height: 150%;
            text-decoration: line-through;
            text-align: center;
            margin-right: 14px;
            color: $grey;
		}

		// .basket-result__item-price-economy

		&__item-price-economy {
            font-size: 12px;
            line-height: 150%;
            color: $second;
            background: #E0F8D4;
            padding: 2px 16px;
		}

		// .basket-result__price

		&__price {
            font-weight: 600;
            font-size: 30px;
            line-height: 150%;
            margin-bottom: 16px;
            max-width: 80%;
		}

		// .basket-result__cashback

		&__cashback {
            display: flex;
            align-items: center;
            & img{
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }
            & .cashback-text{
                font-size: 14px;
                line-height: 150%;
                margin-right: 4px;
            }
            & .cashback-val{
                font-weight: 600;
                font-size: 14px;
                line-height: 150%;
                text-decoration-line: underline;
                color: #FB8200;
            }
		}

		// .basket-result__footer

		&__footer {
            display: flex;
            flex-direction: column;
            // justify-content: center;
            align-items: center;
            padding: 40px 8px 24px 8px;
		}

		// .basket-result__confirm-btn

		&__confirm-btn {
            background: $main;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: $white;
            padding: 12px 16px;
            cursor: pointer;
            transition: .3s;
            margin-bottom: 16px;
            width: fit-content;
            &:hover{
                background: $second;
            }
        }
        
        // .basket-result__credit-buy-btn 

        &__credit-buy-btn {
            padding: 12px 45px;
            background: $second;
            color: $white;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            cursor: pointer;
            transition: .3s;
            width: fit-content;
            &:hover{
                background: #124D13;
            }
        }

}
.basket-services {
    width: calc(100% - 196px);
    align-self: flex-end;
    border-top: 1px solid #DCDCDC;
    margin-top: 18px;
    padding-top: 18px;
}
.basket-service-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:not(:last-child){
        margin-bottom: 10px;
    }
		// .basket-service-item__name

		&__name {
            font-size: 14px;
            line-height: 150%;
		}

		// .basket-service-item__price

		&__price {
            margin-right: 40px;
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
            margin-left: auto;
		}

		// .basket-service-item__delete-btn

		&__delete-btn {
            display: block;
            cursor: pointer;
            
        }
        &__price-wrap{
            display: flex;
        }
}


@media only screen and (max-width: 1195px) {
    .basket-item{
        &__product{
            flex-wrap: wrap;
            justify-content: flex-end;
        }
        &__link{
            width: 100%;
            justify-self: flex-start;
        }
    }
    .basket-services{
        width: 100%;
    }
}

@media only screen and (max-width: 976px) {
    .basket-page{
        & .page-title{
            margin-bottom: 24px;
        }

        & .basket-item {
          &__delete-btn{
            font-size: 0;
            width: 24px;
            height: 24px;
            // background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 8.41L15.59 7L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41Z' fill='%23868686'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position-x: center;
            background-position-y: center;
            position: absolute;
            top: 0;
            right: 0;
          }
        }
    }
    .basket-page {
        .basket{
            flex-direction: column;
            margin-bottom: 40px;
        }
    }
    .basket-item{
        position: relative;
        padding: 16px 8px;
        &:not(:last-child){
            margin-bottom: 20px;
        }
        &__product{
            justify-content: space-between !important;
        }
        &__link{
            width: fit-content !important;
        }
        &__name{
            min-width: 304px;
            width: 304px;
            font-size: 14px;
        }
        &__img{

        }
        &__counter{
            height: 24px;
        }
        &__counter-inc{
            & svg{
                height: 100%;
                width: 20px;
            }
        }
        &__counter-dec{
            & svg{
                height: 100%;
                width: 20px;
            }
        }
        &_counter-val{
            font-size: 12px;
        }
        &__item-old-price{
            font-size: 12px;
        }
        &__item-price{
            font-size: 16px;
        }

    }
    .basket-service-item{
        &__name{
            font-size: 12px;
        }
        &__price{
            font-size: 12px;
            display: flex;
            align-items: center;
        }
        &__delete-btn{
            width: 24px;
            height: 24px;
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.00031 4.29307L1.46481 0.757568L0.757812 1.46457L4.29331 5.00007L0.757812 8.53557L1.46481 9.24257L5.00031 5.70707L8.53581 9.24257L9.24281 8.53557L5.70731 5.00007L9.24281 1.46457L8.53581 0.757568L5.00031 4.29307Z' fill='%23868686'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position-x: center;
            background-position-y: center;
            & svg{
                display: none;
            }
        }
    }

    .basket-result{
        margin: 0 auto;
        margin-top: 20px;
        &__body{
            padding: 20px 0;
        }
        &__title{
            font-size: 14px;
            margin-bottom: 18px;
        }
        &__item-sale{
            margin-bottom: 8px;
        }
        &__item-old-price{
            font-size: 12px;
        }
        &__price{
            font-size: 18px;
            margin-bottom: 18px;
        }
        &__cashback{
            & .cashback-tex, & .cashback-val{
                font-size: 12px;
            }
        }
        &__footer{
            padding: 20px 0px;
        }
        &__confirm-btn{
            margin-bottom: 10px;
        }
        &__confirm-btn, &__credit-buy-btn{
            font-size: 12px;
            width: 215px;
            height: 34px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
        }
    }
    .basket-services{
        margin-top: 16px;
        padding-top: 16px;
    }
}



@media only screen and (max-width: 739px) {
    .basket-item{
        &__link{
            order: 1;
            width: 100% !important;
        }
        &__counter{
            order: 3;
        }
        &__item-pricing{
            order: 2;
            margin-top: 16px;
            align-items: flex-start;
        }
        &__name{
            width: calc(100% - 140px);
        }
    }
}


@media only screen and (max-width: 535px) {
    .basket-item{
        &__name{
            min-width: auto;
        }
    }
}

@media only screen and (max-width: 465px) {
    .basket-item{
        &__link{
            flex-direction: column;
        }
        &__img{
            margin-right: 0;
        }
        &__name{
            text-align: center;
            width: 100%;
        }
    }
    .basket-service-item{
        &__price{
            margin-right: 16px;
        }
    }
}
