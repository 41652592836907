body {
  overflow-x: hidden;
}
.bx-livechat-mobile.bx-livechat-wrapper {
  height: 80% !important;
}
.logoNew {
  svg {
    max-width: 184px;
    width: 100%;
  }
}
@import "reset/reser-style";
