@import "src/scss/style";

.order-page {

}
.order-result {
    & .page-title{
        margin-bottom: 60px;
    }
    // .order-result__body

    &__body {
        padding: 40px;
        max-width: 850px;
        margin: 0 auto;
        background: #FFFFFF;
        margin-bottom: 20px;
    }

    // .order-result__thanks-text

    &__thanks-text {
        font-weight: 600;
        font-size: 36px;
        line-height: 150%;
        text-align: center;
        margin-bottom: 30px;
    }

    // .order-result__number-text

    &__number-text {
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        margin-bottom: 40px;
    }

    // .order-result__info-section

    &__info-section {
        margin-bottom: 40px;
        & .order-result__title {
            margin-bottom: 34px;
        }
    }

    // .order-result__title

    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
        text-align: center;
    }

    // .order-result__info-items

    &__info-items {
    }

    // .order-result__info-item

    &__info-item {
        display: flex;
        &:not(:last-child){
            margin-bottom: 18px;
        }
    }

    // .order-result__info-name

    &__info-name {
        min-width: 210px;
        font-size: 16px;
        line-height: 150%;
        margin-right: 48px;
    }

    // .order-result__info-val

    &__info-val {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
    }

    // .order-result__products

    &__products {
    
        & .order-result__title{
            margin-bottom: 8px;
        }
    }

    // .order-result__products-wrap

    &__products-wrap {
        margin-bottom: 24px;
    }

    // .order-result__product

    &__product {
        padding: 18px 0;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid $second-grey;
    }

    // .order-result__product-body
    
    &__product-body{
        display: flex;
        align-items: center;
    }

    // .order-result__product-info

    &__product-info{
        width: calc(100% - 137px);
        display: flex;
        justify-content: space-between;
    }

    // .order-result__product-link

    &__product-link {
        display: flex;
        align-items: center;
    }

    // .order-result__product-img

    &__product-img {
        width: 117px;
        margin-right: 20px;
        & img{ 
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    // .order-result__product-name

    &__product-name {
        font-size: 14px;
        line-height: 150%;
    }

    // .order-result__product-price

    &__product-price {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        min-width: max-content;
    }

    // .order-result__product-quantity

    &__product-quantity {
        font-size: 14px;
        line-height: 150%;

    }

    // .order-result__pricing

    &__pricing {
    }

    // .order-result__pricing-item

    &__pricing-item {
        display: flex;
        margin-bottom: 15px;
        align-items: center;
    }

    // .order-result__pricing-name

    &__pricing-name {
        text-align: right;
        font-size: 16px;
        line-height: 150%;
        width: 50%;
        padding-right: 10px;
    }

    // .order-result__pricing-val

    &__pricing-val {
        text-align: left;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        width: 50%;
        padding-left: 10px;
    }

    // .order-result__pricing-item--bold

    &__pricing-item--bold {
        
        & .order-result__pricing-name {
            font-weight: 600;
        }
    
        & .order-result__pricing-val {
            font-weight: bold;
            font-size: 18px;
        }
    
    }

    // .order-result__back-link

    &__back-link {
        display: flex;
        align-items: center;
        padding: 12px 40px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: $white;
        background: $second;
        width: fit-content;
        margin: 0 auto 100px auto;
        & svg{
            margin-right: 8px;
        }
    }

    // .order-result__product-services

    &__product-services {
        margin-left: 137px;
        border-top: 1px solid $second-grey;
        padding-top: 14px;
    }

    // .order-result__service-item

    &__service-item {
        display: flex;
        justify-content: space-between;
        &:not(:last-child){
            margin-bottom: 9px;
        }
    }

    // .order-result__service-name

    &__service-name {
        font-size: 12px;
        line-height: 150%;
    }

    // .order-result__service-price

    &__service-price {
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
    }
}

@media only screen and (max-width: 976px) {
.order-result {

    & .page-title{
        margin-bottom: 24px;
    }

    // .order-result__thanks-text

    &__thanks-text {
        font-size: 16px;
        margin-bottom: 14px;
    }

    // .order-result__number-text

    &__number-text {
        font-size: 16px;
        margin-bottom: 24px;
    }

    // .order-result__body

    &__body {
        padding: 20px;
    }

    // .order-result__product-info

    &__product-info{
        width: calc(100% - 100px);
        display: flex;
        justify-content: space-between;
    }

    // .order-result__info-section

    &__info-section {
        margin-bottom: 20px;
    }

    // .order-result__title

    &__title {
        font-size: 16px;
        margin-bottom: 16px !important;
    }

    // .order-result__info-items

    &__info-items {
    }

    // .order-result__info-item

    &__info-item {
        flex-direction: column;
        &:not(:last-child){
            margin-bottom: 16px;
        }
    }

    // .order-result__info-name

    &__info-name {
        margin-bottom: 6px;
        font-size: 12px;
    }

    // .order-result__info-val

    &__info-val {
        font-size: 12px;
    }

    // .order-result__products

    &__products {

    }

    // .order-result__products-wrap

    &__products-wrap {
    }

    // .order-result__product

    &__product {
        padding: 0;

    }

    // .order-result__product-link

    &__product-link {
    }

    // .order-result__product-img

    &__product-img {
        width: 92px;
        height: 63px;
        margin-right: 8px;
    }

    // .order-result__product-name

    &__product-name {
        font-size: 12px;
    }

    // .order-result__product-price

    &__product-price {
        font-size: 14px;
    }

    // .order-result__product-quantity

    &__product-quantity {
        font-size: 12px;
    }

    // .order-result__pricing

    &__pricing {
    }

    // .order-result__pricing-item

    &__pricing-item {
    }

    // .order-result__pricing-name

    &__pricing-name {
        font-size: 12px;
    }

    // .order-result__pricing-val

    &__pricing-val {
        font-size: 14px;
    }

    // .order-result__pricing-item--bold

    &__pricing-item--bold {
        & .order-result__pricing-val {
            font-size: 16px;
        }
    }

    // .order-result__back-link

    &__back-link {
        padding: 10px 32px;
        font-size: 14px;
    }

    // .order-result__product-services

    &__product-services {
        padding-bottom: 14px;
        margin-left: 100px;
    }

    // .order-result__service-item

    &__service-item {
    }

    // .order-result__service-name

    &__service-name {
    }

    // .order-result__service-price

    &__service-price {
    }
}
.container {
}
.page-title {
}

}

@media only screen and (max-width: 596px) {
    .order-result{
        &__product-body{
            padding: 8px 0;
        }
        &__product-info{
            flex-direction: column;
        }
        &__service-item{
            flex-direction: column;
        }
    }
}