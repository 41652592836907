$desktopWidth            : 1280px;
$smDesktopWidth          : 980px;
$tableWidth              : 768px;
$phoneWidth              : 480px;
$smPhoneWidth            : 320px;


//=========================================================================== LIKA COMFORT Color Scheme
$main                    : #43B02A;
$second                  : #257226;
$dark                    : #183029;
$black                   : #182316;
$bg                      : #FCFCFD;
$grey                    : #868686;
$second-grey             : #DCDCDC;
$white                   : #FFFFFF;
$label                   : #E0F8D4;
$orange                  : #FB8200;
$error                   : #DB4915;