@import "src/scss/style";

.sitemap {
    display: flex;
    div {
        margin-top: 15px;
    }
    .row {
        margin-bottom: 20px;
        padding-left: 15px;
        width: 50%;
        h2 {
            padding-top: 30px;
        }
    }

}