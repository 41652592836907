@import '../../../scss/reset/vars';
@import '../../../scss/reset/reser-style';

.mainpartner-section {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-top: 30px;
    // .mainpartner-section__item

    &__item {
        height: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $white;
        transform: translateY(0);
        backface-visibility: hidden;
        box-shadow: 0px 20px 50px rgba(0, 0, 0, 0);
        transition: .3s;
        cursor: pointer;
        &:hover{
            box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
            transform: translateY(-2%);
        }
        img {
            height: 100%;
        }
    }
}

@media only screen and (max-width: 800px) {
    .mainpartner-section{
        display: none;
    }
}