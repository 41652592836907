.contacts {
  position: relative;
  height: 100%;
  min-height: 850px;
  @media (max-width: 320px) {
  min-height: 700px;
  }
  @media (max-width: 1024px) {
    min-height: 700px;
  }
  &__block {
    padding: 40px 40px 7%;
    width: 100%;
    max-width: 330px;
    max-height: 850px;
    background-color: #fff;
    position: absolute;
    left: 10%;
    bottom: 0;
    top: 20%;
    z-index: 5;
    transition: all 0.3s ease;


    &-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
    }

    &--toggle{
      display: none;
    }
  }

  &__block_head {
    font-size: 36px;
    border-left: 5px solid #43B02A;
    padding-left: 5px;
    font-weight: 600;
  }
  &__block_headers {
    margin-bottom: 20px;
  }
  &__block_text {
    font-weight: 600;
    font-size: 16px;
  }
  &__block_link {
    font-weight: 400;
    font-size: 14px;
    margin-top: 10px;
    color: #43B02A;
    display: block;
  }
  &__forms {
    width: 100%;
    max-width: 675px;
    height: 100%;
    max-height: 850px;
    position: absolute;
    left: 7%;
    bottom: 0;
    top: 0;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.3s linear;
    &--padding{
      padding: 60px 30px 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 27%;
      max-height:275px;
      background-color: #fff;

    }
  }
  &__forms_info {
    display: flex;
    justify-content: flex-start;
    margin-left: 60px;
    & > :first-child {
      margin-right: 60px;
    }
  }
  &__forms_ico {
    display: flex;
   & > img {
     margin-bottom: 20px;
     margin-left: -30px;
     margin-right:10px;
   }
  }
  &__form {
    background-color: #183029;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60%;

    &__content {
      display: flex;
      flex-direction: column;
      justify-content:center;
      align-items: center;
      & > input {
        margin-bottom: 35px;
      }
    }

    &__head {
      color: #fff;
      font-size: 24px;
      font-weight: 600;
      padding: 0 40px;
      text-align: center;
      margin-bottom: 40px;
    }
    &__input__wrapper{
      width: 100%;
      max-width: 440px;
      margin-bottom: 35px;
      position: relative;
    }

    &__input {
      height: 30px;
      background-color: #183029;
      border-bottom: 1px solid #fff;
      width: 100%;
      max-width: 440px;
      color: #fff;
      position: relative;
    }

    &__btn {
      width: 240px;
      margin: 50px auto 0;
    }
    &__streetAndHome {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 440px;
      width: 100%;
      &--lg {
       width: 230px;
        position: relative;
      }
      &--md {
        width: 82px;

      }
      &--sm {
        width: 85px;

      }
    }
  }
  .contacts__forms__toggle {
    display:none;
  }
 &__dropdown-selector {
   padding-top: 10px;
   width: 100%;
  position: absolute;
   z-index: 1;
   background: #fff;
  &--hidden {
    display: none;
  }
   & > div {
     height: 30px;
     padding: 5px 10px;
     cursor: pointer;
     &:hover {
       background-color: #e0f8d4;
     }
   }
 }
.street__text {
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
}
  @media (max-width: 1400px) {
    .contacts__forms__toggle{
      display:block;
      position: fixed;
      right: -4.4%;
      top: 50%;
      transform: translateY(-50%);
      height: 250px;
      width: 30px;
      background-color: #43B02A;
      color: #fff;
      text-align: center;
      z-index: 5;
      padding: 5px;
      writing-mode: tb-rl;
      &--block {
        display: none;
        height: 60%;
        @media (max-width: 800px) {
         display: block;
          right: -9%;
        }
      }

    }
    .gm-style-mot {
      width: 200px;
      display: block;
      margin:  0px auto;
    }
    &__forms {
      position: absolute;
      transform: translateX(-108%);
      right: 100%;
      &--active{
        transform: translateX(0);
      }
      @media  (max-width: 800px) {
        transform: translateX(-106%);
        &--active{
          transform: translateX(-5%);
        }
      }
      @media  (max-width: 600px) {
        transform: translateX(-105%);
        &--active{
          transform: translateX(-5%);
        }
      }
    }
  }

  @media (max-width: 800px) {
    .contacts__form__content > input {
      margin-bottom:  20px;
    }
    .contacts__form__input__wrapper {
      margin-bottom:  10px;
    }
    .contacts__form__btn {
      margin-top: 25px;
    }
    .contacts__block {
      position: absolute;
      transform: translateX(-120%);
      right: 100%;
      &--active{
        transform: translateX(0);
      }
    }
    &__forms {
      max-width: 90%;

      justify-content: normal;
      &--padding {
        padding: 25px ;
        height: 100%;
      }
      &_info {
        flex-direction: column;
        margin-left: 30px;
      }
      .contacts__phones-wrapper {
        font-size: 12px;
        max-width: 350px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
      }
      .contacts__block_phones {
        margin-right: 0;
        margin-top: 20px;
        padding-right: 15px;
      }
      .contacts__form__head {
        text-align: left;
        font-size: 18px;
        padding: 0;
      }
      .contacts__form__content {
        justify-content: flex-start;
      }
      .contacts__form {
        padding: 20px;
        height: 100%;
          &__streetAndHome {
            flex-wrap: wrap;
            flex-basis: 110px;
            &--lg {
              flex-grow: 2;
              width: 100%;
            }
            &--md {
              width: 45%;
              flex-grow: 1;
              margin-right: 10px;
              & > input {
                width: 100%;
              }
            }
            &--sm {
              width: 45%;
              flex-grow: 1;
              & > input {
                width: 100%;
              }

            }
          }
      }
    }
    &__block_head {
      font-size: 20px;
    }
  }
  @media (max-width: 550px) {
    .contacts__block_head {
      font-size: 16px;
    }
    .contacts__form__head {
      font-size: 16px;
    }
    .contacts__phones-wrapper {

    }
    .contacts__block {
      position: absolute;
      transform: translateX(-110%);
      width: 90%;
      height: 70%;
      right: 100%;
      bottom: 10%;
      &--active{
        transform: translateX(-20%);
      }
    }

    .contacts__block_headers {
      font-size: 16px;
    }
    .contacts__block_text {
      font-size: 14px;
    }
    .contacts__forms_info {
      margin-left: 5px;
    }
    .contacts__forms--padding{
      padding: 15px;
      height: 35%;
    }
    .contacts__forms_ico {
      padding-left: 20px;
      & > h3 {
        font-size: 14px;
      }
    }
    .contacts__forms {
      top: 0%;
      height: 100%;
    }
  }
  @media (max-width: 375px) {
    .contacts__block_headers {
      margin-bottom: -5px;

    }
    .contacts__forms .contacts__block_phones {
      margin-top:  7px;
    }
    .contacts__form {
      padding: 10px;
    }
    .contacts__forms {
      top: 0%;
      height: 100%;
    }
    .contacts__form__head {
      margin-bottom: 10px;
    }
    .contacts__forms--padding {
      padding: 20px 10px 5px;
      height: 40%;
    }
    .contacts__forms_ico {
      padding-left: 30px;
    }
    .contacts__forms__toggle {
      right: -7.4%;
    }
  }
  @media (max-width: 320px) {
    .contacts__forms {
      height: 100%;
    }
  }
  .input-error {
    border-bottom: 2px solid red;
  }
}