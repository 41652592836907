@import '../../../../scss/reset/reser-style';
.buy-on-click-phone {
  background: #FFFFFF;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.05);
  width: 300px;
  position: relative;
  padding: 16px 16px 20px;
  display: none;
  &.active {
    display: block;
  }
  & .close-btn {
    position: absolute;
    cursor: pointer;
    background: none;
    padding: 0;
    top: 10px;
    right: 10px;
  }

  // .buy-on-click-phone__title

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  // .buy-on-click-phone__subtitle

  &__subtitle {
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 20px;
  }

  // .buy-on-click-phone__form

  &__form {
    margin-bottom: 24px;
  }

  & .form__input{
    width: 100%;
  }
  & .form__label{
    width: 100%;
    display: block;
  }

  // .buy-on-click-phone__done-btn

  &__done-btn {
    text-align: center;
  }

  & .form__label.error {
    border: 1px solid #DB4915 !important;
  }

  & .form__label.error span {
    color: #DB4915;
  }

  & .empty-feedback-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #DB4915;
    svg {
        margin-right: 5px;
        margin-top: -2px;
    }
  }
}
.buy-on-click-phone.opt {
  width: 400px;
  .buy-on-click-phone__title {
    font-size: 18px;
    font-weight:bold;
  }
  .buy-on-click-phone__subtitle {
    font-size: 16px;
    font-weight: 500;
  }
}