@import '../../../../scss/reset/reser-style';
.product-slider-modal {
  display: none;
  flex-direction: column;
  width: 1175px;
  padding: 40px 40px 20px 40px;
  background: $white;
  position: relative;
  .product-info__pricing {
    margin-top: 0 !important;
  }
  & .close-btn {
    position: absolute;
    cursor: pointer;
    background: none;
    padding: 0;
    top: 20px;
    right: 20px;
  }

  // .product-slider-modal__sliders-wrap

  &__sliders-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  // .product-slider-modal__title

  &__title{
    font-weight: 600;
    font-size: 32px;
    line-height: 150%;
    margin-bottom: 30px;
  }

  // .product-slider-modal__main-slider

  &__main-slider {
    .slider-arrow-left, .slider-arrow-right {
      position: absolute;
      top: 50%;
    }
    .slider-arrow-left {
      left: 0;
      z-index: 10;
    }
    .slider-arrow-right {
      right: 0;
      z-index: 10;
    }
    max-width: 910px;
    height: 100%;
    & .product-slider-modal__slide-item {
      max-width: 650px;
      width: 650px;
      & img{
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
    & .slick-slide > div{
      height: 600px;
      min-height: 100px;
      max-height: 600px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  // .product-slider-modal__thumbnail-slider

  &__thumbnail-slider {
    width: 135px;
    max-height: 590px;
    .slider-arrow-left {
      position: absolute;
      left: 40%;
      top: -30px;
      transform: rotate(90deg);
      z-index: 10;
    }
    .slider-arrow-right {
      position: absolute;
      left: 40%;
      bottom: -30px;
      transform: rotate(90deg);
      z-index: 10;
    }
    & .product-slider-modal__slide-item {
      width: 135px;
      height: 132px;
      padding: 10px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      & img{
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  // .product-slider-modal__footer

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    & .product-info {
      // .product-info__banking

      &__banking {
        width: fit-content;
      }

      // .product-info__pricing

      &__pricing {
        margin-bottom: 0;
      }

      // .product-info__price-wrap

      &__price-wrap {
        margin: 0 30px 0 40px;
      }

      // .product-info__banking-description

      &__banking-description {
        top: auto;
        bottom: 35px;
      }

      // .product-info__price

      &__price {
        font-weight: bold;
      }

      // .product-info__banking-mobile

      &__banking-mobile{
        display: none;
      }
    }
  }
}


@media only screen and (max-width: 1186px) {
  .product-slider-modal{
    width: 100%;
    margin: 0 10px;
    // max-height: 95vh;
    &__sliders-wrap {
      flex-direction: column-reverse;
      align-items: center;
    }
    &__main-slider {
      width: 100%;
      height: 100%;
      margin-bottom: 20px;
      & .product-slider-modal__slide-item {
        height: 100%;
        display: flex !important;
        justify-content: center;
        align-items: center;
        & img{
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }
      & .slick-slide > div{
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40vh;
      }
    }
    &__thumbnail-slider {
      .slider-arrow-left {
        position: absolute;
        left: -20px;
        top: 30%;
        transform: rotate(0);
        z-index: 10;
      }
      .slider-arrow-right {
        position: absolute;
        right: -20px;
        left: auto;
        top: 30%;
        transform: rotate(0);
        z-index: 10;
      }
      width: 100%;
      height: 96px;
      & .product-slider-modal__slide-item {
        width: 96px;
        height: 96px;
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        & img{
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }
    }
  }
}


@media only screen and (max-width: 976px) {
  .product-slider-modal{
    padding: 20px 20px 30px;
    &__title{
      font-size: 16px;
      line-height: 20px;
      margin-top: 34px;
      margin-bottom: 30px;
    }
  }
}

@media only screen and (max-width: 824px){
  .product-slider-modal{
    & .product-info__discount {
      margin-right: 32px;
      margin-bottom: 7px;
    }
    & .product-info__banking {
      display: flex;
    }
  }
}
@media only screen and (max-width: 581px){
  .product-slider-modal{
    &__footer{
      flex-wrap: wrap;
    }
    & .product-info__banking{
      display: none;
    }
    & .product-info__pricing{
      flex-direction: row-reverse;
    }
    & .product-info__banking-mobile{
      display: flex;
      margin-left: 0;
      margin-right: 10px;
      svg {
        margin-left: 20px;
      }
    }
    & .product-info__price-wrap{
      margin: 0;
    }
    &__buy-btn{
      width: 100%;
      margin-top: 20px;
    }
  }
}

@media only screen and (max-width: 390px){
  .product-slider-modal{
    &__main-slider {
      & .product-slider-modal__slide-item{
        & img {
          width: auto !important;
          height: 100% !important;
        }
      }
      & .slick-slide > div{
        height: 140px !important;
      }
    }
  }
}

.product-slider-modal.active {
  display: block;
}
