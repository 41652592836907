@import "src/scss/style";
@import "../../scss/reset/reser-style";

.catalog-mobile-header{
  margin-bottom: 24px;
  display: none;
}
.catalog-wrap {
  display: flex;
  position: relative;
}

.product-card-more {
  margin-left: 15px;
  margin-top: -35px;
}

.description-container {
  width: 100%;
  padding: 40px;
  background: #fff;
  margin-bottom: 100px;
  // .description-container__text

  &__text {
    color: #000;
  }

  // .description-container__title

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    color: #000;
    margin-bottom: 24px;
  }

  // .description-container__paragraph

  & p {
    font-size: 14px;
    line-height: 160%;
    margin-bottom: 18px;
  }

  // .description-container__list

  & ul {
    margin-bottom: 18px;
  }

  // .description-container__list-p

  &__list-p {
    font-size: 14px;
    line-height: 160%;
    margin-bottom: 8px;
  }

  // .description-container__list-item

  & li {
    font-size: 14px;
    line-height: 160%;
    &:not(:last-child){
      margin-bottom: 8px;
    }
    &:before{
      content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.54545 5L0 11H10.1818L16 5H6.54545Z' fill='%2343B02A'/%3E%3C/svg%3E%0A");
      width: 16px;
      height: 16px;
      display: inline-block;
      transform: translateY(4px);
      margin-right: 8px;
    }
  }
}

.cheap-product-table-container__text {
  color: #000;
  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    color: #000;
  }

  .cheap-product-table-container__table {
    width: 100%;
    margin-top: 10px;
    border-spacing: 0px 15px;
    border-collapse: separate;
    font-size: 14px;
    line-height: 160%;
    margin-bottom: 18px;
    td {
      border-bottom: 1px solid #e0e0e0;
      height: 32px;
    }
  }
}

.catalog {

  // .catalog__section

  .catalog-buy-one-click {
    display: none;
  }

  &__section {
    width: 99%;
    margin-left: 0.5%;
  }

  // .catalog__title
  &__title{
    @include title;
    border-left: 4px solid $main;
    padding-left: 8px;
    margin-bottom: 32px;
  }

  // .catalog__header

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  // .catalog__sort

  &__sort {
    display: flex;
    & div:not(:last-child){
      margin-right: 16px;
    }
  }

  // .catalog__sort-title

  &__sort-title {
    width: 80px;
    @include subtitle-small;
  }

  // .catalog__sort-item

  &__sort-item {
    @include main-text;
    cursor: pointer;
    &.active{
      @include subtitle-small;
      color: $second;
    }
  }

  // .catalog__pick-equipment

  &__pick-equipment {
    display: block;
  }

  // .catalog__body

  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: center;
    margin-bottom: 38px;
  }
}
.container {
}
.bread-crumbs {

  // .bread-crumbs__item

  &__item {
    font-size: 15px;
  }
}
.catalog-slider-wrap {
  height: fit-content;
  position: relative;
  margin-bottom: 32px;
  width: 100%;
  & .slider-arrow-left, & .slider-arrow-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    & svg rect{
      transition: .2s;
    }
    &:hover{
      & svg rect{
        fill: $main;
        fill-opacity: 1;
      }
    }
  }
  & .slider-arrow-left{
    left: 40px;
  }
  & .slider-arrow-right {
    right: 40px;
  }
}
.catalog-slider {
  height: 100%;
  width: 100%;
  // .catalog-slider__item

  &__item {
    & img{
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.noUi-connect {
  background: #43B02A !important;
}
.noUi-origin {
}
.noUi-handle{
  background: none !important;
  border: none !important;
  height: auto !important;
  width: auto !important;
  box-shadow: none!important;
  transform: translateY(-20%);
  cursor: pointer !important;
  &::before, &::after{
    display: none !important;
  }
}
.noUi-touch-area{
  width: 21px !important;
  height: 21px !important;
  background: #43B02A;
  border-radius: 50%;
  border: 2px solid $white;
}

.product-card-box{
  overflow: visible;
  margin-bottom: 10px;
  width: 260px;
  display: flex;
  justify-content: center;
  transition: 0.2ms;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  border-top: 1px solid #fff;


  & .product-card__ui-section{
    overflow-y: visible;
    align-items: center;
    max-height: 21.3px;
  }

  &:hover {
    z-index: 2;
    border-color: #C4C4C4;
    transform: translateY(-1%);
    .product-card__characteristics {
      display: block;
      border-left: 1px solid #C4C4C4;
      border-right: 1px solid #C4C4C4;
      border-bottom: 1px solid #C4C4C4;
    }
  }
}
.product-card-wrap{
  width: 258px;
  padding: 16px;
  position: relative;
  transition: .2s ;

  //overflow: hidden;

  background: $white;
  .product-specifications-tag {
    display: none;
  }
  &:hover {
    .product-specifications-tag {
      display: block;
    }
  }
  &::after{
    display: none;
  }
  .product-card__payment-mobile{
    position: absolute;
    right: 0;
    bottom: 33px;
    flex-wrap: wrap;
    & img{
      &:not(:last-child){
        margin-right: 10px;
      }
    }
  }
}
.product-card {
  // .product-card__credit-conditions

  &__credit-conditions {
  }

  // .product-card__ui-section

  &__ui-section {
    position: relative;
  }

  // .product-card__buy-btn

  &__buy-btn {
    transition: .3s;
    transition-delay: .3s;
    height: 26px;
  }

  // .product-card__fav-btn

  &__fav-btn {
    position: relative;
    &::before{
      content: "";
      -webkit-clip-path: polygon(50% 0,0 100%,100% 100%);
      clip-path: polygon(50% 0,0 100%,100% 100%);
      transform: rotate(180deg);
      width: 14px;
      height: 14px;
      background: $white;
      position: absolute;
      left: 0;
      right: 0;
      top: -20px;
      margin: auto;
      z-index: 3;
      opacity: 0;
      transition: 0.2s;
    }
    &.active + .product-card__popup--fav{
      opacity: 1;
      transform: scale(1);
      transition: opacity 1s;
    }
    &.active::before{
      opacity: 1;
    }
  }


  // .product-card__compare-btn

  &__compare-btn {
    position: relative;
    &::before{
      content: "";
      -webkit-clip-path: polygon(50% 0,0 100%,100% 100%);
      clip-path: polygon(50% 0,0 100%,100% 100%);
      transform: rotate(180deg);
      width: 14px;
      height: 14px;
      background: $white;
      position: absolute;
      left: 0;
      right: 0;
      top: -20px;
      margin: auto;
      z-index: 3;
      opacity: 0;
      transition: 0.2s;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    &.active + .product-card__popup--compare{
      opacity: 1;
      transform: scale(1);
      transition: opacity 1s;
    }
    &.active::before{
      opacity: 1;
    }
  }

  // .product-card__label--discount

  &__label--discount {

  }

  // .product-card__popup

  &__popup {
    position: absolute;
    right: 0;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    height: 54px;
    width: 100%;
    display: flex;
    align-items: center;
    bottom: 40px;
    z-index: 2;
    padding-left: 10px;
    opacity: 0;
    transform: scale(0);
  }

  // .product-card__popup--fav

  &__popup--fav {
  }

  &__popup--compare{
  }
  // .product-card__popup-text

  &__popup-text {
    font-size: 10px;
    line-height: 150%;
    color: $black;
  }

  // .product-card__popup-link

  &__popup-link {
    color: $grey;
    text-decoration-line: underline;
    margin-left: 5px;
  }

  // .product-card__popup-close-btn

  &__popup-close-btn {
    align-self: flex-start;
  }


  // .product-card__characteristics-wrap

  //&__characteristics-wrap{
  //  position: absolute;
  //  width: 114%;
  //  margin-left: -7%;
  //  padding: 16px;
  //  background-color: $white;
  //  overflow: hidden;
  //  // transition: .3s;
  //}
  &__characteristics-wrap{
    width: 260px;
    margin-top: 10px;
    left: -17px;
    background-color: $white;
    overflow: hidden;
    transition: .3s;
    position: absolute;
    z-index: 99;

  }

  // .product-card__characteristics
  &__characteristics {
    width: 100%;
    position: relative;
    // margin-top: 16px;
    padding: 16px;
    background: $white;
    display: none;
    z-index: 10;
    &::before{
      content: '';
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      border-top: 1px solid #C4C4C4;
      transition: .2s;
    }
  }
  &:hover {
    .product-card__characteristics {
      display: block;
    }
  }

  &:hover .product-card__characteristics{
    overflow: visible;
  }
  // .product-card__payment

  &__payment {
    display: flex;
    margin-bottom: 16px !important;
    margin-left: -5px;
  }

  // .product-card__payment-item

  &__payment-item {
    cursor: pointer;
    &:not(:last-child){
      margin-right: 16px;
    }
  }

  // .product-card__payment-item--privat

  &__payment-item--privat {
  }
}

#range-from, #range-to{
  padding: 4px 2px;
  text-align: center;
  @include main-text;
  width: 58px;
  border: 0.5px solid #257226;
  background: none;
}
#range-from{
  width: 58px;
}

.characteristics {

  // .characteristics__char-title

  &__char-title {
    @include subtitle-small;
  }

  // .characteristics__item

  &__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
  }

  // .characteristics__item-title

  &__item-title {
    @include secondary-text;
    min-width: 50%;
  }

  // .characteristics__item-value

  &__item-value {
    @include secondary-text;
    color: #868686;
    text-align: right;
  }
}
.show-more-btn {
  height: 348px;
  max-width: 238px;
  width: 238px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: $white;
  &:hover{
    box-shadow: 0 20px 50px rgba(0,0,0,.1);
    transition: .2s;
  }
  &:active{
    background: $label;
  }
  // .show-more-btn__arrows

  &__arrows {
    margin-bottom: 34px;
  }

  // .show-more-btn__text

  &__text {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    max-width: 171px;
  }
}



@media only screen and (max-width: 1180px) {
  .catalog{
    &__body{
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

@media only screen and (max-width: 1155px) {
  .catalog{
    &__header{
      flex-direction: column;
      align-items: flex-start;
    }
    &__sort{
      margin-bottom: 12px;
    }
  }
}

.catalog-mobile-header-head {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

@media only screen and (min-width: 976px) {
  .filter-wrap {
    width: 225px !important;
  }
  .product-card-box{
    & .product-card__buy-btn{
      &:hover{
        background: $second;
      }
      svg {
        margin-top: 3px;
      }
      & svg path{
        transition: 0.1s;
      }
    }
  }
  .product-card-box:hover{
    transition: 0.3s;
    & .product-card__buy-btn{
      transition: 0.3s;
      animation: 0.2s buy-btn 1 both;
      width: 83px;
      height: 26px;
      & svg{
        margin-top: 3px;
        transition: 0.3s;
        transition-delay: 0.1s;
        transform: translateX(145%);
      }
      & svg path{
        fill: $white;
        stroke: $white;
      }
    }
  }
}
@media only screen and (max-width: 1060px) {
  .catalog{
    &__header{
      display: none;
    }
    &__title{
      @include subtitle-main-text;
    }
  }
  .catalog-mobile-header__filter-btn {
    margin-bottom: 10px;
    width: 90%;
    margin-left: 5%;
    border-radius: 5px;
    background: $main !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    span {
      color: #FFFFFF;
    }
  }
  .catalog-mobile-header {
    display: block;
    // .catalog-mobile-header__filter-btn, .catalog-mobile-header__sort-btn
    //position: sticky;
    top: 85px;
    z-index: 40;
    background: #FFFFFF;
    padding: 10px;
    width: 100%;
    &__filter-btn, &__sort-btn {
      display: flex;
      align-items: center;
      background: transparent;
      margin-right: 16px;
      & img{
        width: 24px;
        height: 24px;
      }
    }

    // .catalog-mobile-header__filter-text .catalog-mobile-header__sort-text

    &__filter-text, &__sort-text {
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
    }

    // .catalog-mobile-header__equipment-select-link

    &__equipment-select-link {
      display: flex;
      align-items: center;
    }

    // .catalog-mobile-header__selection-text

    &__selection-text {
      font-weight: 600;
      font-size: 10px;
      line-height: 120%;
      color: $main;
    }
  }
  .product-card-wrap{
    max-width: 280px;
    width: 280px;
    padding: 16px 8px;
  }
  .product-card{
    &__payment-mobile{
      display: flex !important;
    }
    &__name{
      @include subtitle-small;
    }
  }

  .product-card-box{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    margin-bottom: 24px;
    width: 250px;
    & .product-card{
      &__img-wrap{
        max-width: 166px;
        width: 166px;
        height: auto;
        min-width: auto;
        min-height: auto;
        margin: 34px auto 16px auto;
        & img{
          width: 100% !important;
        }
      }
      &__characteristics-wrap{
        display: none;
      }
    }
    &:hover {
      & .product-card__buy-btn{
        & svg {
          transition-delay: 0.1s;
          transform: translateX(0);
        }
      }
    }
    & .product-card__buy-btn{
      width: 83px;
      height: 27px;
      display: flex;
      justify-content: center;
      background: $main;
      align-items: center;
      & svg path{
        fill: $white;
        stroke: $white;
      }
    }
  }
  .show-more-btn{
    flex-direction: column-reverse;
    max-width: 100%;
    width: 100%;
    margin-left: -5px;
    .show-more-btn__arrows{
      margin-bottom: 0;
      margin-top: 30px;
      transform: rotate(90deg);
    }
    &__text{
      font-size: 16px;
    }
  }
}



@media only screen and (max-width: 920px) and (min-width: 800px) {
  .product-card-wrap{
    max-width: 100%;
    width: 100%;
    min-width: 100%;
    padding: 16px 8px;
  }
}

@media only screen and (max-width: 800px) {
  .catalog{
    &__body{
      grid-template-columns: 50% 50%;
    }
  }
  .product-card-box:hover {
    box-shadow: none;
    transform: none;
    z-index: 2;
  }
}

@media only screen and (max-width: 768px) {
  .catalog-slider-wrap{
    display: none;
  }
}
@media only screen and (max-width: 596px) {
  .product-card-wrap{
    width: 95%;
    max-width: 95%;
  }
}

@media only screen and (max-width: 460px) {
  .catalog{
    &__body{
      grid-template-columns: 1fr;
      & .product-card-wrap{
        max-width: 280px;
        width: 280px;
      }
      .product-card-box {
        width: 100%;
        .product-card-wrap {
          width: 100%;
          max-width: 100%;
          .product-card__img-wrap {
            width: 70%;
            max-width: 70%;
            height: 260px;
          }
        }
      }
    }
  }
  .catalog__body {
    .product-card-wrap {
      .product-card__price {
        font-size: 30px;
      }
      .product-card__credit-conditions {
        position: absolute;
        top: 100px;
        left: 40px;
        .product-card__payment-mobile {
          img {
            margin-top: 10px;
            width: 30px;
            max-width: 30px;
            height: 30px;
          }
        }
      }
    }
    .product-card__popup--fav, .product-card__popup--compare {
      left: 50px;
      width: 180px;
    }
    .product-card__ui-section {
      .product-card__buy-btn {
        width: 45%;
        height: 34px;
        &:hover {
          width: 45%;
          animation: none;
        }
      }
      .catalog-buy-one-click {
        width: 45%;
        height: 34px;
        display: block;
        button {
          width: 100%;
          height: 34px;
          background: $main;
          font-size: 13px;
          font-weight: 600;
          color: white;
          &:hover {
            color: $main;
          }
        }
      }
    }
    .product-card__btns-wrap {
      left: 2px;
      position: absolute;
      flex-direction: column;
      top: -340px;
      svg {
        width: 25px;
        height: 25px;
        margin-bottom: 10px;
      }
    }
  }
}
