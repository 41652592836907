@import "src/scss/style";
@import "../../../scss/reset/reser-style";

.filter-wrap {
  .input-text {
    width: 75%;
  }
  .hidden-mobile-catalog {
    display: none !important;
  }
  .arrow-up {
    margin-right: -5px;
    margin-left: 5px;
  }
  max-width: 235px;
  padding: 0;
  .filter-counter {
    position: absolute;
    right: 16px;
    color: #182316;
    font-size: 14px;
    line-height: 150%;
  }
  .filter-send-flag {
    position: absolute;
    margin-top: -35px;
    margin-left: 175px;
    display: flex;
    justify-content: space-between;
    width: 190px;
    background: #FFFFFF;
    padding: 8px 16px;
    border: 1px solid #F5F5F7;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
    z-index: 19;
    //&:before {
    //  content: '';
    //  width: 0;
    //  height: 0;
    //  border-left: 10px solid transparent;
    //  border-right: 10px solid transparent;
    //  border-bottom: 10px solid white;
    //  transform: rotate(-90deg);
    //  position: absolute;
    //  margin-top: 3px;
    //  left: -12px;
    //  box-shadow: 0 6px 2px -8px rgba(0, 0, 0, 0.15);
    //}
    &__name {
      font-size: 12px;
      line-height: 150%;
    }
    &__link {
      cursor: pointer;
      font-weight: 700;
      color: #43B02A;
      text-decoration: underline;
    }
    .close {
      position: absolute;
      top: 0;
      right: 3px;
      font-size: 12px;
      line-height: 150%;
      cursor: pointer;
    }
  }
}

.filter__title-btn {
  cursor: pointer;
  background: $main;
  color: $white;
  padding: 2px 16px;
  font-size: 12px;
  transition: .2s;
  margin-top: 20px;
  width: 70px;
  height: 25px;
  &:hover {
    background: $second;
  }
}

.noUi-target {
  height: 1px !important;
}
.noUi-base {
  height: 1px !important;
}
.filter {

  .input-text {
    width: 75%;
    line-height: 20px;
    font-size: 12px !important;
  }
  &__reset-title{
    @include subtitle-small;
  }
  &__mobile-close-btn{
    background: none;
  }

  // .filter__picked-section

  &__picked-section {
    width: 100%;
    margin-bottom: 32px;
    padding: 0;
  }

  // .filter__picked-item

  &__picked-item {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    margin-bottom: 8px;
    background: $second-grey;
    width: fit-content;
    & svg{
      cursor: pointer;
    }
  }
  // .filter__picked-val

  &__picked-val {
    @include secondary-text;
    color: $black;
    margin-right: 4px;
  }

  // .filter__reset-section
  &__reset-section{
    display: flex;
    align-items: center;

  }

  // .filter__reset-title
  &__reset-title{
    margin-right: 16px;
  }

  // .filter__reset-btn

  &__reset-btn {
    cursor: pointer;
    background: none;
    font-size: 12px;
    line-height: 150%;
    text-decoration-line: underline;
    &:hover {
      color: $main;
    }
  }

  // .filter__section

  &__section {
    margin-bottom: 12px;
    position: relative;
    background: #FFFFFF;
    padding: 16px;
  }

  // .filter__title

  &__title {
    @include subtitle-small;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
  }

  // .filter__form

  &__form {
  }

  // .filter__price-input-wrap

  &__price-input-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  // .filter__from-wrap

  &__from-wrap {
  }

  // .filter__to-wrap

  &__to-wrap {
  }

  &__from-wrap, &__to-wrap{
    & > span{
      @include main-text;
      margin-right: 6px;
      text-transform: lowercase;
    }
  }

  // .filter__range-input

  &__range-input {
    & #range-slider{
      width: 96.5%;
      height: 2px;
      background: #E0F8D4;
      border: none;
    }
  }

  // .filter__section--drop

  &__section--drop {
    position: relative;
    height: fit-content;
    & .filter__title{
      cursor: pointer;
      margin-bottom: 0;
    }
    & .arrow-up{
      transform: rotate(0deg);
      transition: .3s;
      min-width: 16px;
      min-height: 16px;
      width: 16px;
      height: 16px;
    }
    & .filter__form{
      display: none;
      overflow: hidden;
      transition: .6s;
    }
    &.active{
      // transition: .6s;
      & .filter__form{
        margin-top: 16px;
        display: block;
      }
      & .arrow-up{
        transform: rotate(-90deg);
      }
    }
  }
}

.checkbox-label .checkbox::before {
  background: none !important;
}

.filter__mobile-btn {
  margin-bottom: 5px;
  width: 90%;
  border-radius: 5px;
  background: #43B02A !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 35px;
}

@media only screen and (min-width: 976px) {
  .filter{
    .filter__reset-title{
      display: none;
    }
    .filter__mobile-close-btn{
      display: none;
    }
    .mobile-filters-use {
      display: none;
    }
    .filter__mobile-btn-wrapper {
      display: none;
    }
  }
}

@media only screen and (max-width: 976px) {
  .filter-wrap{
    .filter__reset-btn {
      display: none;
    }
    .filter-send-flag {
      display: none !important;
    }
    .filter__reset-title {
      display: none;
    }
    .filter__mobile-close-btn {
      position: absolute;
      right: 20px;
      top: 100px;
      z-index: 9999;
    }
    .filter__mobile-btn-wrapper.active {
      display: block;
    }
    .filter__mobile-btn-wrapper {
      display: none;
      position: fixed;
      width: 100%;
      bottom: 10px;
      background-color: white;
    }
    .arrow-up {
      margin-left: 10px;
    }
    padding: 0;
    position: fixed;
    left: 0;
    top: 0;
    height: calc(100vh + 88px);
    z-index: 8000;
    overflow-y: auto;
    width: 0;
    transition: .3s;
    &.active{
      width: 100vw;
    }
    max-width: 100vw;
  }
  .filter{
    padding: 100px 16px 180px 16px;
    background: #FFFFFF;
    height: max-content;
    max-width: 100%;
    min-height: 100%;
    position: relative;
    &__picked-section{
      position: fixed;
      z-index: 5999;
      background: #FFFFFF;
      width: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px;
      height: 110px;
      display: none;
      margin-bottom: 0;
      &.safari {
        //top: calc(90vh - 110px);
      }
      &.active-mobile {
        display: block;
      }
      .mobile-filters-use {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .product-count {
          width: max-content;
          margin-bottom: 20px;
        }
        .product-filter-footer {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .filter__show-btn, .filter__reset-btn-mobile {
            width: 49%;
            margin-right: 1%;
            height: 40px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .filter__show-btn {
            background: $main;
            color: white;
          }
          .filter__reset-btn-mobile {
            background: none;
            &-text {
              border-bottom: 1px dashed $main;
              width: max-content;
              color: $main;
            }
          }
        }
      }
    }
    &__picked-items-wrap {
      display: none;
    }
    &__reset-section{
      margin-bottom: 12px;
    }
    &__picked-item{
      margin-bottom: 4px;
    }
    &__reset-btn{
      font-size: 10px;
    }
    .filter__section {
      &:first-child {
        margin-top: -110px;
      }
    }
  }
}