@import '../../scss/reset/reser-style';

.product-card-wrap--compare{
    & .product-card{
        &__img-wrap{
            margin: 0;
        }
        &__buy-btn{
            width: fit-content;
            background: none;
            &:hover{
                background: none;
            }
            & svg path{
                fill: $black;
                stroke: $black;
            }
        }
    }
}

.favorite {
    .product-card-wrap {
        min-width: 180px;
    }
    margin-bottom: 100px;
    .product-card-wrap {

    }
    // .favorite__body

    &__body {
    }

    // .favorite__items

    &__items {
        display: flex;
        & .product-card-box{
            margin: 0 12px;
        }
    }

    // .favorite__back-to-main

    &__back-to-main {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        text-decoration-line: underline;
    }
    .products-slider-wrap {
        background: none !important;
    }
}

.slider-container--favorite .products-slider-wrap{
    margin-bottom: 40px;
    & .product-card-box{
        display: flex !important;
        justify-content: center;
        & .delete-btn{
            cursor: pointer;
            position: absolute;
            top: 12px;
            right: 12px;
            z-index: 1;
        }
    }
    & .slider-arrow-left, & .slider-arrow-right{
        top: 40%;
    }
}

@media only screen and (max-width: 976px) {
    .slider-container--favorite{
        padding-left: 0;
        margin-bottom: 24px;
        & .product-card-box{
            width: fit-content;
            margin-bottom: 20px !important;
            align-self: flex-start;
        }
        & .products-slider{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
        }
    }
    .favorite{
        margin-bottom: 70px;
        &__back-to-main {
            font-size: 14px;
        }
    }
}

@media only screen and (max-width: 490px) {
    .slider-container--favorite{
        & .product-card-box{
            margin: 0 auto 20px auto !important;
            min-width: 200px;
        }
    }
}

