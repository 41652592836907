.additional-services {
  &__product {
    &:not(:last-child) {
      border-bottom: 1px solid #dcdcdc;
    }
    padding: 16px 0;
  }

  &__product-body {
    display: flex;
  }

  &__product-info {

  }

  &__product-img {
    width: 120px;
    height: 80px;

    display: flex;
    justify-content: center;
    align-self: center;

    margin-right: 15px;

    & img {
      width: 100%;
      object-fit: contain;
    }
  }

  &__product-name {
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
  }

  &__product-data {
    display: flex;
    flex-direction: column;
    width: 100%;

  }


  &__product-service {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 10px;
  }

  &__service-name {
    width: 60%;
    font-size: 12px;
    line-height: 150%;
    margin-bottom: 5px;
  }

  &__service-price {
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
  }

  &__delete-btn {
    font-size: 14px;
    padding: 1px 18px 2px;
    color: white;
    height: 25px;
    background: #FB8200;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.active svg {
      transform: rotate(45deg);
    }

    &.active {
      background: #43b02a;
    }
  }

  @media (max-width: 770px) {
    &__product-body {
      display: grid;
      grid-template-areas:
        "product-image product-name product-name"
        "product-data product-data product-data";
    }

    &__product-info {
      display: flex;
      align-items: center;
    }

    &__product-image {
      grid-area: product-image;
    }

    &__product-name {
      font-weight: 400;
      grid-area: product-name;
    }

    &__product-data {
      grid-area: product-data;
    }

    &__product-service {
      display: grid;
      grid-template-areas:
        "service-name button"
        "price button";
    }

    &__service-price {
      grid-area: price;
    }

    &__service-name {
      width: 100%;
      grid-area: service-name;
    }

    &__delete-btn {
      width: 84px;
      height: 28px;
      grid-area: button;
    }
  }
}
